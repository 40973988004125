<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 81.4 67.7"
    xml:space="preserve"
  >
    <g>
      <path
        d="M55.9,18.5L44,30.4c-0.4,0.3-0.6,0.8-0.6,1.3c0,0.5,0.2,0.9,0.5,1.3c0.3,0.3,0.8,0.5,1.3,0.5 c0.5,0,0.9-0.2,1.3-0.6l11.9-11.9c0.4-0.3,0.6-0.8,0.6-1.3c0-0.5-0.2-0.9-0.5-1.3c-0.3-0.3-0.8-0.5-1.3-0.5 C56.7,18,56.2,18.2,55.9,18.5L55.9,18.5z"
      />
      <path
        d="M52.8,16c0.5-0.7,0.5-1.7-0.1-2.3c-0.6-0.6-1.6-0.7-2.3-0.2l-8.6,8.6c-0.4,0.3-0.6,0.8-0.6,1.3 c0,0.5,0.2,0.9,0.5,1.3c0.3,0.3,0.8,0.5,1.3,0.5c0.5,0,0.9-0.2,1.3-0.6L52.8,16z"
      />
      <path d="M15.8,30.3c-4,0-4-5.9,0-5.9C19.8,24.3,19.8,30.3,15.8,30.3" />
      <path
        d="M33.9,54.6h41c3.6,0,6.5-2.9,6.5-6.5V6.5c0-3.6-2.9-6.5-6.5-6.5H14.2c-3.6,0-6.5,2.9-6.5,6.5 v29.7C3,39.3,0.1,44.6,0,50.2c-0.1,5.7,2.6,11,7.3,14.3c4.6,3.3,10.6,4.1,15.9,2.1C28.6,64.6,32.6,60.1,33.9,54.6L33.9,54.6z M74.9,3.6c0.8,0,1.5,0.3,2,0.9c0.5,0.5,0.8,1.3,0.8,2v41.7c0,1.6-1.3,2.9-2.9,2.9H73V3.6H74.9z M11.3,6.5c0-1.6,1.3-2.9,2.9-2.9 h5.4v29.9c-2.8-0.4-5.7-0.1-8.3,0.9V6.5z M23.2,34.3V3.6h46.3v47.5H34.4v-0.6c0-3.5-1.1-6.9-3.1-9.8C29.3,37.8,26.5,35.5,23.2,34.3 L23.2,34.3z M3.6,50.5c0-3.6,1.4-7.1,4-9.7c2.6-2.6,6-4,9.7-4c3.6,0,7.1,1.4,9.7,4c2.6,2.6,4,6,4,9.7s-1.4,7.1-4,9.7 c-2.6,2.6-6,4-9.7,4c-3.6,0-7.1-1.4-9.7-4C5,57.6,3.6,54.1,3.6,50.5L3.6,50.5z"
      />
      <path
        d="M23,44.8c-0.5-0.5-1.4-0.5-2,0l-3.8,3.8l-3.8-3.8c-0.5-0.5-1.4-0.5-2,0c-0.5,0.5-0.5,1.4,0,2 l3.8,3.8l-3.8,3.8c-0.5,0.5-0.5,1.4,0,2s1.4,0.5,2,0l3.8-3.8l3.8,3.8c0.5,0.5,1.4,0.5,2,0c0.5-0.5,0.5-1.4,0-2l-3.8-3.8l3.8-3.8 C23.5,46.3,23.5,45.4,23,44.8z"
      />
    </g>
  </svg>
</template>
