<script>
import "./FlightConfirm.scss";
import FlightConfirm from "./FlightConfirm.js";

export default FlightConfirm;
</script>

<template>
  <div class="flightConfirm">
    <div class="flightConfirm__inner">
      <header class="flightConfirm__header">
        <span class="flightConfirm__header__subtitle">Bevestigen</span>
        <h3 class="heading heading--3 flightConfirm__header__title">
          Ben je zeker?
        </h3>
      </header>

      <div class="flightConfirm__content">
        <p>Je vaartschema wordt onherroepelijk ingediend.</p>
      </div>

      <div class="flightConfirm__submit">
        <button @click="handleSubmit" class="button button--positive">
          <span class="button__label">Dienst beëindigen</span>
        </button>
      </div>
    </div>
  </div>
</template>
