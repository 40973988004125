<template>
	<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 39.8 18.5" xml:space="preserve">
		<g>
			<path d="M33.9,13.7c-0.7,0.5-1.7,0.8-2.7,0.9V15c0,0.3-0.3,0.6-0.6,0.6c-0.3,0-0.6-0.3-0.6-0.6v-0.4 c-1-0.1-1.9-0.4-2.7-0.9c-1-0.6-1.5-1.5-1.5-2.4c0-0.3,0.3-0.6,0.6-0.6S27,11,27,11.3c0,1,1.3,1.9,3,2.1V8.3h-2.1 c-0.3,0-0.6-0.3-0.6-0.6c0-0.3,0.3-0.6,0.6-0.6H30V5.9c-0.6-0.2-1-0.8-1-1.4c0-0.9,0.7-1.6,1.6-1.6c0.9,0,1.6,0.7,1.6,1.6 c0,0.7-0.4,1.2-1,1.4v1.2h2.1c0.3,0,0.6,0.3,0.6,0.6c0,0.3-0.3,0.6-0.6,0.6h-2.1v5.1c1.7-0.2,3-1.1,3-2.1c0-0.3,0.3-0.6,0.6-0.6 c0.3,0,0.6,0.3,0.6,0.6C35.4,12.2,34.8,13.1,33.9,13.7 M30.6,0c-5.1,0-9.2,4.1-9.2,9.2c0,2.5,0.9,4.8,2.7,6.6 c1.7,1.8,4,2.7,6.5,2.8h0.1c5.1,0,9.2-4.1,9.2-9.2C39.9,4.2,35.7,0,30.6,0"/>
			<circle cx="1.2" cy="9.6" r="1.2"/>
			<circle cx="5.9" cy="9.6" r="1.2"/>
			<path d="M12.5,10.6h-2.4C9.5,10.6,9,10.1,9,9.5c0-0.6,0.5-1.1,1.1-1.1h2.4l0,0V6.3c0-0.5,0.5-0.8,0.8-0.5 l3.8,3.2c0.3,0.2,0.3,0.7,0,0.9l-3.8,3.2c-0.3,0.3-0.8,0-0.8-0.5V10.6z"/>
		</g>
	</svg>
</template>
