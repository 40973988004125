<template>
	<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 42.3 51.9" xml:space="preserve">
		<g>
			<path d="M39.2,24.9c-1.5,0-3,1-3,2.8c0,6.8-5,12.6-11.9,14v-20h0.9c1.7,0,3.1-1.3,3.1-3c0-1.6-1.4-3-3.1-3h-0.9V12
				c2.2-1.1,3.6-3.3,3.6-5.7c0-3.6-3-6.4-6.8-6.4c-3.7,0-6.8,2.9-6.8,6.4c0,2.4,1.4,4.6,3.6,5.7v3.7H17c-0.8,0-1.6,0.3-2.2,0.9
				C14.3,17.2,14,18,14,18.8c0,1.6,1.4,3,3.1,3H18v20c-6.9-1.4-11.9-7.2-11.9-14c0-1.8-1.6-2.8-3-2.8c-1.5,0-3,1-3,2.8
				c0,10.1,7.7,18.5,18,19.9V49c0,1.6,1.4,3,3.1,3s3.1-1.3,3.1-3v-1.3c10.3-1.5,18-9.8,18-19.9C42.3,25.9,40.7,24.9,39.2,24.9z
				 M21.1,5.3c0.5,0,0.9,0.4,0.9,0.9s-0.4,0.9-0.9,0.9c-0.5-0.1-0.9-0.4-0.9-0.9S20.6,5.3,21.1,5.3z"/>
		</g>
	</svg>
</template>