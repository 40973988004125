<script>
import "./PassengersForm.scss";
import PassengersForm from "./PassengersForm.js";

export default PassengersForm;
</script>

<template>
  <article class="passengers-form">
    <header class="passengers-form__header">
      <h2 class="passengers-form__title heading heading--3">Passagiers</h2>
      <div class="passengers-form__location">
        <Svg-icon iconType="Anchor-circle"></Svg-icon>
        <p>{{ nextStop.stop.name }}</p>
      </div>
    </header>
    <div class="passengers-form__counter">
      <Passenger-counter
        title="Opstappers"
        :minPassengers="0"
        :currentPassengers="passengers.adding"
        :maxPassengers="maxIncommingPassengers"
        :minBikes="0"
        :currentBikes="bikes.adding"
        :maxBikes="maxIncommingBikes"
      />
      <div class="passengers-form__current">
        <div class="passengers-form__current-count">
          <div class="passengers-form__current-count-item">
            <Svg-icon iconType="passengers" />
            <p>
              <span>{{ passengers.current }}</span
              >/{{ $store.state.passengers.max_people }}
            </p>
          </div>
          <div class="passengers-form__current-count-item">
            <Svg-icon iconType="bike" />
            <p>
              <span>{{ bikes.current }}</span
              >/{{ $store.state.passengers.max_bikes }}
            </p>
          </div>
        </div>
        <button
          @click.prevent="handleRemoveAll"
          class="button button--pairing-neutrals-9"
          type="sumbit"
        >
          <span class="button__symbol">
            <Svg-icon iconType="Group-remove" />
          </span>
          <span class="button__label">Alle passagiers van boord</span>
        </button>
      </div>
      <Passenger-counter
        title="Afstappers"
        :minPassengers="-$store.state.passengers.current_people"
        :currentPassengers="passengers.removing"
        :maxPassengers="0"
        :minBikes="-$store.state.passengers.current_bikes"
        :currentBikes="bikes.removing"
        :maxBikes="0"
      />
    </div>
    <button
      v-if="
        $store.state.currentStopIndex >= $store.state.currentStops.length - 1
      "
      @click.prevent="closeCanvas"
      class="button--withpadding button button--positive button--arrival passengers-form__confirm"
    >
      <span class="button__symbol">
        <Svg-icon iconType="Check-circle"></Svg-icon>
      </span>
      <span class="button__label">Dienst beëindigen</span>
    </button>
    <button
      v-else
      @click.prevent="closeCanvas"
      class="button button--pairing-brand-1 passengers-form__confirm"
      type="submit"
    >
      <span class="button__label">Bevestigen</span>
    </button>
  </article>
</template>
