<script>
import "./Start.scss";
import Start from "./Start.js";
export default Start;
</script>

<template>
  <div class="start">
    <h2 class="heading heading--3 start__title">Uw vaart starten</h2>
    <div class="start__inner">
      <section class="steps">
        <div class="steps__inner">
          <Step-tracker :steps="steps" :activeStep="activeStep" />

          <div class="steps__theSteps">
            <Tracks v-if="activeStep === 1" />
            <Shifts v-if="activeStep === 2" />
            <Ship v-if="activeStep === 3" />
            <Crew v-if="activeStep === 4" />
          </div>

          <div class="steps__outer_" v-if="showBtns">
            <div class="steps__prevBtn">
              <button
                v-if="activeStep > 1"
                class="button button--pairing-brand-0"
                @click="handlePrevClick()"
              >
                <span class="specialButton__symbol">
                  <Svg-icon iconType="Arrow-left" />
                </span>
                <span class="specialButton__label">Terug</span>
              </button>
            </div>

            <div
              :class="[
                'steps__nextBtn',
                { 'is-hidden': isNextDisabled && hasAllItemsForChecklist },
              ]"
            >
              <button
                :disabled="isNextDisabled && hasAllItemsForChecklist"
                @click="activeStep === 4 ? handleSubmit() : handleNextClick()"
                class="button button--pairing-brand-1"
              >
                <span
                  v-if="hasStartCheckList && !checkIfChecklistIsCompleted"
                  class="button__label"
                  >{{ activeStep === 4 ? "Naar checklist " : "Verder" }}</span
                >
                <span v-else class="button__label">{{
                  activeStep === 4 ? "Naar vaart" : "Verder"
                }}</span>
                <span class="button__symbol">
                  <Svg-icon iconType="Arrow-right" />
                </span>
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
