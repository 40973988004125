<script>
import "./PageWrapper.scss";
import PageWrapper from "./PageWrapper.js";

export default PageWrapper;
</script>

<template>
  <div class="page-wrapper">
    <div class="page-wrapper__outer">
      <div class="checklist__prevBtn" v-if="showPrev">
        <div class="help__actions__item">
          <router-link
            v-if="$route.path === '/flight/edit'"
            to="/flight"
            tag="button"
            class="specialButton specialButton--darker"
          >
            <span class="specialButton__symbol">
              <Svg-icon iconType="Arrow-left" />
            </span>
            <span class="specialButton__label">Terug naar huidige vaart</span>
          </router-link>
          <router-link
            v-else
            to="/dashboard"
            tag="button"
            class="specialButton specialButton--darker"
          >
            <span class="specialButton__symbol">
              <Svg-icon iconType="Dashboard-icon" />
            </span>
            <span class="specialButton__label">Dashboard</span>
          </router-link>
        </div>
      </div>

      <h2 class="heading heading--3 page-wrapper__title">{{ title }}</h2>

      <div class="page-wrapper__actions">
        <transition name="fade" mode="out-in">
          <div
            class="page-wrapper__online-status specialButton button--brand-2"
            v-if="!$store.state.isOnline"
          >
            <span class="button__symbol">
              <Svg-icon iconType="Offline-icon" />
            </span>
          </div>
        </transition>
        <div class="page-wrapper__logout">
          <Logout />
        </div>
      </div>
    </div>

    <div class="page-wrapper__inner" :class="{ colored }">
      <slot />
    </div>
  </div>
</template>
