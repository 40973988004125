<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 49.9 49.3"
    xml:space="preserve"
  >
    <g>
      <path
        d="M42.2,11H7.7c-0.6,0-1,0.5-1,1v26v0v0c0,0.6,0.5,1,1,1h34.5c0.6,0,1-0.5,1-1v0v0l0-21.1V12 C43.2,11.5,42.8,11,42.2,11z M13.6,37H8.7v-3.2h4.8V37z M13.6,31.7H8.7v-3.2h4.8V31.7z M13.6,26.5H8.7v-3.2h4.8V26.5z M13.6,21.2 H8.7v-3.2h4.8V21.2z M20.4,28.5v3.2h-4.8v-3.2H20.4z M15.6,21.2v-3.2h4.8v3.2H15.6z M20.5,37h-4.8v-3.2h4.8V37z M27.4,37h-4.8v-3.2 h4.8V37z M22.5,21.2v-3.2h4.8v3.2H22.5z M27.4,26.5h-4.8v-3.2h4.8V26.5z M34.3,37h-4.8v-3.2h4.8V37z M29.4,31.7v-3.2h4.8v3.2H29.4z M34.3,26.5h-4.8v-3.2h4.8V26.5z M41.2,37h-4.8v-3.2h4.8V37z M41.2,31.7h-4.8v-3.2h4.8V31.7z M41.2,26.5h-4.8v-3.2h4.8V26.5z M41.2,21.2h-4.8v-3.2h4.8V21.2z M41.2,15.9H8.7v-2.8h32.4V15.9z"
      />
      <path
        d="M45.6,4.8h-2.4V1c0-0.6-0.5-1-1-1h-5.9c-0.6,0-1,0.5-1,1v3.8H14.6V1c0-0.6-0.5-1-1-1H7.7c-0.6,0-1,0.5-1,1v3.8 H4.2C1.9,4.8,0,6.7,0,9v32.1c0,2.3,1.9,4.2,4.2,4.2h41.4c2.3,0,4.2-1.9,4.2-4.2V9C49.9,6.7,48,4.8,45.6,4.8z M37.3,2.1h3.9v2.7 h-3.9V2.1z M8.7,2.1h3.9v2.7H8.7V2.1z M47.8,41.1c0,1.2-1,2.2-2.2,2.2H4.2c-1.2,0-2.2-1-2.2-2.2V9c0-1.2,1-2.2,2.2-2.2h41.4 c1.2,0,2.2,1,2.2,2.2L47.8,41.1z"
      />
      <path
        d="M16.1,47.2h-4.6c-0.6,0-1,0.5-1,1c0,0.6,0.5,1,1,1h4.6c0.6,0,1-0.5,1-1C17.1,47.7,16.7,47.2,16.1,47.2z"
      />
      <path
        d="M21.3,47.2h-1.7c-0.6,0-1,0.5-1,1c0,0.6,0.5,1,1,1h1.7c0.6,0,1-0.5,1-1C22.4,47.7,21.9,47.2,21.3,47.2z"
      />
      <path
        d="M38.4,47.2H25.1c-0.6,0-1,0.5-1,1c0,0.6,0.5,1,1,1h13.3c0.6,0,1-0.5,1-1C39.4,47.7,38.9,47.2,38.4,47.2z"
      />
    </g>
  </svg>
</template>
