<script>
import "./ChecklistOverview.scss";
import ChecklistOverview from "./ChecklistOverview.js";

export default ChecklistOverview;
</script>

<template>
  <div class="checklistOverview">
    <article class="checklistOverview__item">
      <header class="checklistOverview__item__header">
        <span class="checklistOverview__item__header__symbol">
          <Svg-icon iconType="Calendar-flat"></Svg-icon>
        </span>

        <h3 class="heading heading--6 checklistOverview__item__title">
          Dienst
        </h3>
      </header>

      <div class="checklistOverview__item__content">
        <ul class="checklistOverview__content__list">
          <li class="checklistOverview__content__listItem">
            <span class="checklistOverview__content__listItem__title"
              >Traject</span
            >

            <div class="checklistOverview__content__listItem__data">
              <span class="checklistOverview__content__listItem__symbol">
                <Svg-icon iconType="Anchor-circle"></Svg-icon>
              </span>

              <span class="checklistOverview__content__listItem__theContent">{{
                $store.state.flightSettings.track.name
              }}</span>
            </div>
          </li>

          <li class="checklistOverview__content__listItem">
            <span class="checklistOverview__content__listItem__title"
              >Dienstregeling</span
            >

            <div class="checklistOverview__content__listItem__data">
              <span class="checklistOverview__content__listItem__symbol">
                <Svg-icon iconType="Calendar-flat"></Svg-icon>
              </span>

              <span class="checklistOverview__content__listItem__theContent">{{
                $store.state.flightSettings.shift.name
              }}</span>
            </div>
          </li>

          <li class="checklistOverview__content__listItem">
            <span class="checklistOverview__content__listItem__title"
              >Schip</span
            >

            <div class="checklistOverview__content__listItem__data">
              <span class="checklistOverview__content__listItem__symbol">
                <Svg-icon iconType="Ship-front"></Svg-icon>
              </span>

              <span class="checklistOverview__content__listItem__theContent">{{
                $store.state.flightSettings.ship.name
              }}</span>
            </div>
          </li>

          <li class="checklistOverview__content__listItem">
            <span class="checklistOverview__content__listItem__title"
              >Kapitein</span
            >

            <div class="checklistOverview__content__listItem__data">
              <span class="checklistOverview__content__listItem__symbol">
                <Svg-icon iconType="Wheel"></Svg-icon>
              </span>

              <span class="checklistOverview__content__listItem__theContent">{{
                $store.state.captainDetails
                  ? $store.state.captainDetails.name
                  : ""
              }}</span>
            </div>
          </li>

          <li class="checklistOverview__content__listItem">
            <span class="checklistOverview__content__listItem__title"
              >Steward(s)</span
            >

            <div class="checklistOverview__content__listItem__data">
              <span class="checklistOverview__content__listItem__symbol">
                <Svg-icon iconType="Lifebuoy-flat" />
              </span>

              <span class="checklistOverview__content__listItem__theContent">
                <template
                  v-for="(steward, index) in $store.state.flightSettings.crew"
                  >{{ index >= 1 ? ", " : "" }}{{ steward.full_name }}</template
                >
              </span>
            </div>
          </li>
        </ul>
      </div>
    </article>

    <article
      v-for="node in $store.state.checklist.checklist"
      class="checklistOverview__item"
      :key="node.name"
    >
      <header class="checklistOverview__item__header">
        <span class="checklistOverview__item__header__symbol">
          <Svg-icon iconType="Calendar-flat" />
        </span>

        <h3 class="heading heading--6 checklistOverview__item__title">
          {{ node.name }}
        </h3>
      </header>

      <div class="checklistOverview__item__details">
        <div
          v-for="subNode in node.subcategories"
          class="checklistOverview__item__details__item"
          :key="subNode.name"
        >
          <span class="checklistOverview__details__title">{{
            subNode.name
          }}</span>

          <ul class="checklistOverview__details__list">
            <li
              v-for="subSubNode in subNode.fields"
              class="checklistOverview__details__listItem"
              :key="
                subSubNode.details ? subSubNode.details.name : subSubNode.name
              "
            >
              <span class="checklistOverview__details__listItem__title">{{
                subSubNode.details ? subSubNode.details.name : subSubNode.name
              }}</span>

              <div class="checklistOverview__details__listItem__data">
                <span
                  v-if="getValue(subSubNode) !== false"
                  class="checklistOverview__details__listItem__theContent"
                >
                  {{ getValue(subSubNode) }}
                </span>
              </div>

              <div class="checklistOverview__details__listItem__checkmark">
                <Svg-icon iconType="Check-circle" />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </article>
  </div>
</template>
