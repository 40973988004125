import Notifications from "../../../api/Notifications";

export default {
  data() {
    return {
      trackId: null,
    };
  },

  created() {
    this.trackId = this.$store.state.flightSettings.track.id;
    if (this.trackId) {
      // this.readAll();
    }
  },

  methods: {
    readAll() {
      //add all current items to read array
      this.$store.commit({
        type: "setReadNotifications",
        payload: [...this.$store.state.notifications],
      });

      //set all read
      Notifications.readAll(this.trackId).then((r) => {});
      //update notifications
      this.emitter.emit("app--getNotifications");
    },
    handleDetailClick(theNotification) {
      const viewData = {
        notification: theNotification,
        isNew: false,
      };

      const currentRead = [...this.$store.state.readNotifications];

      //check if read already
      let present = false;

      currentRead.forEach((readNotification) => {
        if (readNotification.id === theNotification.id) {
          present = true;
        }
      });
      if (!present) {
        //add to read notifications
        this.$store.commit({
          type: "setReadNotifications",
          payload: [...currentRead, theNotification],
        });

        const currentSending = this.$store.state.readNotificationSendInfo;

        this.$store.commit({
          type: "setReadNotificationSendInfo",
          payload: {
            needsSending: currentSending
              ? currentSending.needsSending
                ? [...currentSending.needsSending, theNotification]
                : [theNotification]
              : [theNotification],
            updated: true,
          },
        });

        //send to app
        this.emitter.emit("app--sendReadNotification");
      }

      this.emitter.emit("toggleOffcanvas", [
        true,
        "NotificationsDetail",
        viewData,
      ]);
    },

    formatDateOfNotification(notification) {
      let dateStamp = new Date();
      let theDate = notification.created_at.substring(
        0,
        notification.created_at.length - 8
      );

      return dateStamp == theDate ? "Vandaag" : theDate;
    },

    formatTimeOfNotification(notification) {
      let theTime = notification.created_at.substring(
        10,
        notification.created_at.length - 3
      );

      return theTime;
    },
    notificationIsRead(notification) {
      return this.$store.state.readNotifications.filter(
        (readNote) => readNote.id === notification.id
      ).length;
    },
  },
};
