export default {
  props: {
    viewData: Object,
  },

  data() {
    return {
      theSteps: this.viewData.steps,
    };
  },
  methods: {
    isActive(index) {
      const activeStep = parseInt(this.$route.params.step);
      if (isNaN(activeStep)) {
        return index === 0;
      } else {
        return index <= activeStep;
      }
    },
    isDisabled(index) {
      return false;
    },
  },
};
