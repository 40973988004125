export const baseUrl = window.location.origin + "/api";

export const defaultStore = {
  captainDetails: null,
  weather: null,
  soundMp3: null,
  allTracks: [],
  allShifts: [],
  allShips: [],
  allCrew: [],
  allStops: [],
  currentStops: [],
  currentStopIndex: -1,
  currentStopsSendInfo: {
    succesfullySend: [],
    needsRemoving: [],
    updated: false,
    isSending: false,
    isSend: false,
  },
  flightSettings: {
    id: null,
    track: null, //{ channel: "gW2agDVufwB9peKU", id: 1, name: "Schelde" }
    shift: null,
    ship: null,
    crew: [],
    isSending: false,
    isSend: false,
  },
  incompleteFlights: [],
  checklist: null,
  checklistValues: [],
  checklistRemarks: [],
  checklistSelectedOptions: [],
  checklistCompletedLists: [],
  checklistIsSending: false,
  checklistIsSend: false,
  checklistIsUpdated: false,
  checklistInitValues: [],
  passengers: {
    adding_people: 0,
    removing_people: 0,
    current_people: 0,
    adding_bikes: 0,
    removing_bikes: 0,
    current_bikes: 0,
    max_people: 0,
    max_bikes: 0,
  },
  newMalfunction: {},
  newMalfunctionSendInfo: {
    needsSending: [],
    updated: false,
    isSending: false,
  },
  malfunctions: [],
  notifications: [],
  readNotifications: [],
  readNotificationSendInfo: {
    needsSending: [],
    updated: false,
    isSending: false,
  },
  tmpImg: null,
  isCompleted: false,
  isOnline: true,
};
