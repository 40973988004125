<template>
	<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 48.2 52.8" xml:space="preserve">
		<g>
			<path d="M47.2,28.3c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.5,0.3,0.7l0,0v19.3c0,2.3-1.9,4.2-4.2,4.2c-2.3,0-4.2-1.9-4.2-4.2l0,0v-1 H2.1v1c0,1.2,0.9,2.1,2.1,2.1l0,0h34.6c0.6,0,1,0.5,1,1c0,0.6-0.5,1-1,1l0,0H4.2c-1.1,0-2.2-0.4-3-1.2c-0.8-0.8-1.2-1.9-1.2-3l0,0 v-2.1c0-0.6,0.5-1,1-1l0,0h39.8c0.3,0,0.5,0.1,0.7,0.3s0.3,0.5,0.3,0.7l0,0v2.1c0,1.2,0.9,2.1,2.1,2.1s2.1-0.9,2.1-2.1l0,0V29.3 C46.1,28.8,46.6,28.3,47.2,28.3z"/>
			<path d="M30.4,0c1.7,0,3.1,1.4,3.1,3.1l0,0v1h5.2c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.5,0.3,0.7l0,0v6.3c0,0.3-0.1,0.5-0.3,0.7 c-0.2,0.2-0.5,0.3-0.7,0.3l0,0h-4.2c-0.6,0-1-0.5-1-1s0.5-1,1-1l0,0h3.1V6.3h-5.2c-0.3,0-0.5-0.1-0.7-0.3s-0.3-0.5-0.3-0.7l0,0V3.1 c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.5-0.3-0.7-0.3l0,0h-6.3c-0.6,0-1,0.5-1,1l0,0v2.1c0,0.3-0.1,0.5-0.3,0.7 c-0.2,0.2-0.5,0.3-0.7,0.3l0,0h-5.2v4.2h13.6c0.6,0,1,0.5,1,1s-0.5,1-1,1l0,0H15.7c-0.3,0-0.5-0.1-0.7-0.3 c-0.2-0.2-0.3-0.5-0.3-0.7l0,0V9.4H8.4v32.2c0,0.6-0.5,1-1,1s-1-0.5-1-1l0,0V8.4c0-0.6,0.5-1,1-1l0,0h7.3V5.2c0-0.6,0.5-1,1-1l0,0 H21l0-1C21,1.4,22.4,0,24.1,0l0,0H30.4z"/>
			<path d="M32.5,35.6c0.6,0,1,0.5,1,1c0,0.6-0.5,1-1,1l0,0h-8.4c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1l0,0H32.5z"/>
			<path d="M37.7,35.6c0.6,0,1,0.5,1,1c0,0.6-0.5,1-1,1l0,0h-1c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1l0,0H37.7z"/>
			<path d="M20.7,30.7c-0.2-0.2-0.5-0.3-0.7-0.3h-5.2c-0.6,0-1,0.5-1,1v5.2c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.5,0.3,0.7,0.3h5.2 c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.5,0.3-0.7v-5.2C21,31.2,20.9,30.9,20.7,30.7z M18.9,35.6h-3.1v-3.1h3.1V35.6z"/>
			<path d="M40.9,30.4c0.6,0,1,0.5,1,1c0,0.6-0.5,1-1,1l0,0H24.1c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1l0,0H40.9z"/>
			<path d="M47.2,23.1c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.5,0.3,0.7l0,0v1c0,0.6-0.5,1-1,1c-0.6,0-1-0.5-1-1l0,0v-1 C46.1,23.5,46.6,23.1,47.2,23.1L47.2,23.1z"/>
			<path d="M32.5,23.1c0.6,0,1,0.5,1,1s-0.5,1-1,1l0,0h-8.4c-0.6,0-1-0.5-1-1s0.5-1,1-1l0,0H32.5z"/>
			<path d="M37.7,23.1c0.6,0,1,0.5,1,1s-0.5,1-1,1l0,0h-1c-0.6,0-1-0.5-1-1s0.5-1,1-1l0,0H37.7z"/>
			<path d="M20.7,18.1c-0.2-0.2-0.5-0.3-0.7-0.3h-5.2c-0.6,0-1,0.5-1,1v5.2c0,0.3,0.1,0.5,0.3,0.7s0.5,0.3,0.7,0.3h5.2 c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.5,0.3-0.7v-5.2C21,18.6,20.9,18.3,20.7,18.1z M18.9,23.1h-3.1v-3.1h3.1V23.1z"/>
			<path d="M47.2,7.3c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.5,0.3,0.7l0,0v11.5c0,0.6-0.5,1-1,1c-0.6,0-1-0.5-1-1l0,0V9.4h-4.2 c-0.6,0-1-0.5-1-1s0.5-1,1-1l0,0L47.2,7.3L47.2,7.3z"/>
			<path d="M40.9,17.8c0.6,0,1,0.5,1,1s-0.5,1-1,1l0,0H24.1c-0.6,0-1-0.5-1-1s0.5-1,1-1l0,0H40.9z"/>
		</g>
	</svg>
</template>