import axios from "./index";

export default {
  overview(trackId, vueThis) {
    return axios
      .get(`notifications/index?track=${trackId}&read=0`)
      .then((response) => response);
  },

  all(trackId) {
    return axios.get(`notifications/index?track=${trackId}`);
  },

  readAll(trackId) {
    return axios.post(`notifications/readAll?track=${trackId}`);
  },

  read(trackId, notificationId) {
    return axios.post(
      `notifications/read?track=${trackId}&notificationId=${notificationId}`
    );
  },
};
