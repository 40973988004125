export default {
  props: {
    viewData: Object,
  },

  data() {
    return {
      theNotification: this.viewData.notification,
      isNew: this.viewData.isNew,
      isUrgent: this.viewData.notification.urgent,
    };
  },

  methods: {
    handleHelpClick() {
      this.emitter.emit("toggleOffcanvas", false);
    },

    handleBackClick() {
      this.emitter.emit("toggleOffcanvas", false);
    },

    formatDateOfNotification(notification) {
      let theDate = notification.created_at.substring(
        0,
        notification.created_at.length - 8
      );

      let [yyyy, mm, dd] = theDate.split("-");

      theDate = `${yyyy}/${mm}/${dd}`;

      return theDate;
    },

    formatTimeOfNotification(notification) {
      let theTime = notification.created_at.substring(
        10,
        notification.created_at.length - 3
      );

      theTime = theTime.replace(":", "h");

      return theTime;
    },
  },
};
