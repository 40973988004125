const importAllVue = (app) => {
  	app.component("Camera", require("./Camera/Camera.vue").default); 
	app.component("Counter", require("./Counter/Counter.vue").default); 
	app.component("Date", require("./Date/Date.vue").default); 
	app.component("Loader", require("./Loader/Loader.vue").default); 
	app.component("Logout", require("./Logout/Logout.vue").default); 
	app.component("Step-tracker", require("./StepTracker/StepTracker.vue").default); 
	app.component("Svg-icon", require("./SvgIcon/SvgIcon.vue").default); 
	app.component("Time", require("./Time/Time.vue").default); 
	app.component("Weather", require("./Weather/Weather.vue").default); 
;
};

export default importAllVue;
