const importAllVue = (app) => {
  	app.component("Checklist-confirm", require("./ChecklistConfirm/ChecklistConfirm.vue").default); 
	app.component("Checklist-content", require("./ChecklistContent/ChecklistContent.vue").default); 
	app.component("Checklist-nav", require("./ChecklistNav/ChecklistNav.vue").default); 
	app.component("Checklist-overview", require("./ChecklistOverview/ChecklistOverview.vue").default); 
	app.component("Comments-confirm", require("./CommentsConfirm/CommentsConfirm.vue").default); 
	app.component("Comments-form", require("./CommentsForm/CommentsForm.vue").default); 
	app.component("Crew-form", require("./CrewForm/CrewForm.vue").default); 
	app.component("Flight-confirm", require("./FlightConfirm/FlightConfirm.vue").default); 
	app.component("Flight-insert-form", require("./FlightInsertForm/FlightInsertForm.vue").default); 
	app.component("Flight-skip-form", require("./FlightSkipForm/FlightSkipForm.vue").default); 
	app.component("Login-form", require("./LoginForm/LoginForm.vue").default); 
	app.component("Logout-confirm", require("./LogoutConfirm/LogoutConfirm.vue").default); 
	app.component("Malfunctions-confirm", require("./MalfunctionsConfirm/MalfunctionsConfirm.vue").default); 
	app.component("Malfunctions-detail", require("./MalfunctionsDetail/MalfunctionsDetail.vue").default); 
	app.component("Malfunctions-notes", require("./MalfunctionsNotes/MalfunctionsNotes.vue").default); 
	app.component("Malfunctions-situation", require("./MalfunctionsSituation/MalfunctionsSituation.vue").default); 
	app.component("Notifications-detail", require("./NotificationsDetail/NotificationsDetail.vue").default); 
	app.component("Passenger-counter", require("./PassengerCounter/PassengerCounter.vue").default); 
	app.component("Pincode-form", require("./PincodeForm/PincodeForm.vue").default); 
	app.component("Pin-confirm", require("./PinConfirm/PinConfirm.vue").default); 
	app.component("Shift-resume", require("./ShiftResume/ShiftResume.vue").default); 
	app.component("Success", require("./Success/Success.vue").default); 
	app.component("Whoops-error", require("./WhoopsError/WhoopsError.vue").default); 
;
};

export default importAllVue;
