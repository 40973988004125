import axios from "./index";

export default {
  overview(vueThis) {
    return axios.get(`/stewards/overview`).then((response) => response);
  },

  create(formData, vueThis) {
    return axios
      .post("/stewards/create?", formData)
      .then((response) => response);
  },
};
