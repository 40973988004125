import axios from "./index";

export default {
  checkPin(formData, vueThis) {
    return axios
      .post("/captains/checkpin", formData)
      .then((response) => response)
      .catch((error) =>
        error.response.status !== 500
          ? error.response
          : vueThis.emitter.emit("toggleOffcanvas", [true, "Whoops"])
      );
  },
};
