import Tracks from "../../../api/Tracks";
import { VueAgile } from "vue-agile";

export default {
  components: {
    agile: VueAgile,
  },
  data() {
    return {
      isLoading: false,
      chosenTrack: null,
      slides: [],
    };
  },
  created() {
    //disable initialy
    if (
      this.$store.state.isOnline &&
      localStorage.getItem("token") &&
      this.$store.state.allTracks.length === 0
    ) {
      this.isLoading = true;
      // get tracks
      Tracks.overview()
        .then((r) => this.onSuccess(r))
        .catch((e) => {
          this.onFail(e);
        });
    } else if (!localStorage.getItem("token")) {
      this.$router.push("/");
    }

    if (this.$store.state.flightSettings.track) {
      this.chosenTrack = { ...this.$store.state.flightSettings.track };
      this.emitter.emit("start--set-disabled", false);

      //move slider to selected
    } else {
      this.emitter.emit("start--set-disabled", true);
    }

    //set slides if present
    if (this.$store.state.allTracks.length) {
      this.slides = this.$store.state.allTracks;

      this.moveToSelected();
    }

    //this.getTracks();
    this.emitter.emit("start--set-disabled", true);

    //set slides if present
    if (this.$store.state.allTracks.length) {
      this.slides = this.$store.state.allTracks;

      this.moveToSelected();
    }
  },
  methods: {
    getTracks() {
      // get the tracks
      //check if online
      if (this.$store.state.isOnline) {
        this.isLoading = true;

        Tracks.overview().then((response) =>
          response.status !== 200
            ? this.onFail(response)
            : this.onSuccess(response)
        );
      }
    },

    onSuccess(response) {
      const allTracks = JSON.parse(response.data.body);
      this.$store.commit({
        type: "setAllTracks",
        payload: allTracks,
      });

      if (allTracks.length === 1) {
        this.chosenTrack = allTracks[0];
      }

      this.slides = allTracks;

      this.moveToSelected();

      this.isLoading = false;
    },

    onFail(response) {
      this.isLoading = true;

      let theMessage = {};
      theMessage.status = response.status;
      theMessage.title = response.data.message;
      theMessage.subtitle = "Oeps?!";
      theMessage.body = "";
      theMessage.icon = "whoops";

      this.emitter.emit("toggleOffcanvas", true, "Whoops", theMessage);
    },

    handleChange(chosenTrack) {
      this.chosenTrack = chosenTrack;
    },

    moveToSelected() {
      if (this.chosenTrack && this.$refs.carousel && this.slides) {
        this.slides.forEach((slide, index) => {
          if (slide.id === this.chosenTrack.id) {
            //is current slide index
            this.$refs.carousel.goTo(index);
          }
        });
      }
    },
  },
  watch: {
    "$store.state.allTracks"() {},
    chosenTrack() {
      if (this.chosenTrack) {
        //set active
        this.$store.commit({
          type: "setFlightSettings",
          payload: { track: { ...this.chosenTrack } },
        });

        //exist allow next step
        this.emitter.emit("start--set-disabled", false);
      }
    },
  },
};
