export default {
  data() {
    return {
      isLoading: false,
      flightInfo: Object,
      nextStop: Object,
      shift: String,
    };
  },

  created() {
    // get flight details
    this.getFlightMinimalDetails();
  },

  methods: {
    timeOfDeparture(departure) {
      return departure
        ? departure.replace(":", "u").substring(0, departure.length - 3)
        : "";
    },
    handleResumeShiftClick() {
      // pass the resumed flight info
      //this.$emit("emitResumedShift", this.flightInfo);
      this.emitter.emit("start--goToNext", 3);
      this.$router.push("/start/3");
      this.emitter.emit("toggleOffcanvas", false);
    },

    handleNewShiftClick() {
      //this.$emit("emitNewShift");
    },

    handleBack() {
      //remove selected shift
      this.$store.commit({
        type: "setFlightSettings",
        payload: {
          shift: null,
        },
      });

      this.emitter.emit("toggleOffcanvas", false);
    },

    getFlightMinimalDetails() {
      if (this.$store.state.flightSettings.shift) {
        if (this.$store.state.flightSettings.shift.last_flight) {
          const flightId =
            this.$store.state.flightSettings.shift.last_flight.id;

          const fullflight = this.$store.state.incompleteFlights.filter(
            (item) => {
              const flightData =
                typeof item.flight === "object"
                  ? item.flight
                  : JSON.parse(item.flight);

              return flightData.id === flightId;
            }
          )[0];

          if (fullflight) {
            const oldStore = JSON.parse(localStorage.getItem("oldStore"));

            this.flightInfo =
              typeof fullflight.flight === "object"
                ? fullflight.flight
                : JSON.parse(fullflight.flight);

            this.nextStop =
              typeof fullflight.next === "object"
                ? fullflight.next
                : JSON.parse(fullflight.next);

            if (oldStore) {
              if (oldStore.flightSettings.id === this.flightInfo.id) {
                //same item use old current stop

                if (oldStore.currentStops[oldStore.currentStopIndex]) {
                  this.nextStop =
                    oldStore.currentStops[oldStore.currentStopIndex];
                }
              }
            }

            this.shift = this.$store.state.flightSettings.shift.name;

            //set current stops
            if (fullflight.all_items) {
              this.$store.commit({
                type: "setCurrentStops",
                payload: fullflight.all_items,
              });

              //update already send items
              this.$store.commit({
                type: "setCurrentStopsSendInfo",
                payload: {
                  succesfullySend: fullflight.all_items.filter(
                    (stop) => (stop.passed && stop.arrived) || stop.skipped
                  ),
                },
              });

              //set init index
              let initIndex = this.$store.state.currentStopIndex;
              let isFirst = false;

              fullflight.all_items.forEach((item, index) => {
                if (
                  (!item.arrived || !item.passed) &&
                  !item.skipped &&
                  initIndex === -1 &&
                  index !== 0
                ) {
                  initIndex = index;
                } else if (
                  index === 0 &&
                  (!item.arrived || !item.passed) &&
                  !item.skipped
                ) {
                  isFirst = true;
                }
              });

              if (isFirst) {
                initIndex = -1;
              }

              this.$store.commit({
                type: "setCurrentStopIndex",
                payload: initIndex,
              });

              //check if the list is completed
              /* if (
                this.$store.state.currentStops.filter((stop) =>
                  stop ? stop.passed === 1 : false
                ).length >= this.$store.state.currentStops.length &&
                this.$store.state.currentStops.length > 0
              ) {
                this.$store.commit({
                  type: "setIsCompleted",
                  payload: true,
                });

                //check if a new checklist is needed
              } else {
                this.$store.commit({
                  type: "setIsCompleted",
                  payload: false,
                });
              }*/
            }
          }

          /* this.isLoading = true;
          Flights.detail(flight)
            .then((r) => this.onSuccess(r))
            .catch((e) => {
              this.onFail(e);
            });*/
        }
      }
    },

    onSuccess(r) {
      this.nextStop =
        typeof r.data.body.next === "object"
          ? r.data.body.next
          : JSON.parse(r.data.body.next);

      this.flightInfo =
        typeof r.data.body.flight === "object"
          ? r.data.body.flight
          : JSON.parse(r.data.body.flight);
      this.shift = this.$store.state.flightSettings.shift.name;

      this.isLoading = false;
    },

    onFail(r) {
      this.isLoading = false;
      /* 
      let theMessage = {};
      theMessage.status = r.status;
      theMessage.title = r.message;
      theMessage.subtitle = "Oeps?!";
      theMessage.body = "Resume error";
      theMessage.icon = "whoops";

      this.isLoading = false;

      this.emitter.emit("toggleOffcanvas", [true, "Whoops", theMessage]);*/
    },

    parseStewards(stewards) {
      return stewards.map((steward) => steward.full_name).join(", ");
    },
  },
};
