<script>
import "./Logout.scss";
import Logout from "./Logout.js";

export default Logout;
</script>

<template>
  <div class="logout__container">
    <div class="logout__container__inner">
      <span class="logout__container__symbol">
        <Svg-icon iconType="Wheel" />
      </span>
      <span
        v-if="$store.state.captainDetails"
        class="dashboard__logout__label"
        >{{ $store.state.captainDetails.name }}</span
      >
    </div>

    <div class="logout">
      <button @click="handleClick()" class="specialButton logout__button">
        <span class="logout__button__symbol">
          <Svg-icon iconType="Power-off" />
        </span>
      </button>
    </div>
  </div>
</template>
