export default {
  data() {
    return {
      activeStep: this.$route.params.step,
      activeSubStep: this.$route.params.subStep,
      activeCheckList: null,
    };
  },

  created() {
    this.setActiveCheckList();
  },
  mounted() {
    this.formCompete();
  },
  methods: {
    hasField(item, key) {
      if (item.field) {
        return item.field[key];
      } else {
        return item[key];
      }
    },
    setActiveCheckList() {
      if (this.$store.state.checklist) {
        const checklist =
          this.$store.state.checklist.checklist[
            this.activeStep ? this.activeStep : 0
          ];

        if (checklist) {
          this.activeCheckList = checklist;
        }
      }
    },
    itemIsSelected(itemId) {
      return this.$store.state.checklistSelectedOptions.includes(itemId);
    },
    addInputValue(addValue, itemId) {
      //add to checklist selected
      let values = [...this.$store.state.checklistValues];
      let valueExists = false;

      values = values.map((value) => {
        if (value) {
          if (value.id === itemId) {
            valueExists = true;

            return { ...value, value: addValue };
          }
        }

        return value;
      });

      if (!valueExists) {
        values.push({
          id: itemId,
          value: addValue,
        });
      }

      this.$store.commit({
        type: "setChecklistValues",
        payload: values,
      });
    },

    handleInputChange(e, itemId) {
      const input = e.currentTarget;

      if (input.value.length > input.maxLength) {
        input.value = input.value.slice(0, input.maxLength);
      }

      this.addInputValue(input.value, itemId);

      //if input is set then check the box
      this.checkItem(itemId, input.value.length > 0);

      //check if form is complete
      this.formCompete();
    },
    handleCheckChange(e, itemId) {
      this.checkItem(itemId, e.currentTarget.checked);
    },
    checkItem(itemId, isChecked) {
      let selectedOptions = [...this.$store.state.checklistSelectedOptions];

      //set related input to zero if no value is set
      if (isChecked) {
        selectedOptions.push(itemId);
        this.setInputValue(0, itemId);
      } else {
        selectedOptions = selectedOptions.filter((option) => option !== itemId);
        this.setInputValue(null, itemId);
      }

      this.$store.commit({
        type: "setChecklistSelectedOptions",
        payload: selectedOptions,
      });

      //check if form is complete
      setTimeout(() => {
        this.formCompete();
      }, 50);
    },
    setInputValue(value, itemId) {
      const input = document.querySelector(`#input-${itemId}`);
      if (input) {
        if (input.value === null || input.value === "") {
          input.value = value;
          this.addInputValue(value, itemId);
        }
      }
    },
    setDefaultInput(id) {
      let result = 0;

      this.$store.state.checklistValues.forEach((val) => {
        if (val) {
          if (val.id === id) {
            result = val.value;
          }
        }
      });

      return result;
    },
    formCompete() {
      if (this.$refs.contentForm) {
        const isValid = this.$refs.contentForm.checkValidity();

        if (isValid) {
          //the form is complete
          this.emitter.emit("checklist--formReady");
        } else {
          this.emitter.emit("checklist--formNotReady");
        }

        this.updatedCompletedLists(isValid);
      }
    },

    updatedCompletedLists(isValid) {
      let completedLists = [...this.$store.state.checklistCompletedLists];
      const initCompletedLists = completedLists;
      const step = parseInt(
        this.activeStep !== undefined ? this.activeStep : 0
      );
      if (isValid) {
        //add
        if (completedLists.filter((list) => list === step).length === 0) {
          completedLists.push(step);
        }
      } else {
        //remove
        completedLists = completedLists.filter((list) => list !== step);
      }
      if (
        JSON.stringify(initCompletedLists) === JSON.stringify(completedLists)
      ) {
        this.$store.commit({
          type: "setChecklistCompletedLists",
          payload: completedLists,
        });
      }
    },

    handleCommentsClick(item) {
      const remark = this.checkItemForRemarks(item);

      this.emitter.emit("toggleOffcanvas", [
        true,
        "CommentsForm",
        { ...item, remark: remark ? remark.remark : "" },
      ]);
    },

    checkItemForRemarks(item) {
      return this.$store.state.checklistRemarks.filter(
        (remark) => remark.id === item.id
      )[0];
    },
  },
  watch: {
    "$store.state.checklistSelectedOptions"() {
      this.formCompete();
    },

    "$store.state.checklistValues"() {
      this.formCompete();
    },

    $route() {
      this.activeStep = this.$route.params.step;
      this.activeSubStep = this.$route.params.subStep;
      this.setActiveCheckList();
      setTimeout(() => {
        this.formCompete();
      }, 50);
    },
  },
};
