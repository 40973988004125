<template>
	<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 20.9 20.9" xml:space="preserve">
		<g>
			<path d="M10.4,4.6c-3.2,0-5.8,2.6-5.8,5.8s2.6,5.8,5.8,5.8s5.8-2.6,5.8-5.8S13.6,4.6,10.4,4.6z M10.4,14.5c-2.2,0-4.1-1.8-4.1-4.1
				s1.8-4.1,4.1-4.1s4.1,1.8,4.1,4.1S12.7,14.5,10.4,14.5z"/>
			<path d="M11.3,2.1V0.9c0-0.5-0.4-0.9-0.9-0.9C10,0,9.6,0.4,9.6,0.9v1.2v1.4h1.7V2.1z"/>
			<path d="M9.6,18.8V20c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9v-1.2v-1.4H9.6V18.8z"/>
			<path d="M20,9.6h-1.2h-1.4v1.7h1.4H20c0.5,0,0.9-0.4,0.9-0.9C20.9,10,20.5,9.6,20,9.6z"/>
			<path d="M3.4,9.6H2H0.9C0.4,9.6,0,10,0,10.4c0,0.5,0.4,0.9,0.9,0.9h1.2h1.4L3.4,9.6L3.4,9.6z"/>
			<path d="M17,5.1L17,5.1l0.8-0.8c0.3-0.3,0.3-0.9,0-1.2s-0.9-0.3-1.2,0l-1.8,1.8L16,6.1L17,5.1z"/>
			<path d="M4.9,14.8l-1,1L3,16.6c-0.3,0.3-0.3,0.9,0,1.2C3.2,18,3.4,18,3.6,18c0.2,0,0.4-0.1,0.6-0.2L5.1,17l0,0l1-1L4.9,14.8z"/>
			<path d="M17,15.7L17,15.7l-1-0.9L14.8,16l1.8,1.8C16.8,18,17,18,17.2,18s0.4-0.1,0.6-0.2c0.3-0.3,0.3-0.9,0-1.2L17,15.7z"/>
			<path d="M3.9,5.1L3.9,5.1l1,1l1.2-1.2l-1-1l0,0L4.3,3.1C3.9,2.7,3.4,2.7,3,3.1C2.7,3.4,2.7,4,3,4.3L3.9,5.1z"/>
		</g>
	</svg>
</template>