<script>
import "./Counter.scss";
import Counter from "./Counter.js";

export default Counter;
</script>

<template>
  <div class="counter">
    <div class="counter__icon">
      <Svg-icon :iconType="icon" />
    </div>
    <div class="counter__content">
      <button
        class="counter__btn"
        :disabled="isPlusDisabled"
        @click.prevent="handleAdd"
      >
        <Svg-icon iconType="Plus-circle" />
      </button>
      <input
        class="input__field counter__input"
        type="number"
        placeholder="0"
        :min="min"
        :max="max"
        v-model="currentVal"
        @input="handleInput"
        @focus="handleFocus"
      />
      <button
        class="counter__btn"
        :disabled="isMinDisabled"
        @click.prevent="handleRemove"
      >
        <Svg-icon iconType="Min-circle" />
      </button>
    </div>
  </div>
</template>
