export default {
  data() {
    return {
      isLoading: false,
      maxSteps: 0,
      nextIsDisabled: true,
      currentStep: 0,
      checklistReady: false,
    };
  },

  watch: {
    $route() {
      this.setCurrentStep();
    },
  },

  created() {
    //this.isLoading = true;
    if (!this.$store.state.checklist || this.$route.query.end) {
      //create a checklist if non is present
      this.createChecklist();
    }

    this.emitter.on("checklist--formReady", () => {
      this.setNextBtnStatus(true);
    });

    this.emitter.on("checklist--formNotReady", () => {
      this.setNextBtnStatus(false);
    });

    this.setCurrentStep();
  },

  computed: {
    isLastItem() {
      this.setCurrentStep();

      return isNaN(this.currentStep);
    },

    isFirstItem() {
      return this.currentStep <= 0;
    },
  },

  methods: {
    setCurrentStep() {
      if (this.$route.params.step !== "overview") {
        this.currentStep = parseInt(
          this.$route.params.step !== undefined ? this.$route.params.step : 0
        );
      } else {
        this.currentStep = this.$route.params.step;
      }

      this.setChecklistReady();
    },

    setNextBtnStatus(formStatus) {
      //can go to next
      this.nextIsDisabled = !(formStatus && this.currentStep <= this.maxSteps);

      this.setChecklistReady();
    },

    setChecklistReady() {
      this.maxSteps = this.$store.state.checklist.checklist.length;

      this.checklistReady =
        this.$store.state.checklistCompletedLists.length >= this.maxSteps;
    },

    handleGoToSubmit() {
      this.$router.push("/dashboard");
    },

    createChecklist() {
      //get checklist from selected ship

      const fullShip = this.$store.state.allShips.filter(
        (ship) => ship.id === this.$store.state.flightSettings.ship.id
      )[0];

      let checklist = null;
      let checklistId = null;

      if (fullShip) {
        if (this.$route.query.end) {
          //get stop list
          checklist = fullShip.stopChecklist;
          checklistId = fullShip.stopChecklistId;
        } else {
          //get start list
          checklist = fullShip.startChecklist;
          checklistId = fullShip.startChecklistId;
        }

        this.$store.commit({
          type: "setChecklist",
          payload: { checklist: checklist, id: checklistId },
        });

        this.maxSteps = this.$store.state.checklist.checklist.length;
      }
      //set checked items

      //set completed

      this.isLoading = false;
    },

    onSuccess(r) {
      const checklist = JSON.parse(r.data.body);

      this.$store.commit({
        type: "setChecklist",
        payload: checklist,
      });

      //set max steps
      this.maxSteps = this.$store.state.checklist.checklist.length;

      this.setCurrentStep();

      this.isLoading = false;
    },

    onFail(r) {
      let theMessage = {};
      theMessage.status = r.status;
      theMessage.title = r.data.message;
      theMessage.subtitle = "Oeps?!";
      theMessage.body = "";
      theMessage.icon = "whoops";

      this.emitter.emit("toggleOffcanvas", true, "Whoops", theMessage);
      this.isLoading = false;
    },
    handleNextClick() {
      const step =
        parseInt(
          this.$route.params.step !== undefined ? this.$route.params.step : 0
        ) + 1;

      if (step < this.maxSteps) {
        //is next step

        if (!this.nextIsDisabled) {
          this.$router.push(
            `/checklist/${step}${this.$route.query.end ? "?end=true" : ""}`
          );
        }
      } else {
        //is overview

        this.$router.push(
          `/checklist/overview${this.$route.query.end ? "?end=true" : ""}`
        );
        this.setChecklistReady();
      }
    },
    handlePrevClick() {
      const step =
        parseInt(
          this.$route.params.step !== undefined ? this.$route.params.step : 0
        ) - 1;

      if (step >= 0) {
        //is previous

        this.$router.push(`/checklist/${step}`);
      }
    },
    handleSubmit() {
      const viewData = { checklistId: this.checklistId };

      this.emitter.emit("toggleOffcanvas", [
        true,
        "ChecklistConfirm",
        viewData,
      ]);
    },
    handleSubmitAndLogout() {
      const viewData = { checklistId: this.checklistId, upload: true };

      this.emitter.emit("toggleOffcanvas", [
        true,
        "ChecklistConfirm",
        viewData,
      ]);
    },
  },
  watch: {
    $route() {
      this.setCurrentStep();
    },
    "$store.state.flightSettings"() {
      if (!this.$store.state.checklist && this.$store.state.isOnline) {
        //no checklist present so add it
        this.createChecklist();
      } else {
        if (this.$store.state.checklist.checklist) {
          this.maxSteps = this.$store.state.checklist.checklist.length;
        } else {
          this.createChecklist();
        }
      }
    },
  },
};
