<template>
	<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 51.8 33.8" xml:space="preserve">
		<g>
			<path d="M42.6,15.5c-0.7,0-1.4,0.1-2.1,0.3c-0.9,0.2-1.8,0.6-2.6,1.1c-2.8,1.7-4.4,4.6-4.4,7.8c0,5,4.1,9.1,9.1,9.1 s9.1-4.1,9.1-9.1S47.7,15.5,42.6,15.5z M42.6,31.5c-3.8,0-6.9-3.1-6.9-6.9c0-2.3,1.1-4.4,3-5.7c0.9-0.6,1.8-1,2.8-1.1 c0.4-0.1,0.7-0.1,1-0.1c3.8,0,6.9,3.1,6.9,6.9C49.5,28.5,46.4,31.5,42.6,31.5z"/>
			<path d="M18.2,23.3c-0.3-2.1-1.3-4-2.9-5.4c-0.7-0.6-1.5-1.2-2.4-1.5c-1.2-0.5-2.5-0.8-3.8-0.8c-5,0-9.1,4.1-9.1,9.1 s4.1,9.1,9.1,9.1c4.5,0,8.3-3.3,9-7.7c0.1-0.5,0.1-1,0.1-1.4C18.3,24.2,18.2,23.8,18.2,23.3z M15.8,26.2c-0.7,3.1-3.5,5.3-6.7,5.3 c-3.8,0-6.9-3.1-6.9-6.9c0-3.8,3.1-6.9,6.9-6.9c0.8,0,1.6,0.1,2.4,0.4c1,0.4,1.8,0.9,2.6,1.7c0.9,0.9,1.5,2,1.7,3.2 c0.1,0.5,0.2,1,0.2,1.5C16,25.2,16,25.7,15.8,26.2z"/>
			<path d="M10.7,20.2l-2.5,3.9c-0.2,0.3-0.2,0.8-0.1,1.2c0.2,0.4,0.6,0.6,1,0.6h4.6c0.1-0.4,0.1-0.8,0.1-1.1c0-0.4,0-0.8-0.1-1.1 h-2.5l1.4-2.1C12.1,20.9,11.5,20.4,10.7,20.2z"/>
			<path d="M39.9,20.8l1.9,4.4c0.2,0.6,0.9,0.8,1.5,0.6c0.6-0.2,0.8-0.9,0.6-1.5L42,19.9C41.3,20,40.6,20.3,39.9,20.8z"/>
			<path d="M13.5,3.6c-0.6,0-1.1,0.5-1.1,1.1c0,0.6,0.5,1.1,1.1,1.1H21c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1H13.5z"/>
			<path d="M40.3,3.8l-2.4-3.3c-0.2-0.2-0.4-0.4-0.7-0.5c-0.3-0.1-0.6,0-0.8,0.2l0,0c-0.5,0.4-0.6,1.1-0.3,1.6L38,4.5L35.7,8H19.3 c-0.1,0-0.2,0-0.3,0l-0.1,0l-0.2,0.1c-0.1,0-0.1,0.1-0.1,0.1l-0.1,0.1c-0.1,0.1-0.1,0.1-0.1,0.2l-4.1,6.2c0.7,0.4,1.3,0.8,1.9,1.2 l2.9-4.4l4.4,10l-0.2,0.2c-0.5,0.5-0.9,1-1.1,1.7l-0.1,0.2h-1.9c0,0.4,0.1,0.8,0.1,1.1c0,0.3,0,0.7-0.1,1.1h1.9l0.1,0.2 c0.6,1.7,2.1,2.8,3.9,2.8c2.3,0,4.1-1.8,4.1-4.1c0-0.9-0.3-1.8-0.9-2.5L29.3,22L36,11.8l1.3,3c0.7-0.4,1.4-0.7,2.1-0.9l-1.9-4.4 l2.9-4.4C40.6,4.7,40.6,4.2,40.3,3.8z M27.9,25.2c-0.2,0.8-1,1.3-1.8,1.3c-0.2,0-0.3,0-0.5-0.1c-0.7-0.2-1.2-0.8-1.3-1.5 c0-0.1,0-0.2,0-0.2c0-1,0.8-1.8,1.8-1.8c0.5,0,1.1,0.2,1.4,0.7c0.3,0.3,0.4,0.8,0.4,1.2C28,24.8,27.9,25,27.9,25.2z M27.4,20.7 l-0.2-0.1c-0.4-0.1-0.9-0.1-1.3-0.1l-0.2,0l-4.5-10.3h13.1L27.4,20.7z"/>
		</g>
	</svg>
</template>