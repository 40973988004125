const importAllVue = (app) => {
  	app.component("Crew", require("./Crew/Crew.vue").default); 
	app.component("Flight-stop", require("./FlightStop/FlightStop.vue").default); 
	app.component("Malfunction-tracker", require("./MalfunctionTracker/MalfunctionTracker.vue").default); 
	app.component("Off-canvas", require("./OffCanvas/OffCanvas.vue").default); 
	app.component("Page-wrapper", require("./PageWrapper/PageWrapper.vue").default); 
	app.component("Passengers-form", require("./PassengersForm/PassengersForm.vue").default); 
	app.component("Photo-viewer", require("./PhotoViewer/PhotoViewer.vue").default); 
	app.component("Recap", require("./Recap/Recap.vue").default); 
	app.component("Shifts", require("./Shifts/Shifts.vue").default); 
	app.component("Ship", require("./Ship/Ship.vue").default); 
	app.component("Side-info", require("./SideInfo/SideInfo.vue").default); 
	app.component("Step-wrapper", require("./StepWrapper/StepWrapper.vue").default); 
	app.component("Tracker", require("./Tracker/Tracker.vue").default); 
	app.component("Tracks", require("./Tracks/Tracks.vue").default); 
	app.component("Widgets", require("./Widgets/Widgets.vue").default); 
;
};

export default importAllVue;
