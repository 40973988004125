<script>
import "./OffCanvas.scss";
import OffCanvas from "./OffCanvas.js";

export default OffCanvas;

/*

 <Comments-confirm
            v-if="viewToShow == 'CommentsConfirm'"
            :viewData="viewData"
          />

          <Flight-confirm
            v-if="viewToShow == 'FlightConfirm'"
            :viewData="viewData"
          />

          <Flight-delete
            v-if="viewToShow == 'FlightDelete'"
            :viewData="viewData"
          />

          <Flight-skip-form
            v-if="viewToShow == 'FlightSkipForm'"
            :viewData="viewData"
          />

          <Flight-stop-confirm v-if="viewToShow == 'FlightStopConfirm'" />

          <Logout-confirm
            v-if="viewToShow == 'LogoutConfirm'"
            :viewData="viewData"
          />

          <Malfunctions-detail
            v-if="viewToShow == 'MalfunctionsDetail'"
            :viewData="viewData"
          />

          <Notifications-detail
            v-if="viewToShow == 'NotificationsDetail'"
            :viewData="viewData"
          />

          <Passengers-form
            v-if="viewToShow == 'PassengersForm'"
            :viewData="viewData"
          />

          <Pincode-form v-if="viewToShow == 'PincodeForm'" />

          <Pin-confirm v-if="viewToShow == 'PinConfirm'" />

          <Success v-if="viewToShow == 'Success'" :viewData="viewData" />

          <Whoops v-if="viewToShow == 'Whoops'" :viewData="viewData" />
v-if="viewToShow == 'CommentsForm'"
*/
</script>

<template>
  <transition name="slide-fade">
    <section
      v-if="show"
      :class="['offcanvas', { 'is-urgent': viewData && viewData.urgent }]"
    >
      <div class="offcanvas__inner">
        <div class="offcanvas__actions">
          <div class="offcanvas__theCloser" @click="handleClick()">
            <div class="offcanvas__theCloser__symbol">
              <Svg-icon iconType="Close" />
            </div>
          </div>
        </div>

        <div class="offcanvas__content">
          <Checklist-confirm
            v-if="viewToShow === 'ChecklistConfirm'"
            :viewData="viewData"
          />

          <Comments-form
            v-else-if="viewToShow === 'CommentsForm'"
            :viewData="viewData"
          />

          <Comments-confirm
            v-else-if="viewToShow === 'CommentsConfirm'"
            :viewData="viewData"
          />

          <Notifications-detail
            v-else-if="viewToShow === 'NotificationsDetail'"
            :viewData="viewData"
          />
          <Malfunctions-detail
            v-else-if="viewToShow === 'MalfunctionsDetail'"
            :viewData="viewData"
          />

          <Pin-confirm
            v-else-if="viewToShow === 'PinConfirm'"
            :viewData="viewData"
          />
          <Pincode-form
            v-else-if="viewToShow === 'PincodeForm'"
            :viewData="viewData"
          />

          <Whoops-error
            v-else-if="viewToShow === 'Whoops'"
            :viewData="viewData"
          />

          <Passengers-form
            v-else-if="viewToShow === 'PassengersForm'"
            :viewData="viewData"
          />
          <Flight-skip-form
            v-else-if="viewToShow === 'FlightSkipForm'"
            :viewData="viewData"
          />

          <Flight-confirm
            v-else-if="viewToShow === 'FlightConfirm'"
            :viewData="viewData"
          />

          <Logout-confirm
            v-else-if="viewToShow === 'LogoutConfirm'"
            :viewData="viewData"
          />

          <Success v-else-if="viewToShow === 'Success'" :viewData="viewData" />

          <Shift-resume
            v-else-if="viewToShow === 'shiftResume'"
            :viewData="viewData"
          />
        </div>
      </div>
    </section>
  </transition>
</template>
