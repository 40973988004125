<script>
import "./Camera.scss";
import Camera from "./Camera.js";

export default Camera;
</script>

<template>
  <input
    class="camera"
    type="file"
    accept="image/*"
    capture
    ref="theInput"
    @change="onInputChange"
  />
</template>
