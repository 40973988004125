import Stewards from "../../../api/Stewards";

export default {
  data() {
    return {
      valid: true,
      inputs: {
        firstname: null,
        lastname: null,
      },
      classes: {
        isInvalid: "is-invalid",
        isDisabled: "is-disabled",
      },
    };
  },

  methods: {
    handleSubmit(e) {
      e.preventDefault();

      this.validateIt() && this.sendIt();
    },

    validateIt() {
      this.valid = true;

      !this.inputs.firstname &&
        (this.$refs.firstname.classList.add(this.classes.isInvalid),
        (this.valid = false));

      !this.inputs.lastname &&
        (this.$refs.lastname.classList.add(this.classes.isInvalid),
        (this.valid = false));

      if (!this.valid) {
        return false;
      }

      return true;
    },

    isCrewPresent() {
      return this.$store.state.allCrew.filter((crewMember) => {
        return (
          this.inputs.firstname.toLowerCase() ===
            crewMember.firstname.toLowerCase() &&
          this.inputs.lastname.toLowerCase() ===
            crewMember.lastname.toLowerCase()
        );
      })[0];
    },

    sendIt() {
      // disable send button
      this.$refs.submit.classList.add(this.classes.isDisabled);

      const existingMember = this.isCrewPresent();

      if (!existingMember) {
        //not present yet add a new one
        let formData = new FormData();

        formData.append("firstname", this.inputs.firstname);
        formData.append("lastname", this.inputs.lastname);

        Stewards.create(formData)
          .then((r) => this.onSuccess(r))
          .catch((e) => this.onFail(e));
      } else {
        //already in the list
        this.addToCurrentCrew(existingMember);
      }
    },

    onSuccess(r) {
      this.addToCurrentCrew(JSON.parse(r.data.body));
    },

    addToCurrentCrew(newSteward) {
      const theMessage = {};
      theMessage.title = "Steward toegevoegd";
      theMessage.subtitle = "Succesvol!";
      theMessage.body = "";
      theMessage.icon = "personAdded";

      this.resetForm();

      this.emitter.emit("toggleOffcanvas", [true, "Success", theMessage]);

      //add new crew member to all
      const allCrew = [...this.$store.state.allCrew];

      allCrew.push(newSteward);

      this.$store.commit({
        type: "setAllCrew",
        payload: allCrew.sort((a, b) => {
          const aName = a.firstname.toLowerCase();
          const bName = b.firstname.toLowerCase();

          if (aName < bName) {
            return -1;
          }

          if (aName > bName) {
            return 1;
          }

          return 0;
        }),
      });

      //setFlightSettings
      const currentCrew = [...this.$store.state.flightSettings.crew];

      currentCrew.push(newSteward);

      this.$store.commit({
        type: "setFlightSettings",
        payload: { crew: currentCrew },
      });

      this.emitter.emit("crew--addedSteward");
      this.emitter.emit("crew--toggleShowForm", false);
    },

    onFail(response) {
      this.isLoading = false;

      let theMessage = {};
      theMessage.status = response.status;
      theMessage.title = response.data.message;
      theMessage.subtitle = "Oeps?!";
      theMessage.body = "";
      theMessage.icon = "radar";

      this.emitter.emit("toggleOffcanvas", [true, "Whoops", theMessage]);
    },

    handleKeyevent(e) {
      e.target.parentNode.classList.remove(this.classes.isInvalid);
    },

    handleBackClick() {
      this.resetForm();

      this.emitter.emit("crew--toggleShowForm", false);
    },

    resetForm() {
      this.inputs.firstname = "";
      this.inputs.lastname = "";

      this.$refs.firstname.classList.remove(this.classes.isInvalid);
      this.$refs.lastname.classList.remove(this.classes.isInvalid);

      this.$refs.submit.classList.remove(this.classes.isDisabled);
    },
  },
};
