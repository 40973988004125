<script>
import "./Time.scss";
import Time from "./Time.js";

export default Time;
</script>

<template>
  <span class="time">{{ timestamp }}</span>
</template>
