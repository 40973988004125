import Ships from "../../../api/Ships";
import Stewards from "../../../api/Stewards";

export default {
  data() {
    return {
      activeStep: parseInt(this.$route.params.step),
      showBtns: true,
      isNextDisabled: true,
      steps: ["Traject", "Dienstregeling", "Schip", "Bemanning"],
    };
  },
  created() {
    //remove store
    localStorage.removeItem("store");

    //get captain details
    const captainDetails = { ...this.$store.state.captainDetails };

    this.$store.commit({
      type: "clearStore",
      payload: {},
    });

    this.$store.commit({
      type: "setCaptainDetails",
      payload: captainDetails,
    });

    //get ships, stewards and other defaults
    this.getInitItems();

    this.emitter.on("start--set-disabled", (value) => {
      this.setIsNextDisabled(value);
    });

    this.emitter.on("start--show-btns", (value) => {
      this.showBtns = value;
    });
  },
  computed: {
    hasAllItemsForChecklist() {
      if (this.activeStep === 4) {
        return (
          this.$store.state.flightSettings.shift ||
          this.$store.state.flightSettings.ship ||
          this.$store.state.flightSettings.crew.length
        );
      } else {
        return true;
      }
    },

    hasStartCheckList() {
      if (this.$store.state.flightSettings.shift) {
        return this.$store.state.flightSettings.shift.has_start_checklist;
      }

      return false;
    },

    checkIfChecklistIsCompleted() {
      const oldStore = JSON.parse(localStorage.getItem("oldStore"));
      let toUseChecklist = this.$store.state.checklist;
      let toUseChecklistChecks = this.$store.state.checklistSelectedOptions;
      let toUseChecklistValues = this.$store.state.checklistValues;

      //use old or new checklist
      if (oldStore) {
        if (oldStore.flightSettings.shift && oldStore.flightSettings.ship) {
          if (
            oldStore.flightSettings.shift.id ===
              this.$store.state.flightSettings.shift.id &&
            oldStore.flightSettings.ship.id ===
              this.$store.state.flightSettings.ship.id
          ) {
            //use old items
            toUseChecklist = oldStore.checklist;
            toUseChecklistValues = oldStore.checklistValues;
            toUseChecklistChecks = oldStore.checklistSelectedOptions;
          }
        }
      }

      if (toUseChecklist) {
        if (toUseChecklist.checklist) {
          let hasAllItems = true;

          toUseChecklist.checklist.forEach((section, index) => {
            let isSectionComplete = true;

            section.subcategories.forEach((subCat) => {
              subCat.fields.forEach((field) => {
                const fieldData = field.field ? field.field : field;
                let value = field.value;

                if (!value) {
                  toUseChecklistValues.forEach((checkValue) => {
                    if (checkValue.id === field.id) {
                      value = checkValue.value;
                    }
                  });
                }

                if (
                  !toUseChecklistChecks.includes(field.id) ||
                  (fieldData.unit !== "n.v.t" &&
                    (value === undefined || value === null))
                ) {
                  //is not checked or needs a value and has none
                  hasAllItems = false;
                  isSectionComplete = false;
                }
              });
            });

            //if complete push to completed checklists
            if (isSectionComplete) {
              const currentChecklistCompletedLists = [
                ...this.$store.state.checklistCompletedLists,
              ];
              currentChecklistCompletedLists.push(index);

              this.$store.commit({
                type: "setChecklistCompletedLists",
                payload: currentChecklistCompletedLists,
              });
            }
          });

          if (hasAllItems) {
            this.$store.commit({
              type: "setChecklistIsSend",
              payload: true,
            });
          } else {
            this.$store.commit({
              type: "setChecklistIsSend",
              payload: false,
            });
          }

          return hasAllItems;
        }
      }

      return false;
    },
    needsNewChecklist() {
      /*const shift = this.$store.state.flightSettings.shift;
      const selectedShip = this.$store.state.flightSettings.ship;

      if (shift) {
        return (
          shift.incomplete &&
          shift.last_flight.current_ship_id !== selectedShip.id
        );
      } else {
        return true;
      }*/
    },
  },
  methods: {
    createChecklist() {
      //get checklist from selected ship

      const fullShip = this.$store.state.allShips.filter(
        (ship) => ship.id === this.$store.state.flightSettings.ship.id
      )[0];

      let checklist = null;
      let checklistId = null;

      if (fullShip) {
        if (this.$store.state.isCompleted) {
          //get stop list
          checklist = fullShip.stopChecklist;
          checklistId = fullShip.stopChecklistId;
        } else {
          //get start list
          checklist = fullShip.startChecklist;
          checklistId = fullShip.startChecklistId;
        }
      }

      if (checklist) {
        this.$store.commit({
          type: "setChecklist",
          payload: { checklist: checklist, id: checklistId },
        });

        //set  and values
        const newChecklistValues = [];
        const newChecklistRemarks = [];
        const newChecklistSelectedOptions = [];

        checklist.forEach((cat, index) => {
          let isSectionComplete = true;

          cat.subcategories.forEach((subcat) => {
            subcat.fields.forEach((field) => {
              //checklistValues
              if (field.value) {
                newChecklistValues.push({
                  id: field.id,
                  value: field.value,
                });
              }

              //checklistRemarks
              if (field.remarks) {
                /*newChecklistRemarks.push({
                  id: this.viewData.entry.id,
                  img: this.viewData.img,
                  remark: this.viewData.remark,
                  timeStamp: this.viewData.timeStamp,
                });*/
              }

              //checklistSelectedOptions
              if (field.checked) {
                newChecklistSelectedOptions.push(field.id);
              }

              //checklistCompletedLists
            });
          });

          //if complete push to completed checklists
          if (isSectionComplete) {
            const currentChecklistCompletedLists = [
              ...this.$store.state.checklistCompletedLists,
            ];
            currentChecklistCompletedLists.push(index);

            this.$store.commit({
              type: "setChecklistCompletedLists",
              payload: currentChecklistCompletedLists,
            });

            //and set the checklist as send
          }
        });

        //set all the values
        this.$store.commit({
          type: "setChecklistValues",
          payload: newChecklistValues,
        });

        this.$store.commit({
          type: "setChecklistRemarks",
          payload: newChecklistRemarks,
        });

        this.$store.commit({
          type: "setChecklistSelectedOptions",
          payload: newChecklistSelectedOptions,
        });
      }
    },

    getInitItems() {
      if (localStorage.getItem("token") && this.$store.state.isOnline) {
        Ships.overview(this)
          .then((r) => this.gettingShip(r))
          .catch((r) => this.onFail(r));

        Stewards.overview(this)
          .then((r) => this.gettingCrew(r))
          .catch((r) => this.onFail(r));
      }
    },

    gettingShip(r) {
      this.$store.commit({
        type: "setAllShips",
        payload: r.data.body,
      });
    },
    gettingCrew(r) {
      let crew = r.data.body;

      this.$store.commit({
        type: "setAllCrew",
        payload: crew.sort((a, b) => {
          const aName = a.firstname.toLowerCase();
          const bName = b.firstname.toLowerCase();

          if (aName < bName) {
            return -1;
          }

          if (aName > bName) {
            return 1;
          }

          return 0;
        }),
      });
    },
    onFail(r) {
      let theMessage = {};
      theMessage.status = r.status;
      theMessage.title = r.data.message;
      theMessage.subtitle = "Oeps?!";
      theMessage.body = "";
      theMessage.icon = "whoops";

      this.emitter.emit("toggleOffcanvas", true, "Whoops", theMessage);
    },
    setIsNextDisabled(value) {
      this.isNextDisabled = value;
    },
    handlePrevClick() {
      --this.activeStep;

      this.$router.push(`/start/${this.activeStep}`);

      setTimeout(() => {location.reload();},500);
    },

    handleNextClick() {
      const isIncomplete = this.$store.state.flightSettings.shift
        ? this.$store.state.flightSettings.shift.incomplete
        : false;

      if (parseInt(this.activeStep) === 2 && isIncomplete) {
        //++this.activeStep;
        this.emitter.emit("toggleOffcanvas", [true, "shiftResume"]);
      } else {
        ++this.activeStep;
        this.emitter.emit("start--goToNext", this.activeStep);
        this.$router.push(`/start/${this.activeStep}`);
      }
    },
    handleSubmit() {
      this.setIsNextDisabled(true);
      //send all the data
      this.emitter.emit("app--sendFlightSettings");

      //

      //save store to oldStore
      const oldStore = JSON.parse(localStorage.getItem("oldStore"));

      if (oldStore) {
        const oldSettings = oldStore.flightSettings;
        const currentSettings = this.$store.state.flightSettings;

        if (oldSettings.shift && oldSettings.ship) {
          if (
            oldSettings.shift.id === currentSettings.shift.id &&
            (currentSettings.id === null ||
              oldSettings.id === currentSettings.id)
          ) {
            const oldstoreFiltered = {};
            const keys = [
              "currentStops",
              "currentStopIndex",
              "currentStopsSendInfo",
              "flightSettings",
              "checklist",
              "checklistValues",
              "checklistRemarks",
              "checklistSelectedOptions",
              "checklistCompletedLists",
              "checklistIsSending",
              "checklistIsSend",
              "checklistIsUpdated",
              "checklistInitValues",
              "passengers",
              "newMalfunctionSendInfo",
              "readNotifications",
              "readNotificationSendInfo",
              "isCompleted",
            ];

            keys.forEach((key) => {
              oldstoreFiltered[key] = oldStore[key];
            });
            //the old store is the same as the new one so use it instead

            this.$store.commit({
              type: "setStoreToOldStore",
              payload: {
                ...this.$store.state,
                ...oldstoreFiltered,
                currentStops: [
                  ...oldstoreFiltered.currentStops.map((oldStop) => {
                    let stop = null;

                    this.$store.state.currentStops.forEach((currentStop) => {
                      if (oldStop.departure === currentStop.departure) {
                        //to edit stop
                        stop = currentStop;
                      }
                    });

                    if (stop) {
                      return { ...oldStop, id: stop.id };
                    }
                  }),
                ],
              },
            });

            this.emitter.emit("app--sendAll");
          } else {
            localStorage.setItem("oldStore", JSON.stringify(this.$store.state));
          }
        } else {
          //set oldStore to current
          localStorage.setItem("oldStore", JSON.stringify(this.$store.state));
        }
      } else {
        //set oldStore to current
        localStorage.setItem("oldStore", JSON.stringify(this.$store.state));
      }

      if (this.hasStartCheckList && !this.checkIfChecklistIsCompleted) {
        this.$router.push("/checklist");
      } else {
        this.$router.push("/dashboard");
      }
    },
  },
  watch: {
    $route() {
      if (4 === parseInt(this.$route.params.step)) {
        this.createChecklist();
      }

      this.activeStep = parseInt(this.$route.params.step);
      this.showBtns = true;
    },

    "this.$store.state.checklist"() {},
  },
};
