import axios from "./index";

export default {
  refreshToken(formData) {
    return axios.post("refreshtoken", formData);
  },

  sendPin(formData) {
    return axios.post("sendpin", formData);
  },
};
