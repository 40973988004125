import Shifts from "../../../api/Shifts";
import Flights from "../../../api/Flights";
import { VueAgile } from "vue-agile";
import Checklists from "../../../api/Checklists";

export default {
  components: {
    agile: VueAgile,
  },
  data() {
    return {
      isLoading: false,
      flightInfo: {},
      chosenShift: null,
      slides: [],
    };
  },

  created() {
    //this.emitter.emit("toggleOffcanvas", [true, "shiftResume"]);

    //disable initialy
    if (
      this.$store.state.flightSettings.track &&
      this.$store.state.isOnline &&
      localStorage.getItem("token") &&
      this.$store.state.allShifts.length === 0
    ) {
      this.isLoading = true;
      // get shifts

      Shifts.overview(this.$store.state.flightSettings.track.id)
        .then((r) => this.onSuccess(r))
        .catch((e) => {
          this.onFail(e);
        });
    } else if (!localStorage.getItem("token")) {
      this.$router.push("/");
    }

    if (this.$store.state.flightSettings.shift) {
      this.chosenShift = { ...this.$store.state.flightSettings.shift };
      this.emitter.emit("start--set-disabled", false);

      //move slider to selected
    } else {
      this.emitter.emit("start--set-disabled", true);
    }

    //set slides if present
    if (this.$store.state.allShifts.length) {
      this.slides = this.$store.state.allShifts;

      this.moveToSelected();
    }

    this.emitter.on("start--goToNext", (step) => {
      //if move to next step set data

      if (step === 3) {
        //this.getExistingData();
      }
    });
  },

  mounted() {
    this.moveToSelected();
  },

  methods: {
    checkCompleted(item) {
      const oldStore = JSON.parse(localStorage.getItem("oldStore"));

      if (oldStore) {
        //check if this shift is in storage
        if (oldStore.flightSettings.shift) {
          if (oldStore.flightSettings.shift.id === item.id) {
            //check if stops are send and correct checklists are send
            console.log("stop", item);
            if (
              (item.incomplete ||
                oldStore.currentStops.filter((stop) => stop.passed === 0)
                  .length > 0) &&
              oldStore.checklistIsSend
            ) {
              //items where not send yet
              //send all now
              oldStore.isCompleted = false;
              oldStore.checklistIsSend = false;

              //oldStore.currentStopsSendInfo.succesfullySend = [];

              localStorage.setItem("oldStore", JSON.stringify(oldStore));
            } else {
              //wipe old store
              if (
                (oldStore.isComplete ||
                  (oldStore.currentStops.filter(
                    (stop) => stop.passed === 1 || stop.skipped === 1
                  ).length >= oldStore.currentStops.length &&
                    oldStore.currentStops.length > 0)) &&
                oldStore.checklistIsSend
              ) {
                localStorage.removeItem("oldStore");
              }

              return (
                item.incomplete ||
                (!(
                  oldStore.isComplete ||
                  (oldStore.currentStops.filter(
                    (stop) => stop.passed === 1 || stop.skipped === 1
                  ).length >= oldStore.currentStops.length &&
                    oldStore.currentStops.length > 0)
                ) &&
                  !oldStore.checklistIsSend)
              );
            }

            return (
              item.incomplete ||
              (!(
                oldStore.isComplete ||
                (oldStore.currentStops.filter((stop) =>
                  stop ? stop.passed === 1 || stop.skipped === 1 : false
                ).length >= oldStore.currentStops.length &&
                  oldStore.currentStops.length > 0)
              ) &&
                !oldStore.checklistIsSend)
            );
          }
        }
      }

      return item.incomplete;
    },
    moveToSelected() {
      if (this.chosenShift && this.$refs.carousel && this.slides) {
        this.slides.forEach((slide, index) => {
          if (slide.id === this.chosenShift.id) {
            //is current slide index
            this.$refs.carousel.goTo(index);
          }
        });
      }
    },
    onSuccess(r) {
      let shifts = r.data.body;

      shifts = shifts.map((shift) => {
        const hasNumber = /\d/;
        if (hasNumber.test(shift.name)) {
          shift.number = shift.name.match(/\d+/g)[0];
          shift.label = shift.name
            .replace(/[0-9]/g, "")
            .replace(/ +(?= )/g, "");
        }

        return shift;
      });

      this.$store.commit({
        type: "setAllShifts",
        payload: shifts,
      });

      this.slides = shifts;

      this.moveToSelected();

      this.isLoading = false;

      this.getIncompleteFlights(shifts);
    },

    getIncompleteFlights(shifts) {
      if (this.$store.state.incompleteFlights) {
        if (this.$store.state.incompleteFlights.length === 0) {
          const flights = [];
          const promises = [];

          shifts.forEach((shift) => {
            const id = shift.last_flight.id;

            promises.push(
              Flights.detail(id).then((r) => {
                flights.push(r.data.body);

                this.$store.commit({
                  type: "setIncompleteFlights",
                  payload: flights,
                });
              })
            );
          });

          Promise.all(promises).then((r) => {
            this.$store.commit({
              type: "setIncompleteFlights",
              payload: flights,
            });
          });
        }
      }
    },

    onFail(r) {
      this.isLoading = false;

      let theMessage = {};
      theMessage.status = r.status;
      theMessage.title = r.data ? r.data.message : r.message;
      theMessage.subtitle = "Oeps?!";
      theMessage.body = "";
      theMessage.icon = "whoops";
      console.log("error", r.data);
      //this.emitter.emit("toggleOffcanvas", [true, "Whoops", theMessage]);
    },

    handleChange(e, item) {
      if (!e.target.checked) {
        // e.target.checked = false;

        this.chosenShift = null;

        //exist allow next step
        this.emitter.emit("start--set-disabled", true);
      } else {
        // e.target.checked = true;

        this.chosenShift = { ...item, incomplete: this.checkCompleted(item) };

        //check if shift is realy incomplete

        if (this.chosenShift.isCompleted) {
          const flight = this.chosenShift.last_flight;

          this.$store.commit({
            type: "setFlightSettings",
            payload: {
              id: flight.id,
              ship: flight.current_ship,
              crew: [],
              isSending: false,
              isSend: false,
            },
          });

          this.$store.commit({
            type: "setChecklistIsSend",
            payload: false,
          });

          this.$store.commit({
            type: "setChecklistIsUpdated",
            payload: false,
          });

          const passengers = this.$store.state.passengers;

          this.$store.commit({
            type: "setPassengers",
            payload: {
              current_people: passengers.current_people
                ? passengers.current_people
                : flight.current_people,
              current_bikes: passengers.current_bikes
                ? passengers.current_bikes
                : flight.current_bikes,
            },
          });
        }

        //exist allow next step
        this.emitter.emit("start--set-disabled", false);
      }
    },

    setDefaultsCurrentStops() {
      if (this.chosenShift) {
        this.$store.commit({
          type: "setCurrentStops",
          payload: this.chosenShift.items.map((item) => {
            return {
              arrived: null,
              bikes: 0,
              bikes_off: 0,
              bikes_on: 0,
              created_at: "",
              departured: null,
              flight_id: null,
              inserted: 0,
              is_manualy_added: false,
              order: 0,
              passed: 0,
              people: 0,
              people_off: 0,
              people_on: 0,
              remarks: 0,
              skipped: 0,
              updated_at: "",
              why_skipped: null,
              id: null,
              stop: {
                id: item.stop_id,
                name: item.stop_name,
              },
              ...item,
            };
          }),
        });

        if (this.chosenShift.last_flight) {
          const ship = this.chosenShift.last_flight.current_ship;
          const currentShip = this.$store.state.flightSettings.ship;

          if (currentShip) {
            if (currentShip.id === ship.id) {
              this.$store.commit({
                type: "setPassengers",
                payload: {
                  current_people: currentShip.current_people
                    ? currentShip.current_people
                    : 0,
                  current_bikes: currentShip.current_bikes
                    ? currentShip.current_bikes
                    : 0,
                },
              });
            }
          }
        }
      }
    },
  },
  watch: {
    chosenShift() {
      this.$store.commit({
        type: "setFlightSettings",
        payload: { shift: { ...this.chosenShift } },
      });

      //only on clicking next
      //this.getExistingData();
      //set currentStops
      this.setDefaultsCurrentStops();
    },
  },
};
