<script>
import "./Crew.scss";
import Crew from "./Crew.js";

export default Crew;
</script>

<template>
  <transition name="fade" mode="out-in">
    <Step-wrapper
      title="Selecteer stewards"
      :isLoading="isLoading"
      v-if="!showCrewForm"
      :noPadding="true"
    >
      <div class="crew">
        <div v-if="slider.crewMembers.length" class="crew__slider__outer">
          <div class="crew__slider__info">
            <span class="crew__slider__info__label">{{
              slider.crewMembers.length > 1
                ? slider.crewMembers.length + " resultaten"
                : slider.crewMembers.length + " resultaat"
            }}</span>
          </div>

          <div class="crew__slider__actions">
            <div class="crew__slider__actions__item">
              <button
                @click="toggleCrewForm(true)"
                class="button button--small button--pairing-brand-1"
              >
                <span class="button__symbol">
                  <Svg-icon iconType="Group-add" />
                </span>
                <span class="button__label">Toevoegen</span>
              </button>
            </div>

            <div class="crew__slider__actions__item">
              <label class="input__select crew__slider__actions__select">
                <select v-model="selectedFilter">
                  <option default>A-Z</option>
                  <option
                    v-for="letter in crewFilters"
                    :value="letter"
                    :key="letter"
                  >
                    {{ letter }}
                  </option>
                </select>

                <span
                  class="input__select__symbol crew__slider__actions__select__symbol"
                >
                  <Svg-icon iconType="Triangle" />
                </span>
              </label>
            </div>
          </div>
        </div>

        <div class="crew__slider">
          <div v-if="slider.crewMembers.length > 9" class="crew__slider__btns">
            <div class="crew__slider__btns__prev">
              <button
                @click="handleSliderClick('prev')"
                class="directionalButton"
                :class="!slider.goToPrevSlide && 'is-disabled'"
              >
                <span class="directionalButton__symbol">
                  <Svg-icon iconType="Arrow-left" />
                </span>
              </button>
            </div>

            <div class="crew__slider__btns__next">
              <button
                @click="handleSliderClick('next')"
                class="directionalButton"
                :class="!slider.goToNextSlide && 'is-disabled'"
              >
                <span class="directionalButton__symbol">
                  <Svg-icon iconType="Arrow-right"></Svg-icon>
                </span>
              </button>
            </div>
          </div>

          <div class="crew__slider__wrap">
            <div class="crew__sliderTrack" ref="crew__sliderTrack">
              <div
                v-for="(n, index) in Math.ceil(slider.crewMembers.length / 9)"
                class="crew__slide"
                ref="crew__slide"
                v-touch:swipe="handleSwipe()"
                :key="`${n}${index}`"
              >
                <div
                  v-for="item in slider.crewMembers.slice((n - 1) * 9, n * 9)"
                  class="crew__slide__item"
                  :key="item.id"
                >
                  <div class="crew__slide__item__inner">
                    <input
                      @change="() => handleChange(item)"
                      :value="item.id"
                      :id="item.id"
                      class="crew__radio"
                      type="checkbox"
                      :checked="checkIfChecked(item)"
                    />

                    <label :for="item.id" class="crew__radioFaker">
                      <div class="crew__checked__symbol">
                        <span class="crew__checked__theSymbol">
                          <Svg-icon iconType="check" iconColor="#fff" />
                        </span>
                      </div>

                      <div class="crew__slide__mugshot">
                        <div class="crew__slide__mugshot__sizer">
                          <div class="crew__slide__mugshot__inner">
                            <img
                              v-if="item.picture"
                              class="crew__slide__mugshot__visual"
                              :src="stewardPic(item.picture)"
                            />
                            <span v-else class="crew__slide__mugshot__symbol">
                              <Svg-icon iconType="Person-flat" />
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="crew__slide__name">
                        <span class="crew__slide__firstName">{{
                          item.firstname
                        }}</span>
                        <span class="crew__slide__lastName">{{
                          item.lastname
                        }}</span>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Step-wrapper>
    <Crew-form v-else :isLoading="isLoading" />
  </transition>
</template>
