<template>
	<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 13.2 17.4" xml:space="preserve">
		<g>
			<path d="M2.4,0C1.1,0,0,1.1,0,2.4s1.1,2.4,2.4,2.4s2.4-1.1,2.4-2.4S3.7,0,2.4,0z M2.4,3.8c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5 c0.8,0,1.5,0.7,1.5,1.5S3.2,3.8,2.4,3.8z"/>
			<path d="M6.6,1.9h6.3c0.2,0,0.4-0.2,0.4-0.4S13,1,12.8,1H6.6C6.4,1,6.2,1.2,6.2,1.5S6.4,1.9,6.6,1.9z"/>
			<path d="M6.6,3.7h3c0.3,0,0.4-0.2,0.4-0.4S9.8,2.8,9.6,2.8h-3C6.4,2.8,6.2,3,6.2,3.2S6.4,3.7,6.6,3.7L6.6,3.7z"/>
			<path d="M2.4,17.4c1.3,0,2.4-1.1,2.4-2.4s-1.1-2.4-2.4-2.4S0,13.8,0,15.1S1.1,17.4,2.4,17.4z"/>
			<path d="M12.8,7.4H6.6c-0.2,0-0.4,0.2-0.4,0.4s0.2,0.4,0.4,0.4h6.3c0.2,0,0.4-0.2,0.4-0.4S13,7.4,12.8,7.4z"/>
			<path d="M6.6,10h3C9.8,10,10,9.8,10,9.6S9.8,9.2,9.6,9.2h-3c-0.3,0-0.4,0.2-0.4,0.4S6.4,10,6.6,10z"/>
			<path d="M6.6,14.6h6.3c0.2,0,0.4-0.2,0.4-0.4s-0.2-0.4-0.4-0.4H6.6c-0.2,0-0.4,0.2-0.4,0.4S6.4,14.6,6.6,14.6z"/>
			<path d="M6.6,16.4h3c0.3,0,0.4-0.2,0.4-0.4s-0.2-0.4-0.4-0.4h-3c-0.3,0-0.4,0.2-0.4,0.4S6.4,16.4,6.6,16.4z"/>
			<path d="M2.4,11.1c1.3,0,2.4-1.1,2.4-2.4S3.7,6.3,2.4,6.3S0,7.4,0,8.7S1.1,11.1,2.4,11.1z"/>
		</g>
	</svg>
</template>