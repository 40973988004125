<script>
import "./Date.scss";
import Date from "./Date.js";

export default Date;
</script>

<template>
  <span class="date">{{ date }}</span>
</template>
