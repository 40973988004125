export default {
  props: {
    colorMode: {
      type: String,
      default: "light",
    },
    hasText: {
      type: Boolean,
      default: true,
    },
  },
};
