<script>
import "./Loader.scss";
import Loader from "./Loader.js";

export default Loader;
</script>

<template>
  <div class="loader">
    <div :class="['loader', `colorMode-is-${colorMode}`]">
      <div class="loader__icon">
        <svg class="loader__icon__circle" viewBox="25 25 50 50">
          <circle
            class="loader__icon__path"
            cx="51"
            cy="51"
            r="18"
            fill="none"
            stroke-width="12"
          />
        </svg>
      </div>

      <div class="loader__label" v-if="hasText">
        <span class="loader__theLabel">Laden</span>
      </div>
    </div>
  </div>
</template>
