import Auth from "../../../api/Auth";

export default {
  data() {
    return {
      debug: false,
      isValid: true,
      isLoading: false,
      isSuccess: false,
      inputs: {
        email: null,
      },
    };
  },

  methods: {
    handleSubmit(e) {
      e.preventDefault();

      if (this.validateIt()) {
        this.isLoading = true;
        this.sendIt();
      }
    },

    handleKeyevent(e) {
      e.target.parentNode.classList.remove("is-invalid");
    },

    validateIt() {
      this.isValid = true;

      this.inputs.email == "" &&
        (this.$refs.email.classList.add("is-invalid"), (this.isValid = false));
      !this.validateEmail(this.inputs.email) &&
        (this.$refs.email.classList.add("is-invalid"), (this.isValid = false));

      return this.isValid;
    },

    sendIt() {
      let formData = new FormData();

      formData.append("email", this.inputs.email);

      Auth.sendPin(formData)
        .then((response) => {
          this.onSuccess(response);
        })
        .catch((e) => {
          this.onFail(e);
        });
    },

    onSuccess() {
      this.isLoading = false;
      this.isSuccess = true;

      let theMessage = {};
      theMessage.title = "Bekijk je inbox";
      theMessage.subtitle = "Succesvol verstuurd";
      theMessage.body = "";
      theMessage.icon = "pinSend";
      theMessage.button = "Terug naar login";

      this.emitter.emit("toggleOffcanvas", [true, "Success", theMessage]);
    },

    onFail(error) {
      this.isLoading = false;

      let theMessage = {};
      theMessage.status = error.status;
      theMessage.title = error.message;
      theMessage.subtitle = "Oeps?!";
      theMessage.body = "";
      theMessage.icon = "whoops";

      this.emitter.emit("toggleOffcanvas", [true, "Whoops", theMessage]);
    },

    validateEmail(theValue) {
      return /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
        theValue
      );
    },
  },
};
