<script>
import "./PassengerCounter.scss";
import PassengerCounter from "./PassengerCounter.js";

export default PassengerCounter;
</script>

<template>
  <div class="passenger-counter">
    <h3 class="heading heading--4">{{ title }}</h3>
    <div class="passenger-counter__content">
      <Counter
        :id="title"
        type="passengers"
        icon="passengers"
        :min="minPassengers"
        :current="currentPassengers"
        :max="maxPassengers"
      />
      <Counter
        :id="title"
        type="bikes"
        icon="bike"
        :min="minBikes"
        :current="currentBikes"
        :max="maxBikes"
      />
    </div>
  </div>
</template>
