export default {
  props: {
    viewData: Object,
  },

  data() {
    return {
      theNextStop: this.viewData.nextStop,
      inputFields: {
        reason: "",
        remarks: "",
      },
      isInvalid: true,
      isSuccess: false,
      theMessage: {},
    };
  },

  watch: {
    "inputFields.reason": {
      handler(newVal, oldVal) {
        if (newVal == oldVal) return false;

        this.isInvalid = false;

        this.$refs.reason.classList.remove("is-invalid");
      },
    },
  },

  methods: {
    handleSubmit() {
      if (this.validateIt()) {
        this.sendIt();
      }
    },

    handleKeyevent(e) {
      this.isInvalid = false;

      e.target.parentNode.classList.remove("is-invalid");
    },

    sendIt() {
      const newCurrentStops = [...this.$store.state.currentStops].map(
        (stop) => {
          if (stop.id === this.theNextStop.id) {
            return {
              ...stop,
              skipped: 1,
              why_skipped: this.inputFields.reason,
              remarks: this.inputFields.remarks,
            };
          }

          return stop;
        }
      );

      this.$store.commit({
        type: "setCurrentStops",
        payload: newCurrentStops,
      });

      //set active index

      let newStopIndex = -1;
      newCurrentStops.forEach((stop, index) => {
        if (newStopIndex === -1 && !stop.skipped && !stop.passed) {
          newStopIndex = index;
        }
      });

      //preform second check
      //check if last item is passed then thats the index
      if (newStopIndex === -1) {
        //is last item
        newStopIndex = newCurrentStops.length;
        /*const reversed = [...newCurrentStops];

        reversed.reverse().forEach((stop, index) => {
          if (newStopIndex === -1 && !stop.skipped) {
            newStopIndex = newCurrentStops.length - 1 - 1 - index;
          }
        });*/
      }

      this.$store.commit({
        type: "setCurrentStopIndex",
        payload: newStopIndex,
      });

      this.emitter.emit("toggleOffcanvas", false);

      // this.isLoading = true;
      // create formdata object
      /*let formData = new FormData();

      formData.append("item", this.theNextStop.id);
      formData.append("flight", this.theNextStop.flight_id);
      formData.append("why_skipped", this.inputFields.reason);
      formData.append("remarks", this.inputFields.remarks);

     Items.skip(formData).then((response) =>
        response.status !== 200
          ? this.onFail(response)
          : this.onSuccess(response)
      );*/
    },
    validateIt() {
      this.isInvalid = false;

      this.inputFields.reason == "" &&
        ((this.isInvalid = true),
        this.$refs.reason.classList.add("is-invalid"));

      if (this.isInvalid) return false;

      return true;
    },
  },
};
