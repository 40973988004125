<script>
import "./UploadingData.scss";
import UploadingData from "./UploadingData.js";

export default UploadingData;
</script>

<template>
  <div class="uploading-data">
    <div class="uploading-data__content">
      <h2 class="heading heading--3 page-wrapper__title">Dienst versturen</h2>
      <ul class="uploading-data__processes">
        <template v-for="uploadPoint in uploadPoints" :key="uploadPoint.label">
          <li>
            <span class="uploading-data__icon">
              <Svg-icon
                iconType="Check"
                v-if="isItemLoading(uploadPoint.statusName)"
              ></Svg-icon>
              <Loader v-else :hasText="false" />
            </span>
            <p class="heading--4">{{ uploadPoint.label }}</p>
          </li>
        </template>
      </ul>
      <button
        @click="handleLogout"
        class="button--withpadding button button--positive button--arrival"
        :disabled="!isAllSend"
      >
        <span class="button__symbol">
          <Svg-icon iconType="Logout-icon"></Svg-icon>
        </span>
        <span class="button__label">Uitloggen</span>
      </button>
    </div>
  </div>
</template>
