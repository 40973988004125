<script>
import "./Flight.scss";
import Flight from "./Flight.js";

export default Flight;
</script>

<template>
  <Page-wrapper title="HUIDIGE VAART" :colored="true">
    <section class="flightPanel">
      <div class="flightPanel__stops">
        <div class="flightPanel__stops__bg">
          <Svg-icon iconType="Anchor-circle"></Svg-icon>
        </div>
        <transition key="parent" name="fade" mode="out-in">
          <div v-if="hasStops" class="flightPanel__stops__inner">
            <div class="flightPanel__actions">
              <div class="flightPanel__actions__item">
                <button
                  @click="handleNotificationsClick"
                  v-bind:class="{ hideBell: !newNotification.length }"
                  class="specialButton button button--pairing-brand-2 flightPanel__actions__item__button"
                >
                  <span
                    class="specialButton__symbol flightPanel__actions__item__symbol"
                  >
                    <Svg-icon iconType="Notifications-flat"></Svg-icon>
                  </span>
                  <span
                    v-if="newNotification.length"
                    :class="{
                      flightPanel__actions__alert: newNotification.length,
                    }"
                    >{{ newNotification.length }}</span
                  >
                </button>
              </div>
            </div>

            <!-- FLIGHTNEXT & FLIGHTCURRENT GETS RENDERED HERE -->
            <Flight-stop />

            <div class="flightPanel__boardingData">
              <div class="flightPanel__boardingData__label-wrapper">
                <h3>{{ passagiersTitle }}</h3>
                <div
                  @click="handlePassengersClick"
                  class="button button--pairing-theme-1"
                >
                  <div :class="['flightPanel__boardingData__item']">
                    <span class="flightPanel__boardingData__item__symbol">
                      <Svg-icon iconType="Passengers-flat"></Svg-icon>
                    </span>

                    <span class="flightPanel__boardingData__item__label">
                      <strong
                        :class="{
                          'is-invalid':
                            currentPeople > $store.state.passengers.max_people,
                        }"
                        >{{ currentPeople }}</strong
                      >
                      / {{ $store.state.passengers.max_people }}</span
                    >
                  </div>

                  <div :class="['flightPanel__boardingData__item']">
                    <span class="flightPanel__boardingData__item__symbol">
                      <Svg-icon iconType="Bike-flat"></Svg-icon>
                    </span>

                    <span class="flightPanel__boardingData__item__label">
                      <strong
                        :class="{
                          'is-invalid':
                            currentBikes > $store.state.passengers.max_bikes,
                        }"
                        >{{ currentBikes }}</strong
                      >
                      / {{ $store.state.passengers.max_bikes }}</span
                    >
                  </div>
                </div>
              </div>
              <div
                v-if="theSecondStop"
                class="flightCurrent__actions__item flightCurrent__actions__item_extra"
              >
                <button
                  @click="handleShiftClick"
                  class="button button--pairing-theme-1"
                >
                  <span class="button__label">Extra dienst varen</span>
                </button>
              </div>

              <div class="flightPanel__actions__item">
                <router-link
                  to="/flight/edit"
                  class="specialButton flightPanel__actions__item__button"
                >
                  <span
                    class="specialButton__symbol flightPanel__actions__item__symbol"
                  >
                    <Svg-icon iconType="Settings-flat"></Svg-icon>
                  </span>
                </router-link>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </section>
  </Page-wrapper>
</template>
