<template>
	<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 198.8 117.1" xml:space="preserve">
		<path fill="#BC0D26" d="M197.7,0H1.1C0.6,0,0.1,0.4,0,1v115.1c0.1,0.5,0.5,0.9,1,1h196.8c0.6-0.1,1-0.6,1-1.2c0-0.3-0.1-0.6-0.3-0.8
			l-57.4-56.4L198.5,2c0.5-0.5,0.5-1.2,0-1.6C198.3,0.1,198,0,197.7,0"/>
		<g>
			<path fill="#FFFFFE" d="M14.5,40.9c-0.7,0-1-0.4-1-1.2V15.4c0-0.8,0.3-1.2,1.1-1.2h8.9c7.4,0,10.1,3.3,10.1,6.7v13.3
				c0,3.4-2.7,6.7-10.1,6.7H14.5z M20,19.6v16.1h3.5c1.9,0,3.6-0.7,3.6-2.5v-11c0-1.8-1.7-2.5-3.6-2.5H20L20,19.6z"/>
			<path fill="#FFFFFE" d="M38.9,40.9c-0.8,0-1.1-0.4-1.1-1.2V15.4c0-0.8,0.3-1.2,1.1-1.2h13.5c0.8,0,1.2,0.3,1.2,1.1v3.1
				c0,0.8-0.4,1.1-1.2,1.1h-8.1v5.1h7.5c0.8,0,1.2,0.3,1.2,1.1v2.9c0,0.8-0.4,1.1-1.2,1.1h-7.5v5.9h8.5c0.8,0,1.2,0.3,1.2,1.1v3.1
				c0,0.8-0.4,1.1-1.2,1.1H38.9"/>
			<path fill="#FFFFFE" d="M34.3,57.9c0.4,1.4,0.6,3.8,0.7,4.2c0-0.3,0.3-2.5,0.7-4.2l2.8-11c0.2-0.7,0.6-1.2,1.4-1.2h3.7
				c0.8,0,1.1,0.4,0.9,1.2l-6.1,24.3c-0.2,0.8-0.6,1.2-1.5,1.2h-4.1c-0.7,0-1.1-0.4-1.3-1.2L28.6,60c-0.3-1.1-0.5-3.5-0.6-4
				c0,0.4-0.4,2.9-0.6,4l-2.8,11.3c-0.2,0.8-0.6,1.2-1.5,1.2H19c-0.7,0-1.1-0.4-1.3-1.2L11.5,47c-0.2-0.7,0-1.2,0.9-1.2h4.3
				c0.8,0,1.2,0.4,1.4,1.2l2.7,11c0.3,1.2,0.6,3.1,0.6,3.5c0-0.3,0.3-1.9,0.7-3.5l2.8-11c0.2-0.7,0.6-1.2,1.4-1.2h4
				c0.8,0,1.2,0.4,1.4,1.2L34.3,57.9"/>
			<path fill="#FFFFFE" d="M57.8,47l7.1,24.3c0.2,0.7,0,1.2-0.9,1.2h-4.4c-0.8,0-1.2-0.4-1.4-1.2l-1-3.8h-6.5l-1,3.8
				c-0.2,0.7-0.6,1.2-1.4,1.2h-4c-0.8,0-1.1-0.4-0.9-1.2L50.6,47c0.2-0.8,0.6-1.2,1.5-1.2h4.3C57.1,45.8,57.5,46.2,57.8,47 M55.3,60.3
				c-0.6-2.4-1.2-7.8-1.2-8.2c-0.1,0.5-0.7,5.9-1.3,8.2l-0.6,2.1h3.7L55.3,60.3"/>
			<path fill="#FFFFFE" d="M81,45.8c0.8,0,1.2,0.3,1.2,1.1V50c0,0.8-0.4,1.1-1.2,1.1h-5v20.2c0,0.8-0.3,1.2-1.1,1.2h-4.3
				c-0.8,0-1.1-0.4-1.1-1.2V51.1h-5c-0.8,0-1.2-0.3-1.2-1.1v-3.1c0-0.8,0.4-1.1,1.2-1.1H81"/>
			<path fill="#FFFFFE" d="M85.8,72.5c-0.8,0-1.1-0.4-1.1-1.2V47c0-0.8,0.3-1.2,1.1-1.2h13.5c0.8,0,1.2,0.3,1.2,1.1V50
				c0,0.8-0.4,1.1-1.2,1.1h-8.1v5.1h7.5c0.8,0,1.2,0.3,1.2,1.1v2.9c0,0.8-0.4,1.1-1.2,1.1h-7.5v5.9h8.5c0.8,0,1.2,0.3,1.2,1.1v3.1
				c0,0.8-0.4,1.1-1.2,1.1H85.8"/>
			<path fill="#FFFFFE"  d="M119.9,61.6l3.9,9.6c0.3,0.7-0.2,1.2-1.1,1.2h-4.3c-0.7,0-0.9-0.6-1.1-1.2l-3.5-8.7h-3v8.7
				c0,0.8-0.3,1.2-1.1,1.2h-4.3c-0.8,0-1.1-0.4-1.1-1.2V47c0-0.8,0.3-1.2,1.1-1.2h8.5c7.4,0,10,3.3,10,6.7v3.4
				C124,58.1,122.8,60.4,119.9,61.6 M110.8,51.1v6.2h3.2c1.9,0,3.6-0.7,3.6-2.5v-1.1c0-1.8-1.7-2.5-3.6-2.5H110.8"/>
			<path fill="#FFFFFE"  d="M14.6,104c-0.8,0-1.1-0.4-1.1-1.2V78.5c0-0.8,0.3-1.2,1.1-1.2h7.4c7.4,0,10,2.7,10,6.1v2.2
				c0,1.9-0.9,3.3-2.7,4.4c2.4,1,4,2.6,4,4.7v2.9c0,3.4-2.6,6.4-10,6.4H14.6z M20,82.4v5.6h2c1.9,0,3.6-0.7,3.6-2.6v-1
				c0-1.8-1.7-2-3.6-2H20z M20,92.9v6h3.3c1.9,0,3.6-0.7,3.6-2.5v-1.3c0-1.5-1.7-2.3-3.6-2.3H20L20,92.9z"/>
			<path fill="#FFFFFE"  d="M43.2,96.5c0,1.8,1.6,2.5,3.5,2.5c1.9,0,3.6-0.7,3.6-2.5v-18c0-0.8,0.3-1.2,1.1-1.2h4.3
				c0.8,0,1.1,0.4,1.1,1.2v19.1c0,3.4-2.7,6.7-10.1,6.7c-7.4,0-10-3.3-10-6.7V78.5c0-0.8,0.3-1.2,1.1-1.2h4.3c0.8,0,1.1,0.4,1.1,1.2
				V96.5"/>
			<path fill="#FFFFFE"  d="M73.4,84.6c0-1.8-1.3-2.5-3.1-2.5c-1.8,0-3.1,0.7-3.1,2.5V85c0,1.1,0.8,1.5,1.3,1.7l6.6,2.2
				c2.9,1,4.9,3,4.9,5.9v2.8c0,3.4-2.4,6.7-9.8,6.7c-7.4,0-9.9-3.3-9.9-6.7V95c0-0.8,0.3-1.2,1.1-1.2h4.1c0.8,0,1.1,0.4,1.1,1.2v1.8
				c0,1.8,1.5,2.5,3.5,2.5c1.9,0,3.5-0.7,3.5-2.5v-0.8c0-1.4-1.9-2.1-3.4-2.6l-5.7-2.1c-2-0.7-3.7-2.9-3.7-5.2v-2.3
				c0-3.4,2-6.7,9.3-6.7c7.3,0,9.5,3.3,9.5,6.7v1.4c0,0.8-0.3,1.2-1.1,1.2h-4.1c-0.8,0-1.1-0.4-1.1-1.2V84.6"/>
		</g>
	</svg>
</template>