<script>
import "./Malfunctions.scss";
import Malfunctions from "./Malfunctions.js";

export default Malfunctions;
</script>

<template>
  <Page-wrapper title="storingen">
    <section class="malfunctionsOverview">
      <div class="malfunctionsOverview__inner">
        <table>
          <thead>
            <th width="24%">Datum &amp; tijd</th>
            <th width="25%">Situatie</th>
            <th width="99%">Beschrijving</th>
            <th></th>
          </thead>
        </table>
        <div class="body-table">
          <table>
            <transition name="fade" mode="in-out">
              <tbody>
                <tr
                  v-for="malfunction in $store.state.malfunctions"
                  @click="handleDetailClick(malfunction)"
                  :key="`${formatDateOfMalfunction(
                    malfunction
                  )}${formatTimeOfMalfunction(malfunction)}`"
                >
                  <td width="24%">
                    {{ formatDateOfMalfunction(malfunction) }}
                    <span>&bullet;</span>
                    {{ formatTimeOfMalfunction(malfunction) }}
                  </td>
                  <td width="25%">{{ malfunction.category }}</td>
                  <td width="99%">{{ malfunction.remarks }}</td>
                  <td>
                    <button
                      class="button button--theme-1 flightEditPanel__actions__button"
                    >
                      <span
                        class="button__symbol flightEditPanel__actions__button__symbol"
                      >
                        <Svg-icon iconType="View-icon"></Svg-icon>
                      </span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </transition>
          </table>
        </div>

        <div class="malfunctions__actions">
          <router-link
            to="create-malfunction"
            class="button button--pairing-brand-2 malfunctions__actions__button"
          >
            <span class="button__label">
              <span class="button__symbol malfunctions__actions__symbol">
                <Svg-icon iconType="Megaphone-flat"></Svg-icon>
              </span>
              Storing versturen
            </span>
            <span class="button__symbol">
              <Svg-icon iconType="Arrow-right"></Svg-icon>
            </span>
          </router-link>
        </div>

        <span v-if="this.fishe && this.fishe != ''">
          <a
            :href="this.fishe"
            download
            style="position: absolute; right: 10px; bottom: 10px"
            >Download technische fiche</a
          >
        </span>
      </div>
    </section>
  </Page-wrapper>
</template>
