<script>
import "./StepTracker.scss";
import StepTracker from "./StepTracker.js";

export default StepTracker;
</script>

<template>
  <div class="tracker">
    <ul class="tracker__list">
      <li
        v-for="(step, index) in steps"
        class="tracker__list__item"
        :class="activeStep >= index + 1 && 'is-active'"
        :key="step"
        @click="() => handleClickStep(index + 1)"
      >
        <div class="tracker__item__inner">
          <div class="tracker__item__box">
            <span class="tracker__item__box__label">{{ index + 1 }}</span>
          </div>

          <span class="tracker__item__label">{{ step }}</span>
        </div>
      </li>
    </ul>
  </div>
</template>
