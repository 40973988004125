export default {
  props: {
    steps: {
      type: Array,
      default: () => [],
    },
    activeStep: Number,
  },
  methods: {
    handleClickStep(step) {
      this.$router.push(`/start/${step}`);
    },
  },
};
