export default {
  data() {
    return {
      show: false,
      viewToShow: "",
      viewData: [],
    };
  },
  created() {
    this.emitter.on("toggleOffcanvas", (data) => {
      if (Array.isArray(data)) {
        this.show = data[0];
        this.viewToShow = data[1];
        this.viewData = data[2];
      } else {
        this.show = false;
        this.viewToShow = "";
        this.viewData = [];
      }
    });
  },
  methods: {
    handleClick() {
      this.emitter.emit("toggleOffcanvas", false);

      this.viewData &&
        this.viewData.title ==
          "Captain with token not found (Try to login again?)" &&
        (localStorage.removeItem("store"),
        localStorage.removeItem("token"),
        localStorage.removeItem("concession"),
        this.$router.push("/"));
      this.viewData && this.viewData.status == "404" && this.$router.push("/");
    },
  },
};
