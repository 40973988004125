<template>
	<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 23.1 22.6" xml:space="preserve">
		<g>
			<path d="M3.4,10.7c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9h2.9c0.5,0,0.9-0.4,0.9-0.9 c0-0.5-0.4-0.9-0.9-0.9H3.4z"/>
			<path d="M19.8,13.4h-5.2l-0.2,0.5c-0.2,0.4-0.4,0.7-0.7,1c-0.8,0.8-1.8,1.2-2.9,1.2c-1,0-1.9-0.4-2.6-1 h4.1c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9H0.9c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9h5.1c0.2,0.4,0.5,0.7,0.8,1 c1.1,1.1,2.5,1.8,4.1,1.8c1.5,0,3-0.6,4.1-1.8c0.3-0.3,0.5-0.6,0.8-1h4.2c0.5,0,0.9-0.4,0.9-0.9C20.6,13.8,20.3,13.4,19.8,13.4z"/>
			<path d="M11.6,2.2V0.9c0-0.5-0.4-0.9-0.9-0.9S9.9,0.4,9.9,0.9v1.3v1.5h1.7V2.2z"/>
			<path d="M9.9,20.4v1.3c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9v-1.3v-1.5H9.9V20.4z"/>
			<path d="M17.5,5.5l0.9-0.9c0.3-0.4,0.3-0.9,0-1.2C18,3,17.5,3,17.2,3.3l-0.9,0.9l-1,1l1.2,1.2L17.5,5.5z"/>
			<path d="M4,17.1L3.1,18c-0.3,0.4-0.3,0.9,0,1.2c0.2,0.2,0.4,0.3,0.6,0.3s0.4-0.1,0.6-0.3l0.9-0.9l1-1 L5,16L4,17.1z"/>
			<path d="M4,5.5l1,1l1.2-1.2l-1-1.1L4.3,3.3C4,2.9,3.5,2.9,3.1,3.3c-0.3,0.4-0.3,0.9,0,1.2L4,5.5z"/>
			<path d="M17.5,17.1l-1-1l-1.2,1.2l1,1l0.9,0.9c0.2,0.2,0.4,0.3,0.6,0.3s0.4-0.1,0.6-0.3 c0.3-0.4,0.3-0.9,0-1.2L17.5,17.1z"/>
			<path d="M2.2,9.9h7.2c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9H7.5C7.6,8,7.7,7.8,7.9,7.7 c0.8-0.8,1.8-1.2,2.9-1.2s2.1,0.4,2.9,1.2C13.7,7.8,13.8,8,14,8.1h-0.7c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9h4.8 C18.6,9.9,19,9.5,19,9c0-0.5-0.4-0.9-0.9-0.9h-2.2c0-0.1,0-0.1-0.1-0.2c-0.3-0.6-0.6-1.1-1.1-1.5c-1.1-1.1-2.5-1.8-4.1-1.8 c-1.5,0-3,0.6-4.1,1.8c-0.4,0.5-0.8,0.9-1,1.5c0,0.1,0,0.1-0.1,0.2H2.2C1.7,8.1,1.3,8.5,1.3,9C1.3,9.5,1.7,9.9,2.2,9.9z"/>
			<path d="M22.2,10.7H8.6c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9h13.6c0.5,0,0.9-0.4,0.9-0.9 C23.1,11.1,22.7,10.7,22.2,10.7z"/>
		</g>
	</svg>
</template>