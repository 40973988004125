<script>
import "./MalfunctionTracker.scss";
import MalfunctionTracker from "./MalfunctionTracker.js";

export default MalfunctionTracker;
</script>

<template>
  <div class="tracker">
    <ul class="tracker__list">
      <li
        v-for="(step, index) in theSteps"
        class="tracker__list__item"
        :class="{
          'is-active': isActive(index),
          'is-disabled': isDisabled(index),
        }"
        :key="step"
      >
        <router-link :to="`/create-malfunction/${index ? index : ''}`">
          <div class="tracker__item__inner">
            <div class="tracker__item__box">
              <span class="tracker__item__box__label">{{ index + 1 }}</span>
            </div>

            <span class="tracker__item__label">{{ step }}</span>
          </div>
        </router-link>
      </li>
    </ul>
  </div>
</template>
