import Flights from "../api/Flights";
import Items from "../api/Items";

export const sendCurrentStops = (vueThis) => {
  //check if already sending
  //if not send new set of data
  //check if flight is created

  if (
    !vueThis.$store.state.currentStopsSendInfo.isSending &&
    vueThis.$store.state.flightSettings.isSend
  ) {
    //can send stops
    let passedStops = [];
    let noIds = false;

    vueThis.$store.commit({
      type: "setCurrentStopsSendInfo",
      payload: { isSending: true },
    });

    vueThis.$store.state.currentStops.forEach((stop) => {
      if (stop.id) {
        if (stop.passed) {
          console.log("send me here", stop);
          passedStops.push(getpassedStopsData(stop));
        }
      } else {
        noIds = true;
      }
    });

    if (noIds) {
      //first get the currentStops and then try again
      getCurrentStops(vueThis);
      return false;
    }

    if (vueThis.$store.state.currentStops.length > passedStops.length) {
      //not at the end yet so remove the last item
      passedStops.pop();
    }

    console.log(passedStops);

    //send passed stops
    sendPassedStops(passedStops, vueThis);
  }
};

const sendPassedStops = (stops, vueThis) => {
  const formData = new FormData();

  formData.append("flight", vueThis.$store.state.flightSettings.id);
  formData.append("items", JSON.stringify(stops));

  return Items.passMutiple(formData)
    .then(() => {
      vueThis.$store.commit({
        type: "setCurrentStopsSendInfo",
        payload: { updated: false, isSending: false, isSend: false },
      });
    })
    .catch((err) => {
      vueThis.$store.commit({
        type: "setCurrentStopsSendInfo",
        payload: { updated: true, isSending: false, isSend: false },
      });

      throw { err, myError: "Pass error" };
    });
};

const getCurrentStops = (vueThis) => {
  vueThis.$store.commit({
    type: "setCurrentStopsSendInfo",
    payload: { isSending: false, isSend: false },
  });

  //get stops
  if (vueThis.$store.state.flightSettings.id) {
    Flights.detail(vueThis.$store.state.flightSettings.id)
      .then((r) => {
        let isUpdated = false;
        let newItems = r.data.body.all_items;

        if (vueThis.$store.state.currentStops.length) {
          newItems = [...vueThis.$store.state.currentStops]
            .map((currentStop) => {
              let newItem = currentStop;

              //already has items
              r.data.body.all_items.forEach((item) => {
                if (currentStop.departure === item.departure) {
                  //this one
                  newItem = {
                    ...item,
                    ...currentStop,
                    id: item.id,
                    flight_id: item.flight_id,
                    stop: item.stop,
                    why_skipped: item.why_skipped ? why_skipped : null,
                    updated_at: item.updated_at,
                    created_at: item.created_at,
                    isSending: false,
                    isSend: true,
                  };
                }
              });

              return { ...newItem };
            })
            .sort((a, b) => {
              if (a.departure > b.departure) {
                return 1;
              }

              if (a.departure < b.departure) {
                return -1;
              }

              return 0;
            });
        }

        //got the stops
        vueThis.$store.commit({
          type: "setCurrentStops",
          payload: newItems,
        });

        //is updated
        if (isUpdated) {
          vueThis.$store.commit({
            type: "setCurrentStopsSendInfo",
            payload: { updated: true, isSending: false },
          });
        }

        setTimeout(() => {
          //try sending again
          sendCurrentStops();
        }, 100);
      })
      .catch((error) => {
        vueThis.$store.commit({
          type: "setCurrentStopsSendInfo",
          payload: { isSending: false, isSend: false },
        });
      });
  }
};

const getpassedStopsData = (stop) => {
  const stopData = {
    item: stop.inserted ? "insert" : stop.id,
  };

  //is skipped so is data
  if (stop.skipped) {
    stopData.action = "skip";
    stopData.why_skipped = stop.why_skipped;
  }

  const keys = [
    "id",
    "arrived",
    "departured",
    "people_on",
    "people_off",
    "bikes_on",
    "bikes_off",
    "people",
    "bikes",
  ];

  keys.forEach((key) => {
    stopData[key] = stop[key];
  });

  return stopData;
};
