<script>
import "./Tracks.scss";
import Tracks from "./Tracks.js";

export default Tracks;
</script>

<template>
  <Step-wrapper title="Kies een traject" :isLoading="isLoading">
    <div class="shift-wrapper">
      <agile
        :centerMode="true"
        :slidesToShow="slides.length > 3 ? 3 : slides.length"
        :dots="false"
        ref="carousel"
      >
        <template #prevButton>
          <div class="shift__actions__prev">
            <div class="directionalButton">
              <span class="directionalButton__symbol">
                <Svg-icon iconType="Arrow-left" />
              </span>
            </div>
          </div>
        </template>
        <div
          v-for="track in slides"
          class="tracks__item shift__item slide"
          :key="track.id"
        >
          <div class="shift__item__inner">
            <div class="tracks__item__sizer">
              <div class="tracks__item__inner">
                <input
                  @change="() => handleChange(track)"
                  ref="trackBtn"
                  :checked="chosenTrack ? chosenTrack.id === track.id : false"
                  :value="track.id"
                  :id="track.id"
                  class="tracks__radio"
                  type="radio"
                />
                <label :for="track.id" class="tracks__radioFaker">
                  <div class="tracks__checked__symbol">
                    <span class="tracks__checked__theSymbol">
                      <Svg-icon iconType="check" iconColor="#fff" />
                    </span>
                  </div>

                  <span class="tracks__item__symbol">
                    <Svg-icon iconType="anchor" />
                  </span>

                  <span class="tracks__item__label">{{ track.name }}</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <template #nextButton>
          <div class="shift__actions__next">
            <div class="directionalButton">
              <span class="directionalButton__symbol">
                <Svg-icon iconType="Arrow-right" />
              </span>
            </div>
          </div>
        </template>
      </agile>
    </div>
  </Step-wrapper>
</template>
