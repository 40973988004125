import { VueAgile } from "vue-agile";
import Ships from "../../../api/Ships";

export default {
  components: {
    agile: VueAgile,
  },
  data() {
    return {
      isLoading: false,
      isLoadingExtraData: true,
      chosenShip: null,
    };
  },
  created() {
    if (!this.$store.state.allShips.length) {
      //no ships get them
      this.getShips();
    }

    if (this.$store.state.flightSettings.ship) {
      this.chosenShip = { ...this.$store.state.flightSettings.ship };
      this.emitter.emit("start--set-disabled", false);
    } else {
      this.emitter.emit("start--set-disabled", true);
    }

    this.emitter.on("ship--dataIsSet", (ship = null) => {
      this.chosenShip = ship;
      this.isLoadingExtraData = false;

      this.emitter.emit("start--set-disabled", ship === null);
    });
  },
  mounted() {
    this.moveToSelected();
  },

  methods: {
    moveToSelected() {
      if (
        this.chosenShip &&
        this.$refs.carousel &&
        this.$store.state.allShips.length
      ) {
        this.$store.state.allShips.forEach((ship, index) => {
          if (ship.id === this.chosenShip.id) {
            //is current slide index
            this.$refs.carousel.goTo(index);
          }
        });
      }
    },
    getShips() {
      if (localStorage.getItem("token") && this.$store.state.isOnline) {
        this.isLoading = true;

        Ships.overview(this)
          .then((r) => this.onSuccess(r))
          .catch((r) => {
            this.onFail(r);
          });
      }
    },

    onSuccess(r) {
      this.$store.commit({
        type: "setAllShips",
        payload: r.data.body,
      });

      this.isLoading = false;
    },

    onFail(r) {
      this.isLoading = false;

      let theMessage = {};
      theMessage.status = r.status;
      theMessage.title = r.data.message;
      theMessage.subtitle = "Oeps?!";
      theMessage.body = "";
      theMessage.icon = "whoops";

      this.emitter.emit("toggleOffcanvas", true, "Whoops", theMessage);
    },

    handleChange(e, item) {
      if (!e.target.checked) {
        this.chosenShip = null;

        //exist allow next step
        this.emitter.emit("start--set-disabled", true);
      } else {
        this.chosenShip = item;

        //exist allow next step
        this.emitter.emit("start--set-disabled", false);
      }
    },
  },
  watch: {
    chosenShip() {
      //wipe crew
      this.$store.commit({
        type: "setFlightSettings",
        payload: { ship: { ...this.chosenShip }, crew: [] },
      });

      if (this.$store.state.flightSettings.shift && this.chosenShip) {
        const currentShip = this.$store.state.flightSettings.ship;
        const passengerData = {
          current_people: 0,
          current_bikes: 0,
          max_people: this.chosenShip.max_people
            ? this.chosenShip.max_people
            : this.chosenShip.type
            ? this.chosenShip.type.max_people
            : 0,
          max_bikes: this.chosenShip.max_bikes
            ? this.chosenShip.max_bikes
            : this.chosenShip.type
            ? this.chosenShip.type.max_bikes
            : 0,
        };

        if (this.chosenShip.id === currentShip.id) {
          //same as last time use these passengers
          passengerData.current_people = currentShip.current_people
            ? currentShip.current_people
            : 0;
          passengerData.current_bikes = currentShip.current_bikes
            ? currentShip.current_bikes
            : 0;
        } else {

          ///

        }

        this.$store.commit({
          type: "setPassengers",
          payload: passengerData,
        });
      }
    },
  },
};
