export default {
  methods: {
    now() {
      const nowDate = new Date();

      return `${nowDate.getHours()}:${nowDate.getMinutes()} ${nowDate.getDate()}/${
        nowDate.getMonth() + 1
      }/${nowDate.getFullYear()}`;
    },
  },
};
