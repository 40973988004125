<script>
import "./FlightOverview.scss";
import FlightOverview from "./FlightOverview.js";

export default FlightOverview;
</script>

<template>
  <Page-wrapper title="Overview" :colored="true">
    <div class="flightOverview__inner">
      <div class="flightOverview__summary">
        <div class="flightOverview__summary__inner">
          <div class="flightOverview__flightInfo">
            <span class="flightOverview__flightInfo__dateDay">
              <Date dateType="nameOfDay" />
            </span>
            <span class="flightOverview__flightInfo__date">
              <Date dateType="DD/MM/YYYY" />
            </span>

            <div class="flightOverview__flightInfo__trackInfo">
              <div class="flightOverview__flightInfo__theTrack">
                <span class="flightOverview__flightInfo__theTrack__symbol">
                  <Svg-icon iconType="Anchor-circle"></Svg-icon>
                </span>
                <span class="flightOverview__flightInfo__theTrack__label">{{
                  $store.state.flightSettings.track
                    ? $store.state.flightSettings.track.name
                    : ""
                }}</span>
              </div>

              <div class="flightOverview__flightInfo__theShift">
                <span class="flightOverview__flightInfo__theShift__label">{{
                  $store.state.flightSettings.shift
                    ? $store.state.flightSettings.shift.name
                    : ""
                }}</span>
              </div>
            </div>
          </div>

          <div class="flightOverview__passengersInfo">
            <span class="flightOverview__subtitle"
              >Vervoerde passagiers deze vaart</span
            >

            <div class="flightOverview__passengersInfo__total">
              <div class="flightOverview__passengersInfo__total__item">
                <span class="flightOverview__passengersInfo__total__symbol">
                  <Svg-icon iconType="Passengers"></Svg-icon>
                </span>

                <span class="flightOverview__passengersInfo__total__label">{{
                  flightInfo ? flightInfo.total_people : 0
                }}</span>
              </div>

              <div class="flightOverview__passengersInfo__total__item">
                <span class="flightOverview__passengersInfo__total__symbol">
                  <Svg-icon iconType="Bike"></Svg-icon>
                </span>

                <span class="flightOverview__passengersInfo__total__label">{{
                  flightInfo ? flightInfo.total_bikes : 0
                }}</span>
              </div>
            </div>
          </div>

          <div class="flightOverview__notifications">
            <span class="flightOverview__subtitle">Meldingen</span>

            <div class="flightOverview__notifications__inner">
              <ul class="flightOverview__notifications__list">
                <li
                  @click="handleNotificationsClick(notification)"
                  v-for="(notification, index) in $store.state.notifications"
                  :key="index"
                  class="flightOverview__notifications__list__item"
                >
                  <div class="flightOverview__notifications__item__content">
                    <span
                      class="flightOverview__notifications__content__time"
                      >{{ formatTimeOfNotification(notification) }}</span
                    >

                    <span
                      class="flightOverview__notifications__content__label"
                      >{{ notification.message }}</span
                    >

                    <span
                      class="flightOverview__notifications__content__symbol"
                    >
                      <Svg-icon iconType="Spyglass"></Svg-icon>
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="flightOverview__overview">
        <ul class="flightOverview__list">
          <li
            v-for="(item, index) in $store.state.currentStops"
            :key="index"
            ref="theItems"
            class="flightOverview__list__item"
          >
            <div class="flightOverview__item__content">
              <span class="flightOverview__content__symbol">
                <Svg-icon iconType="Anchor-circle"></Svg-icon>
              </span>

              <span class="flightOverview__content__label">{{
                item.stop.name
              }}</span>

              <span
                v-if="item.departured"
                class="flightOverview__content__timeDiff"
                >{{ getTimeDiff(item) }}</span
              >
              <span
                v-if="item.departure"
                class="flightOverview__content__time"
                >{{ departured(item.departure) }}</span
              >
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="flightOverview__actions">
      <router-link
        :to="hasStopChecklist ? `/checklist` : `/dashboard`"
        class="button button--bottom button--pairing-brand-2 flightOverview__actions__button"
      >
        <span class="button__symbol flightOverview__actions__symbol">
          <Svg-icon
            :iconType="hasStopChecklist ? 'Checklist-icon' : 'Dashboard-icon'"
          ></Svg-icon>
        </span>
        <span class="button__label">{{
          hasStopChecklist ? "Naar Checklist" : "Naar dashboard"
        }}</span>
      </router-link>
    </div>
  </Page-wrapper>
</template>
