<template>
	<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 25.3 21.7" xml:space="preserve">
		<g>
			<path d="M23.3,8.8c-0.4-2.2-2.2-3.9-4.4-4c0-0.2,0.1-0.4,0.1-0.6C19,1.9,17.1,0,14.8,0c-2,0-3.7,1.4-4.1,3.3 c0.6,0.1,1.2,0.3,1.7,0.6c0.1-1.2,1.1-2.2,2.4-2.2c1.3,0,2.4,1.1,2.4,2.4c0,1.3,1.3,2.3,1.4,2.3c1.7,0,3,1.4,3,3 c0,0.5,0.4,0.9,0.9,0.9c0.6,0,1,0.5,1,1c0,0.6-0.4,1-1,1l-2.9,0c0.1,0.1,0.2,0.1,0.2,0.2c0.4,0.5,0.7,1,0.7,1.6h2 c1.5,0,2.8-1.3,2.8-2.8C25.3,10.2,24.5,9.1,23.3,8.8z"/>
			<g>
				<path d="M20.5,14.6c0-0.1,0-0.3,0-0.4c-0.1-0.6-0.3-1.2-0.7-1.6c-0.1-0.1-0.1-0.1-0.2-0.2c-0.3-0.2-0.6-0.4-1-0.5 c-0.4-2.1-2.1-3.8-4.4-4c0-0.2,0.1-0.4,0.1-0.6c0-1.4-0.7-2.7-1.9-3.4c-0.5-0.3-1.1-0.5-1.7-0.6c-0.2,0-0.4-0.1-0.6-0.1 c-2,0-3.7,1.4-4.1,3.3C4.4,6.9,3,8.3,2.8,10.1C1.2,10.5,0,11.9,0,13.6c0,2,1.6,3.6,3.6,3.6h1.5h0.1l1.5,1.5 C6.9,18.9,7.1,19,7.4,19c0.2,0,0.5-0.1,0.6-0.3c0.4-0.4,0.4-0.9,0-1.3L7.5,17c0.2-0.2,0.3-0.4,0.3-0.7c0-0.5-0.4-0.9-0.9-0.9H6 H5.1H3.7c-1,0-1.9-0.8-1.9-1.9s0.8-1.9,1.9-1.9c0.5,0,0.9-0.4,0.9-0.9c0-0.1,0-0.1,0-0.2c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1 c0-1.3,1.1-2.4,2.4-2.4c0.5,0,0.9-0.4,0.9-0.9c0-1.3,1.1-2.4,2.4-2.4c0.3,0,0.6,0.1,0.9,0.2c0.9,0.3,1.5,1.2,1.5,2.2 c0,1.3-1.1,2.4-2.3,2.4c-0.5,0-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9c1.5,0,2.7-0.8,3.4-1.9c0.1,0.1,0.2,0.1,0.3,0.1 c1.6,0,2.8,1.2,2.9,2.8c0,0.1,0,0.1,0,0.2c0,0.5,0.4,0.9,0.9,0.9c0.3,0,0.5,0.1,0.7,0.3c0.1,0.1,0.2,0.3,0.2,0.4 c0,0.1,0.1,0.2,0.1,0.3c0,0.5-0.5,0.9-1.1,0.9h-1.9c-0.4,0-0.7,0.2-0.9,0.6l-0.2,0.6l2.7,2.7c0.2,0.2,0.4,0.3,0.6,0.3 s0.5-0.1,0.6-0.3c0.4-0.4,0.4-0.9,0-1.2l-0.9-0.9C19.3,17.1,20.4,16,20.5,14.6z"/>
				<path d="M9.2,17.9c0.2,0.2,0.4,0.3,0.6,0.3c0.2,0,0.5-0.1,0.6-0.3c0.4-0.4,0.4-0.9,0-1.2l-0.3-0.3 c-0.4-0.4-0.9-0.4-1.3,0c-0.4,0.4-0.4,0.9,0,1.2L9.2,17.9z"/>
				<path d="M12.1,18.3c-0.4-0.4-0.9-0.4-1.2,0c-0.4,0.4-0.4,0.9,0,1.2l1.7,1.7c0.2,0.2,0.4,0.3,0.6,0.3s0.5-0.1,0.6-0.3 c0.4-0.4,0.4-0.9,0-1.2L12.1,18.3z"/>
				<path d="M9.4,19c-0.4-0.4-0.9-0.4-1.2,0c-0.4,0.4-0.4,0.9,0,1.2l1.2,1.2c0.2,0.2,0.4,0.3,0.6,0.3s0.5-0.1,0.6-0.3 c0.4-0.4,0.4-0.9,0-1.2L9.4,19z"/>
				<path d="M13.9,16.8c0.1-0.1,0.1-0.2,0.1-0.4c0-0.5-0.4-0.9-0.9-0.9h-2.9l3.5,3.5c0.2,0.2,0.4,0.3,0.6,0.3 c0.2,0,0.5-0.1,0.6-0.3c0.4-0.4,0.4-0.9,0-1.2L13.9,16.8z"/>
			</g>
		</g>
	</svg>
</template>