import axios from "./index";

export default {
  overview(vueThis) {
    return axios.get(`/tracks/overview`).then((response) => response);
  },

  shifts(track, vueThis) {
    return axios
      .get(`/tracks/shifts?track=${track}`)
      .then((response) => response);
  },
};
