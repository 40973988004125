<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 47.8 51.8"
    xml:space="preserve"
  >
    <g>
      <path
        d="M11.9,34.5c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.2-0.1-0.4-0.1c-0.6-0.1-1.2,0.1-1.6,0.5 C9.3,35,9.1,35.5,9.1,36s0.2,1,0.5,1.3c0.4,0.4,0.8,0.5,1.3,0.5c0.1,0,0.2,0,0.4-0.1c0.1,0,0.2-0.1,0.4-0.1 c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.1,0.2-0.2c0.4-0.4,0.5-0.8,0.5-1.3s-0.2-1-0.5-1.3C12.1,34.6,11.9,34.6,11.9,34.5z"
      />
      <path
        d="M36.4,25.1c3.6,0,6.5-2.9,6.5-6.5s-2.9-6.5-6.5-6.5s-6.5,2.9-6.5,6.5S32.8,25.1,36.4,25.1z"
      />
      <path
        d="M47.8,18.8c0-3.1-1.2-6-3.3-8.2c-2.2-2.2-5.1-3.4-8.2-3.4l0,0c-1.7,0-3.5,0.4-5.1,1.2l-0.3,0.1l-0.3-0.2 c-0.2-0.1-0.4-0.2-0.6-0.4c-0.9-0.5-1.8-0.9-2.6-1.2l-0.5-0.2L27,6c0-0.2,0-0.4,0-0.7c0-3-2.4-5.4-5.4-5.4c-3,0-5.4,2.4-5.4,5.4 c0,0.2,0,0.5,0,0.6l0.1,0.5L16,6.7C11.4,8.5,5.9,12.6,5.9,22.1c0,10.1-2.1,13.7-3.9,15c-1.3,0.8-2,2.2-2,3.7c0,2.5,2,4.5,4.5,4.5 h10.4l0.1,0.5c0.7,3.5,3.7,6,7.3,6c3.5,0,6.6-2.5,7.3-6l0.1-0.5h9.2c2.5,0,4.5-2,4.5-4.5c0-1.5-0.7-2.9-2-3.7 c-0.8-0.5-2.2-2.1-3.1-6.1c0-0.1,0-0.1,0-0.2L38,30.3l0.6-0.1C43.9,29,47.8,24.2,47.8,18.8z M18.8,5.1c0.2-1.5,1.4-2.7,2.9-2.7 c1.5,0,2.7,1.1,3,2.7l0.1,0.8L24,5.7c-1.4-0.2-3-0.2-4.5,0l-0.8,0.1L18.8,5.1z M26.3,47.4c-1,1.3-2.5,2-4,2c-1.6,0-3.1-0.8-4-2 l-0.8-1h9.6L26.3,47.4z M38.4,37.5l0.2,0.2c0.4,0.5,0.9,1,1.4,1.3l0,0c0.6,0.4,1,1.1,1,1.7c0,1.2-0.9,2.1-2.1,2.1H4.5 c-1.2,0-2.1-0.9-2.1-2.1c0-0.7,0.4-1.4,1-1.8c3.2-2.2,4.9-7.9,4.9-16.9c0-9,5.5-12.5,10.2-13.8l0.1,0c0.2-0.1,3.2-1,6.4,0 c1,0.3,2,0.7,3,1.2c0,0,0.1,0,0.1,0l0.7,0.4l-0.6,0.6c-2.2,2.2-3.5,5.1-3.5,8.3c0,6,4.6,11,10.6,11.5l0.5,0l0.1,0.5 c0,0.1,0,0.2,0.1,0.3c0.2,1.2,0.6,2.3,0.9,3.2l0.3,0.8H15.7c-0.7,0-1.2,0.5-1.2,1.2c0,0.7,0.5,1.2,1.2,1.2H38.4z M36.4,28.1 c-5.2,0-9.5-4.3-9.5-9.5s4.3-9.5,9.5-9.5s9.5,4.3,9.5,9.5S41.6,28.1,36.4,28.1z"
      />
    </g>
  </svg>
</template>
