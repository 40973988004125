import Flights from "../../../api/Flights";
import Items from "../../../api/Items";

export default {
  data() {
    return {
      uploadPoints: [
        {
          label: "Vaart gegevens versturen",
          statusName: "flightSettings.isSend",
        },
        {
          label: "Checklist versturen",
          statusName: "checklistIsSend",
        },
        {
          label: "Tellingen doorsturen",
          statusName: "currentStopsSendInfo.isSend",
        },
      ],
    };
  },
  created() {
    this.sendCurrentStops();
    this.$store.commit({
      type: "setChecklistIsUpdated",
      payload: true,
    });

    //try sending every 30 seconds
    setInterval(() => {
      this.emitter.emit("app--sendCheckist");
      this.sendCurrentStops();
    }, 2000);
  },
  computed: {
    isAllSend() {
      let isSend = true;

      this.uploadPoints.forEach((point) => {
        if (!this.isItemLoading(point.statusName)) {
          //not send
          isSend = false;
        }
      });

      return isSend;
    },
  },
  methods: {
    handleLogout() {
      //clear the old store if everything is send
      if (
        //check if the stops are updated or sending
        this.$store.state.currentStopsSendInfo.isSend &&
        //check if check if the checklist is send
        this.$store.state.checklistIsCompleted &&
        //check if all the malfunctions are send
        !this.$store.state.newMalfunctionSendInfo.needsSending.length &&
        //check flight settings is sending
        this.$store.state.flightSettings.isSend
      ) {
        localStorage.removeItem("oldStore");
      }

      localStorage.removeItem("store");
      localStorage.removeItem("token");
      localStorage.removeItem("concession");

      this.$store.commit({
        type: "clearStore",
        payload: {},
      });

      this.emitter.emit("toggleOffcanvas", false);
      this.$router.push("/");
    },
    isItemLoading(statusName) {
      const status = this.getDeep(this.$store.state, statusName);
      return status[0];
    },
    getDeep(from, ...selectors) {
      return [...selectors].map((s) =>
        s
          .replace(/\[([^\[\]]*)\]/g, ".$1.")
          .split(".")
          .filter((t) => t !== "")
          .reduce((prev, cur) => prev && prev[cur], from)
      );
    },
    checkIfAllSend() {
      return (
        this.$store.state.flightSettings.isSend &&
        this.$store.state.checklistIsSend
      );
    },

    sendCurrentStops() {
      console.log(
        "send currentstop",
        !this.$store.state.currentStopsSendInfo.isSending,
        !this.$store.state.currentStopsSendInfo.isSend,
        this.checkIfAllSend(),
        this.checkIfAllStopsAreSet()
      );
      if (
        !this.$store.state.currentStopsSendInfo.isSending &&
        !this.$store.state.currentStopsSendInfo.isSend &&
        this.checkIfAllSend() &&
        this.checkIfAllStopsAreSet()
      ) {
        const passedStops = [];
        let noIds = false;

        this.$store.commit({
          type: "setCurrentStopsSendInfo",
          payload: { isSending: true },
        });

        this.$store.state.currentStops.forEach((stop) => {
          if (stop.id) {
            if (stop.passed) {
              console.log("send me", stop);
              /* if (stop.skipped) {
              //item is skipped
              if (!stop.inserted) {
                //is not inserted so send skipped
                promises.push(this.removeStop(stop));
              }

              //else ignore this stop
            } else if (stop.inserted) {
              //item is inserted
              promises.push(this.insertStop(stop));
            } else {
              //item is passed
            }*/

              passedStops.push(this.getpassedStopsData(stop));
            }
          } else {
            noIds = true;
          }
        });

        if (noIds) {
          //first get the currentStops and then try again
          this.getCurrentStops();
          return false;
        }

        //send passed stops
        this.sendPassedStops(passedStops);
        /*promises.push();

        Promise.all(promises)
          .then(() => {
            console.log("all send");

            this.$store.commit({
              type: "setCurrentStopsSendInfo",
              payload: { updated: false, isSending: false, isSend: true },
            });

            //all send so update the oldStore
            setTimeout(() => {
              localStorage.setItem(
                "oldStore",
                JSON.stringify(this.$store.state)
              );
            }, 50);
          })
          .catch(() => {
            this.$store.commit({
              type: "setCurrentStopsSendInfo",
              payload: { isSending: true },
            });
          });*/
      }
    },

    checkIfAllStopsAreSet() {
      const stopsSet =
        this.$store.state.currentStops.filter((stop) => {
          if (stop) {
            //check if passed and has all data of skipped (no data needed)
            if (
              (stop.departured && stop.arrived && stop.passed) ||
              stop.skipped
            ) {
              return false;
            }
          }

          return true;
        }).length === 0;

      this.$store.commit({
        type: "setIsCompleted",
        payload: stopsSet,
      });

      return stopsSet;
    },

    insertStop(stop) {
      const promises = [];
      const formData = new FormData();

      formData.append("flight", this.$store.state.flightSettings.id);
      formData.append("after", stop.after_index);
      formData.append("stop", stop.stop_id);
      formData.append("departure", stop.departure);

      Items.insert(formData)
        .then((r) => {
          const items =
            typeof r.data.body.items === "string"
              ? JSON.parse(r.data.body.items)
              : r.data.body.items;
          //update the inserted stop

          items.forEach((item) => {
            if (item.departure === stop.departure) {
              //is affected departure
              //add stop data and it's id
              promises.push(this.sendOnePassedStop({ ...stop, id: item.id }));
            }
          });
        })
        .catch((err) => {
          throw { err, myError: "insert error" };
        });

      return Promise.all(promises).catch((err) => {
        throw { err, myError: "insert pass error" };
      });
    },

    getpassedStopsData(stop) {
      const stopData = {
        item: stop.inserted ? "insert" : stop.id,
      };

      //is skipped so is data
      if (stop.skipped) {
        stopData.action = "skip";
        stopData.why_skipped = stop.why_skipped;
      }

      const keys = [
        "id",
        "arrived",
        "departured",
        "people_on",
        "people_off",
        "bikes_on",
        "bikes_off",
        "people",
        "bikes",
      ];

      keys.forEach((key) => {
        stopData[key] = stop[key];
      });

      return stopData;
    },
    sendOnePassedStop(stop) {
      const formData = new FormData();

      formData.append("flight", this.$store.state.flightSettings.id);
      formData.append("items", JSON.stringify([this.getpassedStopsData(stop)]));

      return Items.passMutiple(formData).catch((err) => {
        throw { err, myError: "One pass error" };
      });
    },
    sendPassedStops(stops) {
      console.log("here");
      const formData = new FormData();

      formData.append("flight", this.$store.state.flightSettings.id);
      formData.append("items", JSON.stringify(stops));

      return Items.passMutiple(formData)
        .then(() => {
          this.$store.commit({
            type: "setCurrentStopsSendInfo",
            payload: { updated: false, isSending: false, isSend: true },
          });
        })
        .catch((err) => {
          this.$store.commit({
            type: "setCurrentStopsSendInfo",
            payload: { isSending: false, isSend: false },
          });
          throw { err, myError: "Pass error" };
        });
    },
    removeStop(stop) {
      const formData = new FormData();

      formData.append("item", stop.id);
      formData.append("flight", stop.flight_id);
      formData.append("why_skipped", stop.why_skipped);
      formData.append("remarks", stop.remarks);

      return Items.skip(formData).catch((err) => {
        throw { err, myError: "Skip error" };
      });
    },
    getCurrentStops() {
      console.log("getting current stops");

      this.$store.commit({
        type: "setCurrentStopsSendInfo",
        payload: { isSending: false, isSend: false },
      });

      //get stops
      if (this.$store.state.flightSettings.id) {
        Flights.detail(this.$store.state.flightSettings.id)
          .then((r) => {
            let isUpdated = false;
            let newItems = r.data.body.all_items;

            if (this.$store.state.currentStops.length) {
              newItems = [...this.$store.state.currentStops]
                .map((currentStop) => {
                  let newItem = currentStop;

                  //already has items
                  r.data.body.all_items.forEach((item) => {
                    if (currentStop.departure === item.departure) {
                      //this one
                      newItem = {
                        ...item,
                        ...currentStop,
                        id: item.id,
                        flight_id: item.flight_id,
                        stop: item.stop,
                        why_skipped: item.why_skipped ? why_skipped : null,
                        updated_at: item.updated_at,
                        created_at: item.created_at,
                        isSending: false,
                        isSend: true,
                      };
                    }
                  });

                  return { ...newItem };
                })
                .sort((a, b) => {
                  if (a.departure > b.departure) {
                    return 1;
                  }

                  if (a.departure < b.departure) {
                    return -1;
                  }

                  return 0;
                });
            }

            //got the stops
            this.$store.commit({
              type: "setCurrentStops",
              payload: newItems,
            });

            //is updated
            if (isUpdated) {
              this.$store.commit({
                type: "setCurrentStopsSendInfo",
                payload: { updated: true, isSending: false },
              });
            }

            setTimeout(() => {
              //try sending again
              this.sendCurrentStops();
            }, 100);
          })
          .catch((error) => {});
      }
    },
  },
  watch: {
    "$store.state.currentStops"() {
      this.sendCurrentStops();
    },
    "$store.state.isOnline"() {
      this.sendCurrentStops();
    },
    "$store.state.flightSettings.isSend"() {
      this.sendCurrentStops();
    },
    "$store.state.flightSettings.checklistIsSend"() {
      this.sendCurrentStops();
    },
    "$store.state.currentStopsSendInfo.isSend"() {
      //save old store
      localStorage.setItem("oldStore", JSON.stringify(this.$store.state));
    },
  },
};
