export default {
  props: {
    viewData: [Object, String],
  },

  data() {
    return {
      submit: false,
    };
  },

  methods: {
    handleSubmit() {},

    onSuccess(response) {},

    setEndChecklist(response) {},
  },
};
