<script>
import "./Success.scss";
import Success from "./Success.js";

export default Success;
</script>

<template>
  <div class="success">
    <header class="success__header">
      <span class="success__symbol">
        <Svg-icon :iconType="theContent.icon"></Svg-icon>
      </span>

      <span class="success__header__subtitle">{{ theContent.title }}</span>
      <h3 class="heading heading--3 success__header__title">
        {{ theContent.subtitle }}
      </h3>
    </header>

    <div class="success__content">
      <p>{{ theContent.body }}</p>
    </div>

    <div class="success__submit">
      <button
        @click="handleClick()"
        class="button button--pairing-neutrals-5 success__theSubmit"
      >
        <span class="button__label">{{ theContent.button }}</span>
      </button>
    </div>
  </div>
</template>
