import { v4 as uuidv4 } from "uuid";

export default {
  props: {
    activeIndex: Number,
    currentStop: Object,
  },
  data() {
    return {
      isLoading: false,
      inputFields: {
        stop: "",
        hours: "",
        mins: "",
      },
      isInvalid: false,
      isSuccess: false,
      theMessage: {},
    };
  },

  created() {
    this.emitter.emit("flight-insert-form--changeStop", "");
    this.emitter.emit("app--getStops");
  },

  watch: {
    "inputFields.stop": {
      handler(newVal, oldVal) {
        if (newVal == oldVal) return false;

        this.isInvalid = false;

        this.$refs.stop.classList.remove("is-invalid");
      },
    },
  },

  computed: {
    filteredStops() {
      //prevent current stop from being selected
      return this.$store.state.allStops.filter(
        (stop) => stop.id !== this.currentStop.stop_id
      );
    },
  },

  methods: {
    handleChangeInput(e) {
      const select = e.currentTarget;

      this.emitter.emit(
        "flight-insert-form--changeStop",
        select.options[select.selectedIndex].text
      );
    },

    handleCloseClick() {
      this.emitter.emit("flight-insert-form--toggle", false);
    },

    handleKeyevent(e) {
      e.target.parentNode.parentNode.classList.remove("is-invalid");

      this.isInvalid = false;
    },

    handleSubmit() {
      if (this.validateIt()) {
        this.sendIt();
      }
    },
    addTime(start, increment, respectDay = false) {
      let pad = (n) => ("0" + n).slice(-2);
      let timeToMins = (time) =>
        time.split(":").reduce((h, m) => h * 60 + m * 1);
      let minsToTime = (mins, respectDay = false) =>
        `${pad(
          ((mins / 60) | 0) % (respectDay ? 24 : Number.POSITIVE_INFINITY)
        )}:${pad(mins % 60)}`;
      return minsToTime(timeToMins(start) + timeToMins(increment), respectDay);
    },
    addLeadingZero(int) {
      if (int <= 9) {
        return `0${int}`;
      }

      return int;
    },
    sendIt() {
      const currentStops = this.$store.state.currentStops;
      const currentStop = this.$store.state.currentStops[this.activeIndex];
      const stopLocation = this.$store.state.allStops.filter(
        (stop) => stop.id === this.inputFields.stop
      )[0];

      const timeSplit = this.addTime(
        currentStop.departure.replace(":00", ""),
        `${this.inputFields.hours ? this.inputFields.hours : 0}:${
          this.inputFields.mins ? this.inputFields.mins : 0
        }`,
        true
      ).split(":");

      const delay = new Date(0, 0, 0, timeSplit[0], timeSplit[1]);

      const newStop = {
        ...currentStop,
        arrived: null,
        arrive: `${this.addLeadingZero(delay.getHours())}:${this.addLeadingZero(
          delay.getMinutes()
        )}:00`,
        departure: `${this.addLeadingZero(
          delay.getHours()
        )}:${this.addLeadingZero(delay.getMinutes())}:00`,
        departured: currentStop.arrived
          ? currentStop.arrived
          : `${this.addLeadingZero(delay.getHours())}:${this.addLeadingZero(
              delay.getMinutes()
            )}:00`,
        stop: stopLocation,
        stop_id: this.inputFields.stop,
        is_manually_added: true,
        after_index: currentStop.id,
        isSend: false,
        isSending: false,
        passed: 0,
        skipped: 0,
        inserted: 1,
        stop_name: stopLocation.name,

        id: uuidv4(),
      };

      currentStops.splice(this.activeIndex + 1, 0, newStop);

      this.$store.commit({
        type: "setCurrentStops",
        payload: currentStops,
      });

      this.$store.commit({
        type: "setCurrentStopsSendInfo",
        payload: { updated: true, isSending: false },
      });

      setTimeout(() => {
        //set active index
        let newStopIndex = -1;
        this.$store.state.currentStops.forEach((stop, index) => {
          if (newStopIndex === -1 && !stop.skipped && !stop.passed) {
            newStopIndex = index;
          }
        });

        if (newStopIndex !== -1) {
          this.$store.commit({
            type: "setCurrentStopIndex",
            payload: newStopIndex,
          });
        } else {
          //all done
          this.$store.commit({
            type: "setCurrentStopIndex",
            payload: newStopIndex,
          });
        }
      }, 50);

      /*this.$store.commit({
        type: "setIsCompleted",
        payload: false,
      });*/

      /*this.isLoading = true;

      // create formdata object
      let formData = new FormData();

      formData.append(
        "flight",
        JSON.parse(localStorage.getItem("flight"))[0].id
      );
      formData.append("after", this.currentItem.id);
      formData.append("stop", this.inputFields.stop);
      formData.append("departure", this.currentItem.departure);
      formData.append("arrive", `${delay.getHours()}:${delay.getMinutes()}:00`);

      Items.insert(formData).then((response) =>
        response.status !== 200
          ? this.onFail(response)
          : this.onSuccess(response)
      );*/
      this.emitter.emit("flight-insert-form--toggle", false);
    },

    onSuccess(response) {
      /*this.isLoading = false;

      this.items = JSON.parse(response.data.body.items);

      this.$store.commit("setItems", this.items);
      localStorage.setItem("items", JSON.stringify(this.items));

      this.isSuccess = true;

      this.theMessage.title = "Halte toegevoegd";
      this.theMessage.subtitle = "Succes!";
      this.theMessage.body = "";
      this.theMessage.icon = "insert";

      this.emitter.emit("updateItems", true);*/
    },

    onFail(response) {
      this.isLoading = false;

      let theMessage = {};
      theMessage.status = response.status;
      theMessage.title = response.data.message;
      theMessage.subtitle = "Oeps?!";
      theMessage.body = "";
      theMessage.icon = "radar";

      this.emitter.emit("toggleOffcanvas", [true, "Whoops", theMessage]);
    },

    validateIt() {
      this.isInvalid = false;

      // set zero as value if mins are filled
      this.inputFields.mins != "" &&
        this.inputFields.hours == "" &&
        (this.inputFields.hours = "0");

      this.inputFields.stop == "" &&
        ((this.isInvalid = true), this.$refs.stop.classList.add("is-invalid"));
      this.inputFields.mins == "" &&
        ((this.isInvalid = true), this.$refs.mins.classList.add("is-invalid"));

      if (this.isInvalid) return false;

      return true;
    },
  },
};
