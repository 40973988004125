<template>
  <svg
    width="18"
    height="11"
    viewBox="0 0 18 11"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.07 4.842a1.2 1.2 0 0 1-.739.27c-.666 0-1.206-.54-1.206-1.206 0-.288.109-.54.27-.738a2.535 2.535 0 0 0-3.277 2.43 2.535 2.535 0 0 0 2.54 2.538 2.535 2.535 0 0 0 2.537-2.538 3.316 3.316 0 0 0-.125-.756Zm5.471-.828c-1.458-1.44-4.41-3.87-7.901-3.87-3.475 0-6.445 2.43-7.902 3.87A2.205 2.205 0 0 0 .09 5.58c0 .594.234 1.152.648 1.566 1.458 1.44 4.41 3.87 7.902 3.87 3.474 0 6.444-2.43 7.901-3.87.414-.414.648-.972.648-1.566 0-.594-.234-1.152-.648-1.566ZM6.965 9.126c-1.601-.432-2.754-1.872-2.771-3.51v-.072c.018-1.656 1.17-3.096 2.771-3.51A6.476 6.476 0 0 1 8.639 1.8c.576 0 1.135.09 1.675.234 1.601.432 2.754 1.872 2.771 3.51v.072c-.018 1.656-1.17 3.096-2.771 3.51a6.476 6.476 0 0 1-1.675.234c-.575 0-1.134-.09-1.674-.234Z"
      fill="#FFF"
    />
  </svg>
</template>
