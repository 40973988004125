<script>
import "./CreateMalfunction.scss";
import CreateMalfunction from "./CreateMalfunction.js";

export default CreateMalfunction;
</script>

<template>
  <Page-wrapper title="storing versturen">
    <section class="malfunctionsPanel">
      <transition name="fade" mode="out-in">
        <div v-if="isLoading" class="malfunctionsPanel__loader">
          <Loader />
        </div>

        <div v-else class="malfunctionsPanel__inner">
          <Tracker :viewData="tracker" />

          <div class="malfunctionsPanel__theSteps">
            <div class="malfunctionsPanel__theSteps__inner">
              <transition name="fade" mode="out-in">
                <Malfunctions-situation v-if="this.tracker.activeStep === 0" />
                <Malfunctions-notes v-else-if="this.tracker.activeStep === 1" />
                <Malfunctions-confirm
                  v-else-if="this.tracker.activeStep === 2"
                />
              </transition>
            </div>
            <div class="malfunctionsPanel__nextBtn">
              <button
                @click="handlePrevClick"
                class="button button--pairing-brand-0"
              >
                <span class="button__symbol">
                  <Svg-icon iconType="Arrow-left"></Svg-icon>
                </span>
                <span class="button__label">Terug</span>
              </button>

              <button
                v-if="tracker.activeStep < tracker.steps.length - 1"
                :disabled="isDisabled"
                @click="handleNextClick"
                class="button button--pairing-brand-1"
              >
                <span class="button__label">Volgende</span>
                <span class="button__symbol">
                  <Svg-icon iconType="Arrow-right"></Svg-icon>
                </span>
              </button>

              <button
                v-if="tracker.activeStep === tracker.steps.length - 1"
                @click="handleSubmit"
                class="button button--pairing-positive"
              >
                <span class="button__label">Versturen</span>
                <span class="button__symbol">
                  <Svg-icon iconType="Arrow-right"></Svg-icon>
                </span>
              </button>
            </div>
          </div>

          <div class="malfunctionsPanel__callout">
            <span class="malfunctionsPanel__callout__symbol">
              <Svg-icon iconType="Lifebuoy-flat"></Svg-icon>
            </span>
            <span class="malfunctionsPanel__callout__label"
              >Urgente storing? Bel steeds eerst het noodnummer TD: 0032 3 844
              19 19</span
            >
          </div>
        </div>
      </transition>

      <div class="malfunctionsPanel__outer"></div>
    </section>
  </Page-wrapper>
</template>
