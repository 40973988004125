import Auth from "../../../api/Auth";
import Checklists from "../../../api/Checklists";
import { defaultStore } from "../../../config";

export default {
  data() {
    return {
      isValid: true,
      isDisabled: false,
      isLoading: false,
      noMatchingCredentials: false,
      inputs: {
        email: null,
        pin: null,
      },
    };
  },

  methods: {
    handleSubmit(e) {
      e.preventDefault();

      this.isDisabled = true;

      this.validateIt() && this.sendIt();
    },

    handleClick() {
      this.emitter.emit("toggleOffcanvas", [true, "PincodeForm"]);
    },

    validateIt() {
      this.isValid = true;

      !this.inputs.email &&
        (this.$refs.email.classList.add("is-invalid"), (this.isValid = false));

      !this.inputs.pin &&
        (this.$refs.pin.classList.add("is-invalid"), (this.isValid = false));

      if (!this.isValid) {
        return false;
      }

      return true;
    },

    sendIt() {
      if (this.$store.state.isOnline) {
        this.isLoading = true;

        let formData = new FormData();

        formData.append("email", this.inputs.email);
        formData.append("pin", this.inputs.pin);

        Auth.refreshToken(formData)
          .then((r) => this.onSuccess(r))
          .catch((e) => {
            this.onFail(e);
          });
      } else {
        let theMessage = {};
        theMessage.status = 401;
        theMessage.title = "Offline";
        theMessage.subtitle = "Oeps?!";
        theMessage.body = "";
        theMessage.icon = "Whoops";

        this.emitter.emit("toggleOffcanvas", [true, "Whoops", theMessage]);

        this.isDisabled = false;
      }
    },

    onSuccess(r) {
      this.isLoading = false;

      localStorage.removeItem("store");
      localStorage.removeItem("token");
      localStorage.removeItem("concession");

      this.$store.commit({
        type: "clearStore",
        payload: {},
      });

      //wipe all on login
      //check if token is different
      /*if (localStorage.getItem("token") !== r.data.body.api_token) {
        //clear storage if token is changed
        localStorage.removeItem("store");
      localStorage.removeItem("token");
      localStorage.removeItem("concession");
      }*/

      localStorage.setItem("token", r.data.body.api_token);
      localStorage.setItem("concession", r.data.body.concession);

      this.setCaptainDetails(r.data.body.captain_id);

      //open the start
      this.$router.push("/start/1");
    },

    onFail(r) {
      this.isLoading = false;

      if (r.response.status === 404) {
        this.noMatchingCredentials = !false;

        let theMessage = {};
        theMessage.status = 404;
        theMessage.title = "Gebruiker niet gevonden";
        theMessage.subtitle = "Oeps?!";
        theMessage.body = "";
        theMessage.icon = "whoops";

        this.emitter.emit("toggleOffcanvas", [true, "Whoops", theMessage]);
        this.isDisabled = false;
      } else {
        let theMessage = {};
        theMessage.status = 401;
        theMessage.title = "Unauthorized";
        theMessage.subtitle = "Oeps?!";
        theMessage.body = "";
        theMessage.icon = "whoops";

        this.emitter.emit("toggleOffcanvas", [true, "Whoops", theMessage]);
        this.isDisabled = false;
      }
    },

    setCaptainDetails(captainId) {
      Checklists.getCaptain(captainId)
        .then((r) => this.onGetCaptain(r))
        .catch((e) => {
          console.log(e);
        });
    },

    onGetCaptain(r) {
      this.$store.commit({
        type: "setCaptainDetails",
        payload: r.data.body,
      });
    },

    handleKeyevent(e) {
      this.noMatchingCredentials = false;

      this.isDisabled = false;

      e.target.parentNode.classList.remove("is-invalid");
    },
  },
};
