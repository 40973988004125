<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 55.2 52.2"
    xml:space="preserve"
  >
    <g>
      <path
        d="M48.4,8.5L52,4.8l-1.6-1.6L28.3,25.3l-0.1,0.1l1.6,1.6l5.6-5.6c1,1.4,1.6,3,1.6,4.7c0,4.3-3.5,7.8-7.8,7.9 c-0.1-2.2-1.9-4-4.1-4c-0.9,0-1.7,0.3-2.4,0.8c-1-1.4-1.5-3-1.5-4.6c0-4.3,3.5-7.9,7.9-7.9h1.1V16h-1.1C23.5,16,19,20.5,19,26.1 c0,2.3,0.8,4.6,2.3,6.4c-0.2,0.5-0.3,1-0.3,1.6c0,2.3,1.9,4.1,4.1,4.1c1.4,0,2.8-0.8,3.5-2c0.1,0,0.3,0,0.5,0 c5.6,0,10.1-4.5,10.1-10.1c0-2.3-0.8-4.5-2.2-6.3l4.1-4.1c2.5,2.9,3.9,6.6,3.9,10.4C45,34.9,37.9,42,29.1,42 c-8.8,0-15.9-7.1-15.9-15.9c0-2.9,0.8-5.7,2.3-8.2c0.5,0.2,1.1,0.3,1.6,0.3c2.3,0,4.1-1.9,4.1-4.1c0-0.5-0.1-1.1-0.3-1.6 c2.5-1.5,5.3-2.3,8.2-2.3h1.1V8h-1.1c-3.4,0-6.7,1-9.6,2.8C18.8,10.3,18,10,17.1,10c-2.3,0-4.1,1.9-4.1,4.1c0,0.8,0.3,1.7,0.8,2.4 C12,19.4,11,22.7,11,26.1c0,10,8.1,18.1,18.1,18.1s18.1-8.1,18.1-18.1c0-4.4-1.6-8.7-4.6-12l4.1-4.1c4,4.4,6.2,10.1,6.2,16.1 C53,39.3,42.3,50,29.1,50c-5.7,0-11.2-2-15.5-5.7c-4.3-3.7-7.1-8.7-8-14.3c1.6-0.6,2.7-2.1,2.7-3.8s-1.1-3.2-2.7-3.8 c0.9-5.6,3.7-10.6,8-14.3c4.3-3.7,9.8-5.7,15.5-5.7h1.1V0h-1.1c-6.2,0-12.3,2.2-17,6.3C7.4,10.4,4.3,16,3.3,22.1 c-1.9,0.4-3.3,2.1-3.3,4c0,2,1.4,3.7,3.3,4c0.9,6.1,4.1,11.7,8.8,15.8c4.7,4.1,10.8,6.3,17,6.3c14.4,0,26.1-11.7,26.1-26.1 C55.2,19.6,52.8,13.3,48.4,8.5z"
      />
    </g>
  </svg>
</template>
