export default {
  methods: {
    handleDetailClick(malfunction) {
      const viewData = {
        malfunction: malfunction,
        flightInfo: this.$store.state.flightSettings,
      };

      this.emitter.emit("toggleOffcanvas", [
        true,
        "MalfunctionsDetail",
        viewData,
      ]);
    },

    formatDateOfMalfunction(malfunction) {
      let dateStamp = new Date();
      let theDate = malfunction.created_at.substring(
        0,
        malfunction.created_at.length - 8
      );

      return dateStamp == theDate ? "Vandaag" : theDate;
    },

    formatTimeOfMalfunction(malfunction) {
      let theTime = malfunction.created_at.substring(
        10,
        malfunction.created_at.length - 3
      );

      return theTime;
    },
  },
};
