<script>
import "./FlightInsertForm.scss";
import FlightInsertForm from "./FlightInsertForm.js";

export default FlightInsertForm;
</script>

<template>
  <div class="flightInsertForm">
    <transition name="fade-in">
      <div v-if="isLoading" class="flightInsertForm__loader">
        <Loader />
      </div>
    </transition>

    <div class="flightInsertForm__actions">
      <div class="flightInsertForm__theCloser" @click="handleCloseClick">
        <div class="flightInsertForm__theCloser__symbol">
          <Svg-icon iconType="Close"></Svg-icon>
        </div>
      </div>
    </div>

    <div
      :class="[
        'flightInsertForm__inner',
        { 'is-loading': isLoading },
        { 'is-success': isSuccess },
      ]"
    >
      <h3 class="heading heading--4 flightInsertForm__title">Halte invoegen</h3>

      <form class="flightInsertForm__form" novalidate>
        <div class="flightInsertForm__form__field" ref="stop">
          <label class="input__label">Kies halte*</label>

          <div class="flightInsertForm__input__select input__select">
            <span class="flightInsertForm__input__select__symbol">
              <Svg-icon iconType="Anchor-circle"></Svg-icon>
            </span>

            <select v-model="inputFields.stop" @change="handleChangeInput">
              <option disabled value="">Maak uw keuze</option>
              <option
                v-for="stop in filteredStops"
                :value="stop.id"
                :key="stop.id"
              >
                {{ stop.name }}
              </option>
            </select>

            <span class="input__select__symbol">
              <Svg-icon iconType="Triangle"></Svg-icon>
            </span>
          </div>

          <span class="input__error">
            <span class="input__error__symbol">
              <Svg-icon iconType="Warning"></Svg-icon>
            </span>
            <span class="input__error__label">Dit veld is verplicht</span>
          </span>
        </div>

        <div class="flightInsertForm__form__fieldset">
          <label class="input__label">Hoelang bedraagt de vertraging?</label>

          <div class="flightInsertForm__form__field" ref="hours">
            <div class="flightInsertForm__input__wrap">
              <span class="flightInsertForm__input__helper">Uur</span>
              <input
                @input="handleKeyevent"
                v-model="inputFields.hours"
                class="input__field flightInsertForm__input__field"
                type="number"
              />
            </div>
          </div>

          <div class="flightInsertForm__form__field" ref="mins">
            <div class="flightInsertForm__input__wrap">
              <span class="flightInsertForm__input__helper">Min*</span>
              <input
                @input="handleKeyevent"
                v-model="inputFields.mins"
                class="input__field flightInsertForm__input__field"
                type="number"
              />
            </div>

            <span class="input__error">
              <span class="input__error__symbol">
                <Svg-icon iconType="Warning"></Svg-icon>
              </span>
              <span class="input__error__label">Dit veld is verplicht</span>
            </span>
          </div>
        </div>

        <div class="flightInsertForm__form__submit">
          <button
            @click.prevent="handleSubmit"
            :disabled="isInvalid"
            class="button button--brand-2"
            type="sumbit"
          >
            <span class="button__label">Bevestigen</span>
          </button>
        </div>
      </form>
    </div>

    <transition name="fade-in">
      <div v-if="isSuccess" class="flightInsertForm__success">
        <Success
          @emitToggleOffcanvas="emitToggleOffcanvas"
          :viewData="theMessage"
        />
      </div>
    </transition>
  </div>
</template>
