export default {
  data() {
    return {
      isLoading: false,
      showOffcanvas: false,
      activeIndex: -1,
      ghostName: "",
    };
  },

  created() {
    this.emitter.on("flight-insert-form--toggle", (show) => {
      this.toggleShowCanvas(show);
    });

    this.emitter.on("flight-insert-form--changeStop", (name) => {
      this.ghostName = name;
    });
  },

  methods: {
    canInsertHere(index) {
      const currentStop = this.$store.state.currentStops[index];
      const nextStop = this.$store.state.currentStops[index + 1];

      if (nextStop) {
        //check if not last item

        if (
          (currentStop.passed == 1 || currentStop.skipped == 1) &&
          (nextStop.passed == 1 || nextStop.skipped == 1)
        ) {
          //has no stop before or after
          return false;
        }
      }

      return true;
    },
    toggleShowCanvas(show = false) {
      this.showOffcanvas = show;
    },
    beforeEnter: (el) => ((el.style.display = "none"), (el.style.opacity = 0)),

    afterEnter: (el, done) => (
      (el.style.display = "flex"), (el.style.opacity = 1)
    ),

    leave: (el, done) => ((el.style.opacity = 0), done()),

    handleInsertClick(index) {
      this.activeIndex = index;

      this.showOffcanvas = true;
    },

    handleDeleteClick(item, index) {
      const viewData = {
        index: index,
        flightInfo: this.$store.state.flightSettings,
        nextStop: item,
      };

      this.emitter.emit("toggleOffcanvas", [true, "FlightSkipForm", viewData]);
    },
  },
};
