export default {
  data() {
    return {
      isLoading: false,
      isDisabled: true,
      viewData: Object,
      theFlightInfo: Object,
      tracker: {
        activeStep: parseInt(
          this.$route.params.step ? this.$route.params.step : 0
        ),
        steps: ["Situeren", "Opmerkingen", "Bevestigen"],
      },
    };
  },

  created() {
    this.emitter.on(
      "create-malfunction--isDisabled",
      (isDisabled) => (this.isDisabled = isDisabled)
    );

    this.emitter.on("pinIsConfirmed", (isTrue) => isTrue && this.sendIt());
  },

  destroyed() {
    this.$store.commit({
      type: "setNewMalfunction",
      payload: {
        isSending: false,
        isSend: false,
      },
    });
  },

  methods: {
    handlePrevClick() {
      if (this.tracker.activeStep > 0) {
        this.tracker.activeStep =
          parseInt(this.$route.params.step ? this.$route.params.step : 0) - 1;

        if (!isNaN(this.tracker.activeStep) || this.tracker.activeStep < 0) {
          this.$router.push(
            `/create-malfunction/${
              this.tracker.activeStep ? this.tracker.activeStep : ""
            }`
          );
        } else {
          this.$router.push(`/malfunctions`);
        }
      } else {
        //return to dashboard
        this.$router.push(`/dashboard`);
      }
    },
    handleNextClick() {
      this.tracker.activeStep =
        parseInt(this.$route.params.step ? this.$route.params.step : 0) + 1;

      this.$router.push(`/create-malfunction/${this.tracker.activeStep}`);
    },

    handleSubmit() {
      this.isLoading = true;

      this.emitter.emit("toggleOffcanvas", [true, "PinConfirm"]);
    },

    sendIt() {
      const currentInfo = this.$store.state.newMalfunctionSendInfo
        ? this.$store.state.newMalfunctionSendInfo
        : {};

      const needsSending = currentInfo
        ? currentInfo.needsSending
          ? currentInfo.needsSending
          : []
        : [];

      //add to que
      this.$store.commit({
        type: "setNewMalfunctionSendInfo",
        payload: {
          ...currentInfo,
          needsSending: [
            ...needsSending,
            { ...this.$store.state.newMalfunction },
          ],
          updated: true,
        },
      });

      //reset data
      this.$store.commit({
        type: "setNewMalfunction",
        payload: {},
      });

      this.$router.push("/malfunctions");
      //send to app
      this.emitter.emit("app--sendNewMalfunction");
    },

    onSuccess() {
      this.isLoading = false;

      this.$router.push("/malfunctions");
    },

    onFail(response) {
      this.isLoading = false;

      let theMessage = {};
      theMessage.status = response.status;
      theMessage.title = response.data.message;
      theMessage.subtitle = "Oeps?!";
      theMessage.body = "";
      theMessage.icon = "whoops";

      this.emitter.emit("toggleOffcanvas", [true, "Whoops", theMessage]);
    },
  },
  watch: {
    $route() {
      this.tracker.activeStep = parseInt(
        this.$route.params.step ? this.$route.params.step : 0
      );

      this.$router.push(`/create-malfunction/${this.tracker.activeStep}`);
    },
  },
};
