<script>
import "./MalfunctionsSituation.scss";
import MalfunctionsSituation from "./MalfunctionsSituation.js";

export default MalfunctionsSituation;
</script>

<template>
  <div class="malfunctionsSituation">
    <h3 class="heading heading--3 malfunctionsSituation__title">
      Situeer de storing
    </h3>

    <div class="malfunctionsSituation__inner">
      <div class="malfunctionsSituation__slider">
        <div
          v-if="slider.visibleSlides.length"
          class="malfunctionsSituation__actions"
        >
          <div
            class="malfunctionsSituation__actions__prev"
            @click="handleClick('prev')"
          >
            <button
              class="directionalButton"
              :class="!slider.goToPrevSlide && 'is-disabled'"
            >
              <span class="directionalButton__symbol">
                <Svg-icon iconType="Arrow-left" />
              </span>
            </button>
          </div>

          <div
            class="malfunctionsSituation__actions__next"
            @click="handleClick('next')"
          >
            <button
              class="directionalButton"
              :class="!slider.goToNextSlide && 'is-disabled'"
            >
              <span class="directionalButton__symbol">
                <Svg-icon iconType="Arrow-right" />
              </span>
            </button>
          </div>
        </div>

        <div class="malfunctionsSituation__slider__inner">
          <div class="malfunctionsSituation__sliderTrack" ref="sliderTrack">
            <div
              v-for="item in slider.items"
              class="malfunctionsSituation__item"
              ref="item"
              :key="item.id"
            >
              <div class="malfunctionsSituation__item__inner">
                <div class="malfunctionsSituation__item__sizer">
                  <div class="malfunctionsSituation__item__sizerWrap">
                    <div class="malfunctionsSituation__item__content">
                      <input
                        @change="() => handleChange(item)"
                        :id="item.id"
                        :value="item.title"
                        name="malfunctionsSituation"
                        class="malfunctionsSituation__radio"
                        type="radio"
                        :checked="
                          chosenMalfunction
                            ? chosenMalfunction.id === item.id
                            : false
                        "
                      />
                      <label
                        :for="item.id"
                        class="malfunctionsSituation__radioFaker"
                      >
                        <div class="malfunctionsSituation__checked__symbol">
                          <span
                            class="malfunctionsSituation__checked__theSymbol"
                          >
                            <Svg-icon iconType="Check" />
                          </span>
                        </div>

                        <span class="malfunctionsSituation__item__symbol">
                          <Svg-icon :iconType="item.icon" />
                        </span>

                        <span class="malfunctionsSituation__item__label">{{
                          item.title
                        }}</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
