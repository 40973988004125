<script>
import "./Checklist.scss";
import Checklist from "./Checklist.js";

export default Checklist;
</script>

<template>
  <Page-wrapper title="Checklist">
    <section class="checklistPanel">
      <aside class="checklistPanel__aside">
        <div class="checklistPanel__aside__inner">
          <Loader v-if="isLoading" colorMode="dark" />
          <template v-else-if="this.$store.state.checklist">
            <div
              v-if="this.$store.state.checklist.checklist"
              class="checklistPanel__aside__item"
            >
              <span class="checklistPanel__date__nameOfDay">
                <Date dateType="nameOfDay" />
              </span>

              <span class="checklistPanel__date__theDate">
                <Date dateType="DD/MM/YYYY" />
              </span>
            </div>
          </template>
          <div class="checklistPanel__aside__item">
            <Checklist-nav />
          </div>
        </div>
      </aside>

      <article class="checklistPanel__steps">
        <div class="checklistPanel__steps__inner">
          <Loader v-if="isLoading" colorMode="dark" />
          <template v-else-if="this.$store.state.checklist">
            <template v-if="this.$store.state.checklist.checklist">
              <transition name="fade" mode="out-in">
                <Checklist-content v-if="currentStep !== 'overview'" />
              </transition>

              <transition name="fade" mode="out-in">
                <Checklist-overview v-if="currentStep === 'overview'" />
              </transition>

              <transition name="fade" mode="out-in">
                <div v-if="!isLastItem" class="checklistPanel__steps__nextBtn">
                  <button
                    :disabled="isFirstItem"
                    @click="handlePrevClick"
                    class="button button--pairing-brand-2"
                  >
                    <span class="button__symbol reverse">
                      <Svg-icon iconType="Arrow-right" />
                    </span>
                    <span class="button__label reverse">Vorige</span>
                  </button>
                  <button
                    @click="handleNextClick"
                    class="button button--pairing-brand-2"
                    :disabled="nextIsDisabled"
                  >
                    <span class="button__label">Volgende</span>
                    <span class="button__symbol">
                      <Svg-icon iconType="Arrow-right" />
                    </span>
                  </button>
                </div>

                <div v-else class="checklistPanel__steps__readyBtn">
                  <button
                    v-if="
                      ($store.state.checklistIsSend ||
                        $store.state.checklistIsSending ||
                        (!$store.state.isOnline &&
                          $store.state.checklistIsUpdated)) &&
                      !this.$route.query.end
                    "
                    @click.prevent="handleGoToSubmit"
                    class="button button--bottom button--pairing-positive"
                  >
                    <span class="button__label">Terug naar dashboard</span>
                    <span class="button__symbol">
                      <Svg-icon iconType="Arrow-right" />
                    </span>
                  </button>
                  <button
                    v-else-if="!this.$route.query.end"
                    @click="handleSubmit"
                    class="button button--bottom button--pairing-positive"
                    :disabled="!checklistReady"
                  >
                    <span class="button__label">Klaar voor vertrek</span>
                    <span class="button__symbol">
                      <Svg-icon iconType="Arrow-right" />
                    </span>
                  </button>

                  <button
                    v-else
                    @click="handleSubmitAndLogout"
                    class="button button--bottom button--pairing-positive"
                    :disabled="!checklistReady"
                  >
                    <span class="button__label">Checklist opslaan</span>
                    <span class="button__symbol">
                      <Svg-icon iconType="Arrow-right" />
                    </span>
                  </button>
                </div>
              </transition>
            </template>
          </template>
        </div>
      </article>
    </section>
  </Page-wrapper>
</template>
