<script>
import "./Widgets.scss";
import Widgets from "./Widgets.js";

export default Widgets;
</script>

<template>
  <div class="widgets">
    <div class="widgets__inner">
      <router-link
        :to="checkStopsSend ? `/flight-overview` : `/flight`"
        :class="[
          'widgets__item',
          {
            'is-completed': checkStopsSend,
          },
        ]"
      >
        <div class="widgets__item__inner">
          <div class="widgets__item__sizer">
            <div class="widgets__item__sizerBox">
              <div v-if="checkStopsSend" class="widgets__item__symbol">
                <span class="widgets__item__theSymbol">
                  <Svg-icon iconType="Check"></Svg-icon>
                </span>
              </div>

              <div class="widgets__content">
                <span class="widgets__content__symbol">
                  <Svg-icon iconType="Ship-icon"></Svg-icon>
                </span>
                <span class="widgets__content__title" v-if="!isStarted()">
                  Start vaart
                </span>
                <span class="widgets__content__title" v-if="isStarted()">
                  Huidige vaart
                </span>
              </div>
            </div>
          </div>
        </div>
      </router-link>

      <router-link :to="`/malfunctions`" class="widgets__item">
        <div class="widgets__item__inner">
          <div class="widgets__item__sizer">
            <div class="widgets__item__sizerBox">
              <div class="widgets__content">
                <div
                  v-if="$store.state.malfunctions.length"
                  :class="[
                    'widgets__item__symbol',
                    { 'is-active': $store.state.malfunctions.length },
                  ]"
                >
                  <span class="widgets__item__count">
                    {{ $store.state.malfunctions.length }}
                  </span>
                </div>

                <span class="widgets__content__symbol">
                  <Svg-icon iconType="Megaphone"></Svg-icon>
                </span>

                <span class="widgets__content__title"> Storingen </span>
              </div>
            </div>
          </div>
        </div>
      </router-link>

      <router-link :to="`/notifications`" class="widgets__item">
        <div class="widgets__item__inner">
          <div class="widgets__item__sizer">
            <div class="widgets__item__sizerBox">
              <div
                v-if="
                  $store.state.notifications.length -
                    $store.state.readNotifications.length >
                  0
                "
                :class="[
                  'widgets__item__symbol',
                  {
                    'is-active':
                      $store.state.notifications.length -
                      $store.state.readNotifications.length,
                  },
                ]"
              >
                <span class="widgets__item__count">
                  {{
                    $store.state.notifications.length -
                    $store.state.readNotifications.length
                  }}
                </span>
              </div>

              <div class="widgets__content">
                <span class="widgets__content__symbol">
                  <Svg-icon iconType="Notifications-icon"></Svg-icon>
                </span>

                <span class="widgets__content__title"> Meldingen </span>
              </div>
            </div>
          </div>
        </div>
      </router-link>

      <router-link
        @click="handleChecklistClick"
        :to="`/checklist/overview`"
        :class="[
          'widgets__item',
          {
            'is-completed': $store.state.checklistIsSend,
          },
        ]"
        :disabled="checkChecklistExists"
      >
        <div class="widgets__item__inner">
          <div class="widgets__item__sizer">
            <div class="widgets__item__sizerBox">
              <div
                v-if="
                  $store.state.checklistIsSend ||
                  $store.state.checklistIsSending
                "
                class="widgets__item__symbol"
              >
                <span class="widgets__item__theSymbol">
                  <Svg-icon iconType="Check"></Svg-icon>
                </span>
              </div>

              <div class="widgets__content">
                <span class="widgets__content__symbol">
                  <Svg-icon iconType="Checklist-icon"></Svg-icon>
                </span>

                <span class="widgets__content__title"> Checklist </span>
              </div>
            </div>
          </div>
        </div>
      </router-link>

      <router-link :to="`/help`" class="widgets__item">
        <div class="widgets__item__inner">
          <div class="widgets__item__sizer">
            <div class="widgets__item__sizerBox">
              <div class="widgets__content">
                <span class="widgets__content__symbol">
                  <Svg-icon iconType="Lifebuoy"></Svg-icon>
                </span>

                <span class="widgets__content__title">
                  Help &amp; Contact
                </span>
              </div>
            </div>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>
