import axios from "./index";

export default {
  pass(formData) {
    return axios.post(`/items/pass`, formData);
  },

  skip(formData) {
    return axios.post("/items/skip", formData);
  },

  insert(formData) {
    return axios.post("/items/insert", formData);
  },
  passMutiple(formData) {
    return axios.post("/items/passmultiple", formData, {
      headers: {
        Concession: localStorage.getItem("concession") ?? "2",
      },
    });
  },
};
