import Flights from "../../../api/Flights";
import TimeStamp from "../../../functions/TimeStamp";

export default {
  data() {
    return {
      newNotification: [],
      timeRemaining: null,
      previousStop: this.$store.state.previousStop,
      nextStop: this.$store.state.nextStop,
      theSecondStop: false,
    };
  },

  created() {
    if (!this.$store.state.currentStops.length) {
      this.getStops();
    }
  },

  methods: {
    getStops() {
      if (this.$store.state.flightSettings.id) {
        const formData = new FormData();

        formData.append("flight", this.$store.state.flightSettings.id);

        Flights.detail(this.$store.state.flightSettings.id, this).then((r) => {
          //got the stops
          this.$store.commit({
            type: "setCurrentStops",
            payload: r.data.body.all_items.sort((a, b) => {
              if (a.departure > b.departure) {
                return 1;
              }

              if (a.departure < b.departure) {
                return -1;
              }

              return 0;
            }),
          });

          console.log(r.data.body.all_items);
        });
      }
    },

    handleStopClick() {
      this.emitter.emit("toggleOffcanvas", [true, "FlightStopConfirm"]);
    },

    handleShiftClick() {},

    handleNotificationsClick() {
      this.$router.push("/notifications");
    },

    handlePassengersClick() {
      let nextStopIndex = this.$store.state.currentStopIndex;

      if (nextStopIndex < 0) {
        nextStopIndex = 0;
      }

      const viewData = {
        timeStamp: TimeStamp.getTime(),
        flightInfo: this.$store.state.flightInfo,
        nextStop: this.$store.state.currentStops[nextStopIndex],
        stayOnPage: true,
      };

      this.emitter.emit("toggleOffcanvas", [true, "PassengersForm", viewData]);
    },

    getRemainingTime() {
      if (!this.secondStop) {
        this.waitForIt.time = true;
        return false;
      }

      // TIME OF DEPARTURE SECOND STOP - CURRENT TIME
      setInterval(
        function () {
          let self = this;

          const today = new Date();
          let timeToDepartForSecondStop = new Date();
          let [hh, mm, ss] = this.nextStop.departure.split(":");

          timeToDepartForSecondStop.setHours(hh);
          timeToDepartForSecondStop.setMinutes(mm);
          timeToDepartForSecondStop.setSeconds(ss);

          let diff = timeToDepartForSecondStop.getTime() - today.getTime();

          let hours = Math.floor(
            (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          diff -= hours * (1000 * 60 * 60);
          hours = hours.toString().replace(/[a-z-]/g, "");
          hours < 10 && (hours = "0" + hours);

          let mins = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
          diff -= mins * (1000 * 60);
          mins = mins.toString().replace(/[a-z-]/g, "");
          mins < 10 && (mins = "0" + mins);

          let seconds = Math.floor((diff % (1000 * 60)) / 1000);
          seconds < 10 && (seconds = "0" + seconds);
          seconds = seconds.toString().replace(/[a-z-]/g, "");

          this.timeRemaining =
            hours == "00" && mins == "00" && `${mins}:${seconds}`;
          this.timeRemaining =
            hours == "00" && mins != "00" && `${mins}:${seconds}`;
          this.timeRemaining =
            hours != "00" && mins != "00" && `${hours}:${mins}`;

          this.waitForIt.time = true;
        }.bind(this),
        1000
      );
    },
  },
  computed: {
    hasStops() {
      return (
        this.$store.state.currentStops.filter((stop) =>
          stop ? !stop.skipped : false
        ).length > 0
      );
    },
    currentPeople() {
      const adding_people = isNaN(this.$store.state.passengers.adding_people)
        ? 0
        : this.$store.state.passengers.adding_people;
      const removing_people = isNaN(
        this.$store.state.passengers.removing_people
      )
        ? 0
        : this.$store.state.passengers.removing_people;

      return (
        this.$store.state.passengers.current_people +
        adding_people +
        removing_people
      );
    },

    currentBikes() {
      const adding_bikes = isNaN(this.$store.state.passengers.adding_bikes)
        ? 0
        : this.$store.state.passengers.adding_bikes;

      const removing_bikes = isNaN(this.$store.state.passengers.removing_bikes)
        ? 0
        : this.$store.state.passengers.removing_bikes;

      return (
        this.$store.state.passengers.current_bikes +
        adding_bikes +
        removing_bikes
      );
    },
    passagiersTitle() {
      const lastStop =
        this.$store.state.currentStops[this.$store.state.currentStopIndex];

      return lastStop
        ? `passagiers van ${lastStop.stop.name}`
        : "Huidige passagiers";
    },
  },
  watch: {
    "$store.state.notifications"() {
      this.newNotification = this.$store.state.notifications.filter((note) => {
        let notPresent = true;

        this.$store.state.readNotifications.forEach((readNote) => {
          if (readNote.id === note.id) {
            notPresent = false;
          }
        });

        return notPresent;
      });

      if (this.newNotification.length && this.$store.state.soundMp3) {
        this.$store.state.soundMp3.play();
      }
    },
  },
};
