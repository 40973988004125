export default {
  data() {
    return {
      timestamp: "",
    };
  },

  created() {
    this.getTime();
    setInterval(this.getTime, 1000);
  },

  methods: {
    getTime() {
      function addZero(i) {
        i < 10 && (i = "0" + i);
        return i;
      }

      const today = new Date();
      const time =
        addZero(today.getHours()) + ":" + addZero(today.getMinutes());

      this.timestamp = time;
    },
  },
};
