export default {
  data() {
    return {
      noCheckList: true,
    };
  },

  created() {
    //get notifications and malfunctions
    setTimeout(() => {
      this.emitter.emit("app--getNotifications");
    }, 50);
    setTimeout(() => {
      this.emitter.emit("app--getMalfunctions");
    }, 100);
  },

  computed: {
    hasStartChecklist() {
      if (this.$store.state.flightSettings.shift) {
        return this.$store.state.flightSettings.shift.has_start_checklist;
      }

      return false;
    },
    checkCompleted() {
      return (
        this.$store.state.currentStops.filter((stop) =>
          stop ? stop.passed || stop.skipped : true
        ).length >= this.$store.state.currentStops.length &&
        this.$store.state.currentStops.length > 0
      );
    },

    checkStopsSend() {
      return this.$store.state.currentStopsSendInfo.isSend;
    },

    checkChecklistExists() {
      const shift = this.$store.state.flightSettings.shift;

      if (shift) {
        return !(
          shift.has_start_checklist ||
          (this.$store.state.isCompleted && shift.has_end_checklist)
        );
      }
      return true;
    },
  },
  methods: {
    handleChecklistClick(e) {
      e.preventDefault();

      if (this.$store.state.checklist) {
        this.$router.push("/checklist/overview");
      } else {
        this.$router.push("/checklist");
      }
    },
    isStarted() {
      return this.$store.state.currentStopIndex > 0;
    },
  },
};
