<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 51.7 51.8"
    xml:space="preserve"
  >
    <g>
      <path
        d="M4.5,25.9L4.5,25.9c0,1.4,0.1,2.8,0.4,4.2C5,30.6,5.4,31,6,31c0.1,0,0.1,0,0.2,0 c0.6-0.1,1-0.7,0.9-1.3c-0.2-1.2-0.4-2.5-0.4-3.7c0-1.3,0.1-2.5,0.4-3.8c0.1-0.6-0.3-1.2-0.9-1.3C5.6,20.6,5,21,4.9,21.6 C4.6,23,4.5,24.5,4.5,25.9L4.5,25.9z"
      />
      <path
        d="M29.6,44.7c-2.5,0.5-5.1,0.5-7.5,0c-0.6-0.1-1.2,0.3-1.3,0.9c-0.1,0.6,0.3,1.2,0.9,1.3 c2.8,0.6,5.6,0.6,8.4,0c0.3-0.1,0.6-0.2,0.7-0.5c0.2-0.3,0.2-0.6,0.2-0.9c-0.1-0.3-0.2-0.6-0.5-0.7C30.2,44.7,29.9,44.6,29.6,44.7 L29.6,44.7z"
      />
      <path
        d="M47.3,25.9L47.3,25.9c0-1.4-0.1-2.8-0.4-4.1c-0.1-0.6-0.7-1-1.3-0.9c-0.6,0.1-1,0.7-0.9,1.3 c0.2,1.2,0.4,2.5,0.4,3.7v0c0,1.3-0.1,2.5-0.4,3.8c-0.1,0.3,0,0.6,0.2,0.8c0.2,0.2,0.4,0.4,0.7,0.5c0.1,0,0.1,0,0.2,0 c0.5,0,1-0.4,1.1-0.9C47.1,28.7,47.3,27.3,47.3,25.9L47.3,25.9z"
      />
      <path
        d="M22.1,7.1c1.2-0.2,2.5-0.4,3.7-0.4h0c1.3,0,2.5,0.1,3.8,0.4c0.1,0,0.1,0,0.2,0 c0.6,0,1.1-0.4,1.1-1c0.1-0.6-0.3-1.1-0.9-1.2c-1.4-0.3-2.8-0.4-4.3-0.4h0c-1.4,0-2.8,0.1-4.1,0.4c-0.6,0.1-1,0.7-0.9,1.3 C21,6.8,21.5,7.2,22.1,7.1L22.1,7.1z"
      />
      <path
        d="M3.8,47c1.5,1.6,3.5,2.5,5.7,2.5c0.4,0,0.7,0,1.1-0.1l3.4-0.5h0c0,0,0.1,0,0.1,0 c7.4,3.8,16.1,3.8,23.5,0c0,0,0.1,0,0.1,0l3.4,0.5c0.4,0.1,0.7,0.1,1.1,0.1c2.3,0,4.6-1.1,6.1-2.9c1.5-1.8,2.1-4.2,1.7-6.5 l-0.6-3.4h0c3.2-6.9,3.2-14.8,0-21.7l0.6-3.4h0c0.5-2.5-0.3-5.1-2-6.9c-1.7-1.8-4.3-2.7-6.8-2.4l-3.4,0.5c0,0,0,0-0.1,0h0 c-7.4-3.8-16.2-3.8-23.6,0c0,0,0,0-0.1,0l-3.4-0.5l0,0C8,2,5.5,2.9,3.8,4.8c-1.7,1.8-2.5,4.4-2,6.9L2.4,15 c-3.2,6.9-3.2,14.8,0,21.7l-0.6,3.4C1.2,42.6,2,45.2,3.8,47L3.8,47z M18.8,14.4c-1.8,1.1-3.3,2.6-4.4,4.4l-9-4.7 c2.1-3.6,5.1-6.6,8.7-8.7L18.8,14.4z M37.7,5.4c3.6,2.1,6.6,5,8.7,8.7l-9,4.7c-1.1-1.8-2.6-3.3-4.4-4.4L37.7,5.4z M33,37.4 c1.8-1.1,3.3-2.6,4.4-4.4l9,4.7c-2.1,3.6-5.1,6.6-8.7,8.7L33,37.4z M33.8,17.9L33.8,17.9c2.5,2.5,3.7,6,3.2,9.4 c-0.5,3.5-2.5,6.5-5.5,8.3c-3,1.8-6.7,2-10,0.7c-3.2-1.3-5.7-4.1-6.6-7.5c-0.9-3.4-0.2-7,1.9-9.8c2.1-2.8,5.4-4.4,9-4.4 C28.9,14.6,31.7,15.8,33.8,17.9L33.8,17.9z M16.3,35.4c0.7,0.7,1.6,1.4,2.5,1.9l-4.7,9c-3.6-2.1-6.6-5-8.7-8.7l9-4.7h0 C14.9,33.9,15.6,34.7,16.3,35.4L16.3,35.4z M10.3,47.2c-1.8,0.2-3.6-0.4-4.9-1.7c-1.2-1.3-1.8-3.1-1.5-4.9l0.1-0.7 c1.8,2.8,4.1,5.3,6.9,7.2L10.3,47.2z M16.1,47.4l4.7-9c3.3,1.3,6.9,1.3,10.1,0l4.7,9C29.4,50.2,22.3,50.2,16.1,47.4L16.1,47.4z M46.4,45.5L46.4,45.5c-1.2,1.3-3.1,2-4.9,1.7l-0.7-0.1l0,0c2.8-1.9,5.1-4.3,6.9-7.2l0.1,0.7v0C48.2,42.3,47.6,44.1,46.4,45.5 L46.4,45.5z M47.4,35.7l-9-4.7c1.3-3.3,1.3-6.9,0-10.1l9-4.7C50.2,22.3,50.2,29.5,47.4,35.7L47.4,35.7z M41.5,4.6 c1.8-0.2,3.6,0.4,4.8,1.7c1.2,1.3,1.8,3.1,1.5,4.9l-0.1,0.7c-1.8-2.8-4.1-5.3-6.9-7.2L41.5,4.6z M35.7,4.4l-4.7,9 c-3.3-1.3-6.9-1.3-10.1,0l-4.7-9C22.3,1.6,29.4,1.6,35.7,4.4L35.7,4.4z M5.4,6.3c1.2-1.3,3.1-2,4.9-1.7L11,4.7l0,0 c-2.8,1.9-5.1,4.3-6.9,7.2l-0.1-0.7C3.6,9.5,4.1,7.6,5.4,6.3L5.4,6.3z M4.4,16.1l9,4.7h0c-1.3,3.3-1.3,6.9,0,10.1l-9,4.7h0 C1.5,29.5,1.5,22.3,4.4,16.1L4.4,16.1z"
      />
      <path
        d="M19.4,24c0.2-0.6-0.2-1.2-0.8-1.4c-0.6-0.2-1.2,0.2-1.4,0.8c-0.4,1.3-0.5,2.6-0.3,3.9 c0.1,0.6,0.6,1,1.1,1c0.1,0,0.1,0,0.2,0c0.3,0,0.6-0.2,0.7-0.4c0.2-0.2,0.3-0.5,0.2-0.8c0-0.3-0.1-0.6-0.1-1 C19.1,25.2,19.2,24.6,19.4,24L19.4,24z"
      />
      <path
        d="M24.9,32.6c-1.4-0.2-2.7-0.9-3.8-1.9c-0.4-0.4-1.1-0.4-1.6,0c-0.4,0.4-0.4,1.1,0,1.6 c1.4,1.3,3.1,2.2,5,2.5c0.1,0,0.1,0,0.2,0c0.6,0,1.1-0.5,1.1-1C25.9,33.2,25.5,32.6,24.9,32.6L24.9,32.6z"
      />
    </g>
  </svg>
</template>
