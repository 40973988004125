import Captains from "../../../api/Captains";

export default {
  data() {
    return {
      isLoading: true,
      pin: "",
      isDisabled: true,
      isInvalid: false,
    };
  },

  mounted() {
    this.isLoading = false;
  },

  methods: {
    handleKeyClick(key) {
      this.isInvalid = false;

      if (this.pin.length == 3) this.isDisabled = false;

      if (this.pin.length == 4 && key != 10 && key != 11) return false;

      switch (key) {
        case 10:
          this.pin = "";
          break;
        case 11:
          this.pin = this.pin.slice(0, -1);
          break;
        default:
          this.pin = this.pin + key;
          break;
      }
    },

    handleSubmit(e) {
      this.isLoading = true;
      this.isDisabled = true;

      e.preventDefault();

      let formData = new FormData();

      formData.append("pin", this.pin);

      Captains.checkPin(formData).then((response) =>
        response.status !== 200
          ? this.onFail(response)
          : this.onSuccess(response)
      );
    },

    onSuccess(response) {
      this.emitter.emit("toggleOffcanvas", [false]);

      this.emitter.emit("pinIsConfirmed", true);
    },

    onFail(response) {
      this.isLoading = false;
      this.isDisabled = false;

      this.isInvalid = true;
    },
  },
};
