<script>
import "./Dashboard.scss";
import Dashboard from "./Dashboard.js";

export default Dashboard;
</script>

<template>
  <Page-wrapper title="Dashboard">
    <div class="dashboardPanel">
      <transition name="fade" mode="out-in">
        <div v-if="isLoading" class="dashboardPanel__loader">
          <Loader />
        </div>

        <div v-else class="dashboardPanel__inner">
          <section class="dashboardPanel__overview">
            <div class="dashboardPanel__overview__inner">
              <Side-info />

              <div class="dashboardPanel__overview__action">
                <router-link
                  to="/create-malfunction/"
                  class="button button--brand-2"
                >
                  <span class="button__symbol">
                    <Svg-icon iconType="Megaphone-flat" />
                  </span>
                  <span class="button__label">Storing versturen</span>
                </router-link>
              </div>
            </div>
          </section>

          <section class="dashboardPanel__widgets">
            <div class="dashboardPanel__widgets__inner">
              <Widgets />
            </div>
          </section>
        </div>
      </transition>
    </div>
  </Page-wrapper>
</template>
