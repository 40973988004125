export default {
  data() {
    return {
      photoEl: null,
      inputFields: {
        remarks: "",
        image: null,
      },
      isInvalid: false,
    };
  },

  watch: {
    "inputFields.remarks"() {
      // enable next button
      this.emitter.emit(
        "create-malfunction--isDisabled",
        this.inputFields.remarks ? false : true
      );

      this.$store.commit({
        type: "setNewMalfunction",
        payload: {
          ...this.$store.state.newMalfunction,
          remarks: this.inputFields.remarks,
        },
      });
    },

    "$store.state.tmpImg"() {
      this.$store.commit({
        type: "setNewMalfunction",
        payload: {
          ...this.$store.state.newMalfunction,
          image: this.$store.state.tmpImg,
        },
      });
    },
  },

  created() {
    this.$store.commit({
      type: "setTmpImg",
      payload: null,
    });

    if (this.$store.state.newMalfunction) {
      this.inputFields.remarks = this.$store.state.newMalfunction.remarks;
    }

    this.photoEl = this.$refs.photo;

    // handle file upload
    this.emitter.on("fileIsUploaded", (isFileUploaded) => {
      if (!isFileUploaded) return false;

      this.$nextTick(() => this.onPhotoIsUploaded());
    });

    // enable next button
    this.emitter.emit(
      "create-malfunction--isDisabled",
      this.inputFields.remarks ? false : true
    );
  },

  methods: {
    handlePhotoClick() {
      this.emitter.emit("openCamera", true);
    },

    onPhotoIsUploaded() {
      // $store.file is set on camera
      this.inputFields.image = this.$store.state.tmpImg;
      this.photoEl && (this.photoEl.src = this.$store.state.tmpImg);
    },

    handleDeleteClick() {
      this.inputFields.image = null;
      this.$store.state.tmpImg = null;
    },
  },
};
