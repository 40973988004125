const importAllVue = (app) => {
  	app.component("Anchor-circle", require("./anchor--circle.vue").default); 
	app.component("Anchor-flat", require("./anchor--flat.vue").default); 
	app.component("Anchor", require("./anchor.vue").default); 
	app.component("Arrow-left", require("./arrow--left.vue").default); 
	app.component("Arrow-right", require("./arrow--right.vue").default); 
	app.component("Bike-flat", require("./bike--flat.vue").default); 
	app.component("Bike", require("./bike.vue").default); 
	app.component("Broken-wheels", require("./brokenWheels.vue").default); 
	app.component("Calendar-flat", require("./calendar--flat.vue").default); 
	app.component("Calendar", require("./calendar.vue").default); 
	app.component("Camera-icon", require("./camera--icon.vue").default); 
	app.component("Check-circle", require("./check--circle.vue").default); 
	app.component("Check", require("./check.vue").default); 
	app.component("Checklist-flat", require("./checklist--flat.vue").default); 
	app.component("Checklist-icon", require("./checklist--icon.vue").default); 
	app.component("Clear-mobile", require("./clearMobile.vue").default); 
	app.component("Close", require("./close.vue").default); 
	app.component("Dashboard-icon", require("./dashboard-icon.vue").default); 
	app.component("Delete-mobile", require("./deleteMobile.vue").default); 
	app.component("Goal", require("./goal.vue").default); 
	app.component("Group-add", require("./group--add.vue").default); 
	app.component("Group-remove", require("./group--remove.vue").default); 
	app.component("Insert", require("./insert.vue").default); 
	app.component("Lifebuoy-flat", require("./lifebuoy--flat.vue").default); 
	app.component("Lifebuoy", require("./lifebuoy.vue").default); 
	app.component("Logo", require("./logo.vue").default); 
	app.component("Logout-icon", require("./logout-icon.vue").default); 
	app.component("Megaphone-flat", require("./megaphone--flat.vue").default); 
	app.component("Megaphone", require("./megaphone.vue").default); 
	app.component("Min-circle", require("./min--circle.vue").default); 
	app.component("Next-stop", require("./nextStop.vue").default); 
	app.component("Notifications-flat", require("./notifications--flat.vue").default); 
	app.component("Notifications-icon", require("./Notifications--icon.vue").default); 
	app.component("Offline-icon", require("./Offline-icon.vue").default); 
	app.component("Oops", require("./oops.vue").default); 
	app.component("Passengers-flat", require("./passengers--flat.vue").default); 
	app.component("Passengers", require("./passengers.vue").default); 
	app.component("Pencil", require("./pencil.vue").default); 
	app.component("Person-added", require("./person--added.vue").default); 
	app.component("Person-flat", require("./person--flat.vue").default); 
	app.component("Phone", require("./phone.vue").default); 
	app.component("Pin-send", require("./pinSend.vue").default); 
	app.component("Plus-circle", require("./plus--circle.vue").default); 
	app.component("Plus-min-circle", require("./plusMin--circle.vue").default); 
	app.component("Power-off", require("./power--off.vue").default); 
	app.component("Radar-flat", require("./radar--flat.vue").default); 
	app.component("Radar", require("./radar.vue").default); 
	app.component("Schedule", require("./schedule.vue").default); 
	app.component("Sender", require("./sender.vue").default); 
	app.component("Settings-flat", require("./settings--flat.vue").default); 
	app.component("Settings", require("./settings.vue").default); 
	app.component("Ship-front", require("./ship--front.vue").default); 
	app.component("Ship-icon", require("./ship-icon.vue").default); 
	app.component("Spyglass", require("./spyglass.vue").default); 
	app.component("Track", require("./track.vue").default); 
	app.component("Trash", require("./trash.vue").default); 
	app.component("Triangle", require("./triangle.vue").default); 
	app.component("View-icon", require("./view-icon.vue").default); 
	app.component("Warning", require("./warning.vue").default); 
	app.component("Weather-broken-clouds", require("./weather--brokenClouds.vue").default); 
	app.component("Weather-clear-sky", require("./weather--clearSky.vue").default); 
	app.component("Weather-few-clouds", require("./weather--fewClouds.vue").default); 
	app.component("Weather-mist", require("./weather--mist.vue").default); 
	app.component("Weather-rain", require("./weather--rain.vue").default); 
	app.component("Weather-scattered-clouds", require("./weather--scatteredClouds.vue").default); 
	app.component("Weather-shower-rain", require("./weather--showerRain.vue").default); 
	app.component("Weather-snow", require("./weather--snow.vue").default); 
	app.component("Weather-thunderstorm", require("./weather--thunderstorm.vue").default); 
	app.component("Wheel", require("./wheel.vue").default); 
;
};

export default importAllVue;
