export default {
  data() {
    return {
      isOpen: false,
    };
  },
  created() {
    this.emitter.on("openCamera", (toggle) => {
      if (toggle && this.hasGetUserMedia()) {
        this.handleInputClick();
      }
    });
  },

  methods: {
    hasGetUserMedia() {
      return !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
    },

    handleInputClick() {
      //cech if not open yet
      if (!this.isOpen) {
        let theInput = this.$refs.theInput;

        if (theInput) {
          theInput.click();
        }
        this.isOpen = true;
      }
    },

    onInputChange(e) {
      const theFile = e.target.files[0];

      if (theFile.type.match(/^image\//)) {
        this.toBase64(theFile).then((img) => {
          this.$store.commit({
            type: "setTmpImg",
            payload: img,
          });

          this.emitter.emit("fileIsUploaded", true);
        });
      }

      this.isOpen = false;
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
  },
};
