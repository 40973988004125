<template>
  <svg width="28px" height="31px" viewBox="0 0 28 31" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
      <polygon id="path-1" points="10.2677231 0 10.2677231 7.78916987 26.5116272 7.78916987 26.5116272 18.441218 0 18.441218 0 0"></polygon>
    </defs>
    <g id="Login" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Group-23-Copy" transform="translate(0.000000, 3.000000)">
        <g id="Group-43" transform="translate(0.086891, 8.788949)">
          <mask id="mask-2" fill="white">
            <use xlink:href="#path-1"></use>
          </mask>
          <g id="Path-2"></g>
          <path d="M23.8219088,13.4688054 L8.14030883,13.4688054 C5.25340598,13.4688054 2.91310883,11.1285083 2.91310883,8.24160543 C2.91310883,5.35470259 5.25340598,3.01440543 8.14030883,3.01440543 L20.8519088,3.01440543" id="Path" stroke="#5B0B17" stroke-width="2.2869" stroke-dasharray="3.593699741363525" mask="url(#mask-2)"></path>
        </g>
        <path d="M5.44753548,1.34895484 L21.2628244,1.34895484 C23.577105,1.34895484 26.265,3.68925199 26.265,6.57615484 C26.265,9.46305768 23.577105,11.8033548 21.2628244,11.8033548 L19.2314082,11.8033548" id="Path" stroke="#FFFFFF" stroke-width="2.2869"></path>
        <circle id="Oval" stroke="#FFFFFF" stroke-width="2.2869" fill="#BC0D26" cx="5.78477419" cy="1.34895484" r="2.49240484"></circle>
        <circle id="Oval-Copy" stroke="#5B0B17" stroke-width="2.2869" fill="#BC0D26" cx="21.6349935" cy="22.0891355" r="2.49240484"></circle>
        <g id="np_cross_2120431_000000" transform="translate(11.362684, 8.768206)" fill="#FFFFFF" fill-rule="nonzero" stroke="#FFFFFF" stroke-width="0.6">
          <path d="M3.3721048,2.00754299 L5.15996694,0.219680845 C5.77099023,-0.391342448 6.69901082,0.544160572 6.08795557,1.15520943 L4.30756948,2.93559552 L6.08795557,4.72345766 C6.69897887,5.33448096 5.77121388,6.26250154 5.15996694,5.6514463 L3.3721048,3.8710602 L1.5917187,5.6514463 C0.980695407,6.26246959 0.0451987764,5.3347046 0.663730069,4.72345766 L2.44411616,2.93559552 L0.656254019,1.15520943 C0.0452307254,0.544186131 0.980733746,-0.391310499 1.5917826,0.219680845 L3.3721048,2.00754299 Z" id="Path"></path>
        </g>
      </g>
    </g>
  </svg>
</template>