<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 14.4 14.4"
    xml:space="preserve"
  >
    <g>
      <path
        d="M14.4,7.2c0-3.9-3.2-7.2-7.2-7.2C3.3,0,0,3.2,0,7.2c0,3.9,3.2,7.2,7.2,7.2C11.1,14.4,14.4,11.1,14.4,7.2z M5.9,9.5L4,7.6C3.5,7.2,3.5,6.5,4,6s1.1-0.5,1.6,0l1.1,1.1l2.2-2.2c0.5-0.5,1.1-0.5,1.6,0s0.5,1.1,0,1.6l-3,3 C7.3,9.7,7,9.8,6.7,9.8C6.3,9.8,6.1,9.7,5.9,9.5L5.9,9.5z"
      />
    </g>
  </svg>
</template>
