export default {
  data() {
    return {
      chosenMalfunction: null,
      theSituations: [
        { id: 1, title: "Defect melden", icon: "Broken-wheels" },
        { id: 2, title: "Storing op traject", icon: "Radar" },
        { id: 3, title: "Overige...", icon: "Checklist-icon" },
      ],
      slider: {
        items: null,
        dir: null,
        trackOffset: 0,
        goToPrevSlide: false,
        goToNextSlide: true,
        visibleSlides: [],
      },
    };
  },

  created() {
    this.createSlider();

    //set initvalue if present
    if (this.$store.state.newMalfunction) {
      this.chosenMalfunction = this.$store.state.newMalfunction.situation;
      // enable next button
      this.emitter.emit("create-malfunction--isDisabled", false);
    } else {
      // enable next button
      this.emitter.emit("create-malfunction--isDisabled", true);
    }
  },

  methods: {
    handleChange(situation) {
      this.$store.commit({
        type: "setNewMalfunction",
        payload: {
          ...this.$store.state.newMalfunction,
          situation: { ...situation },
        },
      });

      this.emitter.emit("create-malfunction--isDisabled", false);
    },

    handleClick(dir) {
      this.dir = dir;

      // check if there's a next/prev slide
      if (
        this.dir == "next" &&
        this.$refs.item[this.slider.visibleSlides[2]].nextElementSibling
      ) {
        // add the next slide
        this.slider.visibleSlides.push(this.slider.visibleSlides[2] + 1);

        // remove first child from the visibleslides array
        this.slider.visibleSlides.shift();

        this.slideIt();
      }

      if (
        this.dir == "prev" &&
        this.$refs.item[this.slider.visibleSlides[0]].previousElementSibling
      ) {
        // add the prev slide
        this.slider.visibleSlides.unshift(this.slider.visibleSlides[0] - 1);

        // remove last child from the visibleslides array
        this.slider.visibleSlides.pop();

        this.slideIt();
      }
    },

    createSlider() {
      this.slider.items = this.theSituations;

      // set visible slides
      this.slider.items.length > 3 &&
        this.slider.items.forEach(
          (slide, index) => index <= 2 && this.slider.visibleSlides.push(index)
        );
    },

    slideIt() {
      let theTrack = this.$refs.sliderTrack;

      // get width of slide
      let theWidth = this.$refs.item[0].clientWidth;

      // go left or right
      this.dir == "next" &&
        (this.slider.trackOffset = this.slider.trackOffset - theWidth);
      this.dir == "prev" &&
        (this.slider.trackOffset = this.slider.trackOffset + theWidth);

      theTrack.style.left = this.slider.trackOffset + "px";

      // check prev/next btn
      this.checkControls();
    },

    checkControls() {
      this.dir == "next" &&
        !this.$refs.item[this.slider.visibleSlides[2]].nextElementSibling &&
        (this.slider.goToNextSlide = false);
      this.dir == "next" &&
        this.$refs.item[this.slider.visibleSlides[0]].previousElementSibling &&
        (this.slider.goToPrevSlide = true);

      this.dir == "prev" &&
        this.$refs.item[this.slider.visibleSlides[2]].nextElementSibling &&
        (this.slider.goToNextSlide = true);
      this.dir == "prev" &&
        !this.$refs.item[this.slider.visibleSlides[0]].previousElementSibling &&
        (this.slider.goToPrevSlide = false);
    },
  },
  watch: {
    "$store.state.newMalfunction"() {
      this.chosenMalfunction = this.$store.state.newMalfunction.situation;
      if (this.chosenMalfunction) {
        this.emitter.emit("create-malfunction--isDisabled", false);
      }
    },
  },
};
