export default {
  methods: {
    getValue(currentEntry) {
      const unit = currentEntry.details
        ? currentEntry.details.unit
        : currentEntry.unit;

      if (unit !== "n.v.t") {
        let result = 0;

        this.$store.state.checklistValues.forEach((val) => {
          if (val) {
            if (val.id === currentEntry.id) {
              result = `${val.value} ${unit}`;
            }
          }
        });

        return result;
      }

      return false;
    },
  },
};
