<script>
import "./StepWrapper.scss";
import StepWrapper from "./StepWrapper.js";

export default StepWrapper;
</script>

<template>
  <div class="step-wrapper">
    <h3 class="heading heading--3 step-wrapper__title">{{ title }}</h3>

    <div class="step-wrapper__inner" :class="{ noPadding: noPadding }">
      <div class="step-wrapper__loader" v-if="isLoading">
        <Loader colorMode="dark" />
      </div>
      <template v-else>
        <slot />
      </template>
    </div>
  </div>
</template>
