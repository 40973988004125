<template>
	<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 25.3 23.4" xml:space="preserve">
		<g>
			<path d="M10.7,2V0.8c0-0.4-0.4-0.8-0.8-0.8S9,0.4,9,0.8V2v1.3h1.7V2z"/>
			<path d="M3.3,9L3.3,9H1.9H0.8C0.4,9,0,9.4,0,9.9c0,0.4,0.4,0.8,0.8,0.8H2h1.3V9z"/>
			<path d="M16,4.9l0.8-0.8c0.3-0.3,0.3-0.9,0-1.2c-0.3-0.3-0.9-0.3-1.2,0L14,4.6l1.2,1.2L16,4.9z"/>
			<path d="M3.7,14.9l-0.8,0.8c-0.3,0.3-0.3,0.9,0,1.2c0.2,0.2,0.4,0.2,0.6,0.2c0.2,0,0.4-0.1,0.6-0.2l0.8-0.8l0.9-0.9 L4.6,14L3.7,14.9z"/>
			<path d="M3.7,4.9l0.9,0.9l1.2-1.2L4.8,3.7L4,2.9c-0.3-0.3-0.9-0.3-1.2,0c-0.3,0.3-0.3,0.9,0,1.2L3.7,4.9z"/>
			<path d="M25.3,16.9c0-0.7-0.2-1.4-0.7-1.9c-0.3-0.3-0.7-0.5-1.1-0.7c-0.3-2-2-3.5-4-3.7c0-0.2,0-0.4,0-0.5 c0-2.1-1.7-3.8-3.8-3.8c-0.5,0-1,0.1-1.4,0.3c-1-1.4-2.6-2.2-4.4-2.2c-3,0-5.5,2.5-5.5,5.5c0,1.9,0.9,3.5,2.3,4.5 c-0.3,0.5-0.4,1-0.4,1.6c0,1.9,1.5,3.4,3.4,3.4H11h0.1l1.4,1.4c0.2,0.2,0.4,0.2,0.6,0.2s0.4-0.1,0.6-0.2c0.3-0.3,0.3-0.8,0-1.2 l-0.5-0.5c0.2-0.1,0.3-0.4,0.3-0.6c0-0.4-0.4-0.8-0.8-0.8h-0.9H11H9.7c-1,0-1.7-0.8-1.7-1.7c0-0.3,0.1-0.6,0.2-0.8 c0.3-0.5,0.9-0.9,1.5-0.9c0.3,0,0.6-0.2,0.8-0.5c0-0.1,0.1-0.2,0.1-0.3c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0-1.2,1-2.2,2.2-2.2 c0.4,0,0.8-0.4,0.8-0.8c0-0.3,0.1-0.5,0.1-0.8C13.9,8.7,14.4,8.2,15,8c0.2-0.1,0.4-0.1,0.7-0.1c1.2,0,2.2,1,2.2,2.2s-1,2.2-2.2,2.2 c-0.4,0-0.8,0.4-0.8,0.8s0.4,0.8,0.8,0.8c1.4,0,2.5-0.7,3.2-1.8c0.1,0,0.2,0,0.3,0c1.5,0,2.7,1.2,2.7,2.7c0,0.4,0.4,0.8,0.8,0.8 c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.4,0.3,0.7c0,0.5-0.5,0.9-1,0.9h-1.8c-0.4,0-0.7,0.2-0.8,0.5l-0.2,0.6l2.5,2.5 c0.2,0.2,0.4,0.2,0.6,0.2s0.4-0.1,0.6-0.2c0.3-0.3,0.3-0.8,0-1.1l-0.8-0.8C24.2,19.2,25.3,18.2,25.3,16.9z M12,9.4 c-1.6,0.3-2.8,1.7-3.1,3.3c-0.4,0.1-0.7,0.2-1,0.5C6.8,12.5,6,11.3,6,9.9C6,7.7,7.7,6,9.9,6c1.2,0,2.3,0.6,3,1.5 C12.4,8.1,12.1,8.7,12,9.4z"/>
			<path d="M14.9,19.9c0.2,0.2,0.4,0.2,0.6,0.2s0.4-0.1,0.6-0.2c0.3-0.3,0.3-0.8,0-1.1l-0.3-0.3c-0.3-0.3-0.8-0.3-1.2,0 s-0.3,0.8,0,1.1L14.9,19.9z"/>
			<path d="M17.5,20.3c-0.3-0.3-0.8-0.3-1.1,0c-0.3,0.3-0.3,0.8,0,1.1L18,23c0.2,0.2,0.4,0.2,0.6,0.2 c0.2,0,0.4-0.1,0.6-0.2c0.3-0.3,0.3-0.8,0-1.1L17.5,20.3z"/>
			<path d="M15.1,20.9c-0.3-0.3-0.8-0.3-1.1,0c-0.3,0.3-0.3,0.8,0,1.1l1.1,1.1c0.2,0.2,0.4,0.2,0.6,0.2s0.4-0.1,0.6-0.2 c0.3-0.3,0.3-0.8,0-1.1L15.1,20.9z"/>
			<path d="M19.2,18.9c0-0.1,0.1-0.2,0.1-0.3c0-0.4-0.4-0.8-0.8-0.8h-2.7L19,21c0.2,0.2,0.4,0.2,0.6,0.2s0.4-0.1,0.6-0.2 c0.3-0.3,0.3-0.8,0-1.1L19.2,18.9z"/>
		</g>
	</svg>
</template>

<script>
	export default {
		name: 'icon10d'
	}
</script>