export default {
  props: {
    id: String,
    type: String,
    icon: String,
    min: Number,
    current: Number,
    max: Number,
  },
  data() {
    return {
      currentVal: null,
    };
  },
  created() {
    this.currentVal = this.current === 0 ? null : parseInt(this.current);
  },
  methods: {
    handleAdd() {
      let newVal = parseInt(this.currentVal ? this.currentVal : 0) + 1;

      if (newVal >= this.max) {
        newVal = this.max;
      }

      //update currentVal
      this.currentVal = newVal;

      //send event
      this.emitter.emit(`counter--update`, {
        id: this.id,
        type: this.type,
        value: newVal,
      });
    },
    handleRemove() {
      let newVal = parseInt(this.currentVal ? this.currentVal : 0) - 1;

      if (newVal <= this.min) {
        newVal = this.min;
      }

      //update currentVal
      this.currentVal = newVal;

      //send event
      this.emitter.emit(`counter--update`, {
        id: this.id,
        type: this.type,
        value: newVal,
      });
    },

    clampCurrentVal() {
      if (this.currentVal > this.max) {
        this.currentVal = this.max;
      }

      if (this.currentVal < this.min) {
        this.currentVal = this.min;
      }

      if (this.currentVal === 0) {
        this.currentVal = null;
      }
    },

    handleInput(e) {
      let value = parseInt(e.currentTarget.value);
      value = !isNaN(value) ? value : 0;

      this.currentVal = value;

      if (value > this.max) {
        this.currentVal = this.max;

        //check if value needs to be negative
        if (this.max === 0 && this.min < 0) {
          this.currentVal = -Math.abs(value);
        }
      }

      if (value < this.min) {
        this.currentVal = this.min;
      }

      this.emitter.emit(`counter--update`, {
        id: this.id,
        type: this.type,
        value: parseInt(this.currentVal),
      });

      if (this.currentVal === 0) {
        this.currentVal = null;
      }
    },
    handleFocus(e) {
      if (this.currentVal === 0) {
        this.currentVal = null;
      }

      e.target.select();
    },
    handleBlur(e) {
      if (this.currentVal === null) {
        this.currentVal = 0;
      }
    },
  },
  computed: {
    isPlusDisabled() {
      return this.currentVal >= this.max;
    },
    isMinDisabled() {
      return this.currentVal <= this.min;
    },
  },
  watch: {
    current() {
      this.currentVal = this.current;
    },
    min() {
      this.clampCurrentVal();
    },
    max() {
      this.clampCurrentVal();
    },
  },
};
