export default {
  data() {
    return {
      activeStep:
        this.$route.params.step === "overview"
          ? "overview"
          : parseInt(this.$route.params.step),
      activeSubStep: parseInt(this.$route.params.subStep),
    };
  },
  methods: {
    isComplete(index) {
      return [...this.$store.state.checklistCompletedLists].includes(index);
    },
    handleClickCategory(index) {
      this.$router.push(`/checklist/${index}`);
    },
    isOpen(index) {
      return isNaN(this.activeStep)
        ? this.activeStep === "overview"
          ? false
          : index === 0
        : this.activeStep === index;
    },
  },
  watch: {
    $route() {
      this.activeStep =
        this.$route.params.step === "overview"
          ? "overview"
          : parseInt(this.$route.params.step);
      this.activeSubStep = parseInt(this.$route.params.subStep);
    },
  },
};
