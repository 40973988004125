export default {
  props: {
    title: String,
    colored: Boolean,
  },
  data() {
    return {
      blackListUrls: ["/start", "/dashboard"],
    };
  },

  computed: {
    showPrev() {
      let present = false;

      this.blackListUrls.forEach((url) => {
        if (this.$route.path.includes(url)) {
          present = true;
        }
      });

      return !present;
    },
  },
};
