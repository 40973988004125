<template>
	<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 20.9 22" xml:space="preserve">
		<g>
			<path d="M10.4,8.4c1.4,0,2.6,1.1,2.6,2.5s-1.1,2.5-2.6,2.5s-2.5-1-2.5-2.4S9,8.4,10.4,8.4z"/>
			<path d="M19.4,3.6H1.5C0.7,3.6,0,4.2,0,5v11.9c0,0.8,0.7,1.5,1.5,1.5h17.9c0.8,0,1.5-0.7,1.5-1.5V5C20.9,4.2,20.2,3.6,19.4,3.6z M3.4,6.6H1.8c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.7,0.7-0.7h1.6c0.4,0,0.7,0.3,0.7,0.7C4.1,6.3,3.8,6.6,3.4,6.6z M10.4,16.2 c-2.9,0-5.2-2.3-5.2-5.2s2.3-5.2,5.2-5.2s5.2,2.3,5.2,5.2C15.6,13.9,13.3,16.2,10.4,16.2z"/>
			<path d="M17.6,0c0.2,0,0.5,0.2,0.6,0.3l0,0l0.6,0.9c0.2,0.3,0,0.6-0.3,0.6l0,0H13c-0.3,0-0.5-0.4-0.3-0.6l0,0l0.6-0.9 C13.4,0.2,13.6,0,13.9,0l0,0H17.6z"/>
		</g>	
	</svg>	
</template>


