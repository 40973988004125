<script>
import "./ChecklistNav.scss";
import ChecklistNav from "./ChecklistNav.js";

export default ChecklistNav;
</script>

<template>
  <nav class="checklistNav">
    <ul v-if="$store.state.checklist">
      <li
        v-for="(node, index) in $store.state.checklist.checklist"
        class="checklistNav__list"
        :key="`${node}-1-${index}`"
      >
        <button
          :class="[
            'checklistNav__list__item',
            {
              'is-open': isOpen(index),
            },
          ]"
          @click.prevent="() => handleClickCategory(index)"
          :disabled="$store.state.checklistIsSend"
        >
          <div
            :class="[
              'checklistNav__list__item__inner',
              {
                'is-active': isOpen(index),
              },
              { 'is-completed': isComplete(index) },
            ]"
          >
            <div class="checklistNav__item__symbol">
              <span
                :class="[
                  'checklistNav__item__theSymbol',
                  { 'is-completed': isComplete(index) },
                ]"
              >
                <Svg-icon v-if="isComplete(index)" iconType="check" />
                <Svg-icon v-else iconType="anchor" />
              </span>
            </div>
            <span class="checklistNav__item__label">{{ node.name }}</span>
          </div>
          <ul v-if="isOpen(index)">
            <li
              v-for="(subnode, subIndex) in node.subcategories"
              class="checklistNav__list--sub"
              :key="`${subnode}-2-${subIndex}`"
            >
              <div class="checklistNav__list__item">
                <div
                  :class="[
                    'checklistNav__list__item__inner',
                    {
                      'is-active': true,
                    },
                    { 'is-completed': isComplete(index) },
                  ]"
                >
                  <div class="checklistNav__item__symbol">
                    <span
                      :class="[
                        'checklistNav__item__theSymbol',
                        { 'is-completed': isComplete(index) },
                      ]"
                    >
                      <Svg-icon v-if="isComplete(index)" iconType="Check" />
                      <Svg-icon v-else iconType="Anchor" />
                    </span>
                  </div>
                  <span class="checklistNav__item__label">{{
                    subnode.name
                  }}</span>
                </div>
              </div>
            </li>
          </ul>
        </button>
      </li>
    </ul>
  </nav>
</template>
