export default {
  props: {
    viewData: [String, Object],
  },

  data() {
    return {
      theContent: {},
    };
  },

  created() {
    this.setTheMessage();
  },

  methods: {
    setTheMessage() {
      this.theContent.title =
        this.viewData && this.viewData.title
          ? this.viewData.title
          : "Er is iets misgegaan";
      this.theContent.subtitle =
        this.viewData && this.viewData.subtitle
          ? this.viewData.subtitle
          : "Oeps?!";
      this.theContent.body =
        this.viewData && this.viewData.body ? this.viewData.body : "";
      this.theContent.icon =
        this.viewData && this.viewData.icon ? this.viewData.icon : "whoops";
      this.theContent.button =
        this.viewData && this.viewData.button
          ? this.viewData.button
          : "Sluiten";
    },

    handleClick() {
      this.$emit("emitToggleOffcanvas");
      this.emitter.emit("toggleOffcanvas", false);
    },
  },
};
