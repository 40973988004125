<script>
import "./Ship.scss";
import Ship from "./Ship.js";

export default Ship;
</script>

<template>
  <Step-wrapper title="Kies een schip" :isLoading="isLoading">
    <agile :centerMode="true" :slidesToShow="3" :dots="false" ref="carousel">
      <template #prevButton>
        <div class="shift__actions__prev">
          <div class="directionalButton">
            <span class="directionalButton__symbol">
              <Svg-icon iconType="Arrow-left" />
            </span>
          </div>
        </div>
      </template>
      <div
        v-for="item in $store.state.allShips"
        class="ship__item"
        ref="ship__item"
        :key="item.id"
      >
        <div class="ship__item__inner">
          <div class="ship__item__sizer">
            <div class="ship__item__sizerWrap">
              <div class="ship__item__content">
                <input
                  @change="(e) => handleChange(e, item)"
                  :id="item.id"
                  :value="item.id"
                  class="ship__radio"
                  type="checkbox"
                  :checked="chosenShip ? chosenShip.id === item.id : false"
                />
                <label :for="item.id" class="ship__radioFaker">
                  <div class="ship__checked__symbol">
                    <span class="ship__checked__theSymbol">
                      <Svg-icon iconType="check" iconColor="#fff" />
                    </span>
                  </div>

                  <span class="ship__item__symbol">
                    <Svg-icon iconType="Ship-icon" />
                  </span>

                  <span class="ship__item__label">{{ item.name }}</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template #nextButton>
        <div class="shift__actions__next">
          <div class="directionalButton">
            <span class="directionalButton__symbol">
              <Svg-icon iconType="Arrow-right" />
            </span>
          </div>
        </div>
      </template>
    </agile>
  </Step-wrapper>
</template>
