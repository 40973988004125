import axios from "axios";

const DEV_URL = "https://cockpit.test/api/";
const API_URL = "https://swetscockpitapp.nl/api";
const TEST_URL = "https://test.swetscockpitapp.nl/api";

const LOCAL_URL =
  document.location.host.indexOf("localhost") == 0
    ? TEST_URL
    : document.location.host.indexOf("test.") == 0
    ? TEST_URL
    : API_URL;

localStorage.setItem("LOCAL_URL", LOCAL_URL);

export default axios.create({
  baseURL: LOCAL_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: {
      toString() {
        return `Bearer ${localStorage.getItem("token")}`;
      },
    },
    Concession: {
      toString() {
        return `${localStorage.getItem("concession")}`;
      },
    },
  },
});
