<script>
import "./CommentsConfirm.scss";
import CommentsConfirm from "./CommentsConfirm.js";

export default CommentsConfirm;
</script>

<template>
  <div class="commentsConfirm">
    <transition name="fade" mode="out-in">
      <div v-if="!isSuccess" class="commentsConfirm__inner">
        <header class="commentsConfirm__header">
          <div class="commentsConfirm__header__group">
            <span class="commentsConfirm__header__symbol">
              <Svg-icon iconType="Warning"></Svg-icon>
            </span>

            <span class="commentsConfirm__header__subtitle"
              >{{
                viewData.entry.details
                  ? viewData.entry.details.name
                  : viewData.entry.name
              }}
              -
              {{
                viewData.entry.details
                  ? viewData.entry.details.title
                  : viewData.entry.title
              }}</span
            >
          </div>

          <h3 class="heading heading--4 commentsConfirm__header__title">
            {{
              viewData.entry.details
                ? viewData.entry.details.subcategorytitle
                : viewData.entry.subcategorytitle
            }}
          </h3>
        </header>

        <div
          :class="['commentsConfirm__theContent', { 'is-loading': isLoading }]"
        >
          <div class="commentsConfirm__content">
            <div class="commentsConfirm__content__item">
              <div class="commentsConfirm__formContent">
                <div
                  v-if="viewData.remark != ''"
                  class="commentsConfirm__formContent__remarks"
                >
                  <p>{{ viewData.remark }}</p>
                </div>

                <div
                  v-if="viewData.image"
                  class="commentsConfirm__formContent__visual"
                >
                  <button
                    @click="handlePhotoClick"
                    class="button button--brand-1"
                  >
                    <span class="button__symbol">
                      <Svg-icon iconType="Camera-icon"></Svg-icon>
                    </span>
                    <span class="button__label">Bekijk foto</span>
                  </button>
                </div>
              </div>
            </div>

            <div class="commentsConfirm__content__item">
              <div class="commentsConfirm__info">
                <ul class="commentsConfirm__info__list">
                  <li class="commentsConfirm__info__listItem">
                    <span class="commentsConfirm__info__title"
                      >Datum &amp; tijd</span
                    >

                    <div class="commentsConfirm__info__data">
                      <span class="commentsConfirm__info__data__symbol">
                        <Svg-icon iconType="Calendar-flat"></Svg-icon>
                      </span>

                      <span class="commentsConfirm__info__data__theContent">
                        <Date dateType="nameOfDay+DD/MM/YYYY" />
                        {{ viewData.timestamp }}
                      </span>
                    </div>
                  </li>

                  <li class="commentsConfirm__info__listItem">
                    <span class="commentsConfirm__info__title"
                      >Dienstregeling</span
                    >

                    <div class="commentsConfirm__info__data">
                      <span class="commentsConfirm__info__data__symbol">
                        <Svg-icon iconType="Anchor-circle"></Svg-icon>
                      </span>

                      <span class="commentsConfirm__info__data__theContent">
                        {{ $store.state.flightSettings.shift.name }}
                      </span>
                    </div>
                  </li>

                  <li class="commentsConfirm__info__listItem">
                    <span class="commentsConfirm__info__title">Kapitein</span>

                    <div class="commentsConfirm__info__data">
                      <span class="commentsConfirm__info__data__symbol">
                        <Svg-icon iconType="Wheel"></Svg-icon>
                      </span>

                      <span class="commentsConfirm__info__data__theContent">
                        {{ $store.state.captainDetails.name }}
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="commentsConfirm__actions">
            <div class="commentsConfirm__actions__item">
              <button
                @click="handleChangeClick"
                class="button button--pairing-neutrals-5"
              >
                <span class="button__label">Wijzigen</span>
              </button>
            </div>

            <div class="commentsConfirm__actions__item">
              <button @click="handleSubmit" class="button button--brand-2">
                <span class="button__label">Bevestigen</span>
              </button>
            </div>
          </div>
        </div>

        <div v-if="isLoading" class="commentsConfirm__loader">
          <Loader />
        </div>
      </div>

      <div v-else class="commentsConfirm">
        <div class="commentsConfirm__success">
          <Success :viewData="theMessage" />
        </div>
      </div>
    </transition>
  </div>
</template>
