<template>
	<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 12.2 12.1" xml:space="preserve">
		<g>
			<path d="M8.5,10.3c0,0,1.6,1.8,2.9,1.8l0,0h-0.6c-0.8,0-1.6-0.3-2.3-0.7c-1.5,0.8-3.2,0.8-4.7,0
				c-0.7,0.4-1.5,0.7-2.3,0.7l0,0H0.9c1.3,0,2.9-1.8,2.9-1.8C5.2,11.3,7,11.3,8.5,10.3L8.5,10.3z M7.4,0l0.4,1.5h1.7
				c0.6,0,1.2,0.5,1.2,1.2l0,0v2.7l1,0.3c0.1,0.1,0.3,0.1,0.3,0.3c0.1,0.1,0.1,0.3,0,0.5l0,0l-1.4,3.9h0c-0.9,0-1.7-0.5-2.3-1.2
				c-0.6,0.7-1.4,1.2-2.3,1.2S4.4,9.9,3.8,9.2c-0.6,0.7-1.4,1.2-2.3,1.2l0,0h0L0,6.5C0,6.4,0,6.2,0.1,6.1c0.1-0.1,0.2-0.2,0.3-0.3l0,0
				l1-0.4V2.7C1.4,2,2,1.5,2.6,1.5l0,0h1.7L4.8,0H7.4z M9.6,2.7h-7V5l3.5-1.2L9.6,5V2.7z"/>
		</g>
	</svg>
</template>