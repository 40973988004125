<script>
import "./Help.scss";
import Help from "./Help.js";

export default Help;
</script>

<template>
  <Page-wrapper title="Help & Contact">
    <div class="help__inner">
      <div class="help__content">
        <div class="help__content__inner">
          <div class="help__box">
            <div class="help__box__title">
              <span class="help__box__title__symbol">
                <Svg-icon iconType="LifebuoyFlat"></Svg-icon>
              </span>

              <span class="help__box__title__label"
                >Urgente storing? Bel steeds eerst het noodnummer TD : 0032 3
                844 19 19</span
              >
            </div>

            <div class="help__box__callout">
              <span class="help__box__callout__symbol">
                <Svg-icon iconType="Phone"></Svg-icon>
              </span>

              <a href="tel:003238441919" class="help__box__callout__label"
                >0032 3 844 19 19</a
              >
            </div>
          </div>

          <div class="help__content__theContent">
            <table>
              <tbody>
                <tr>
                  <td>
                    <span class="help__content__theContent__label"
                      >Technische dienst</span
                    >
                    <span class="help__content__theContent__desc"
                      >Een technieker bereiken?</span
                    >
                  </td>
                  <td>
                    <div class="help__content__theContent__phone">
                      <span class="help__content__theContent__phone__symbol">
                        <Svg-icon iconType="Phone"></Svg-icon>
                      </span>
                      <a
                        href="tel:003238332791"
                        class="help__content__theContent__phone__label"
                        >0032 3 833 27 91</a
                      >
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="help__content__theContent__label"
                      >Concessie verantwoordelijke</span
                    >
                    <span class="help__content__theContent__desc"
                      >De verantwoordeijke voor jouw vaart.</span
                    >
                  </td>
                  <td>
                    <div class="help__content__theContent__phone">
                      <span class="help__content__theContent__phone__symbol">
                        <Svg-icon iconType="Phone"></Svg-icon>
                      </span>
                      <a
                        href="tel:003238332543"
                        class="help__content__theContent__phone__label"
                        >0032 3 833 25 43</a
                      >
                    </div>
                  </td>
                  <td>
                    <div class="help__content__theContent__phone">
                      <span class="help__content__theContent__phone__symbol">
                        <Svg-icon iconType="Phone"></Svg-icon>
                      </span>
                      <a
                        href="tel:003238332540"
                        class="help__content__theContent__phone__label"
                        >0032 3 833 25 40</a
                      >
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="help__content__theContent__label"
                      >Aqualiner</span
                    >
                    <span class="help__content__theContent__desc"></span>
                  </td>
                  <td>
                    <div class="help__content__theContent__phone">
                      <span class="help__content__theContent__phone__symbol">
                        <Svg-icon iconType="Phone"></Svg-icon>
                      </span>
                      <a
                        href="tel:003238332543"
                        class="help__content__theContent__phone__label"
                        >0032 3 833 25 43</a
                      >
                    </div>
                  </td>
                  <td>
                    <div class="help__content__theContent__phone">
                      <span class="help__content__theContent__phone__symbol">
                        <Svg-icon iconType="Phone"></Svg-icon>
                      </span>
                      <a
                        href="tel:003238332540"
                        class="help__content__theContent__phone__label"
                        >0032 3 833 25 40</a
                      >
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="help__content__theContent__label"
                      >Politie/Brandweer</span
                    >
                    <span class="help__content__theContent__desc"></span>
                  </td>
                  <td>
                    <div class="help__content__theContent__phone">
                      <span class="help__content__theContent__phone__symbol">
                        <Svg-icon iconType="Phone"></Svg-icon>
                      </span>
                      <a
                        href="tel:112"
                        class="help__content__theContent__phone__label"
                        >112</a
                      >
                    </div>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    <span class="help__content__theContent__label"
                      >Ziekenwagen</span
                    >
                    <span class="help__content__theContent__desc"></span>
                  </td>
                  <td>
                    <div class="help__content__theContent__phone">
                      <span class="help__content__theContent__phone__symbol">
                        <Svg-icon iconType="Phone"></Svg-icon>
                      </span>
                      <a
                        href="tel:112"
                        class="help__content__theContent__phone__label"
                        >112</a
                      >
                    </div>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    <span class="help__content__theContent__label"
                      >Ziekenwagen HAVEN</span
                    >
                    <span class="help__content__theContent__desc"></span>
                  </td>
                  <td>
                    <div class="help__content__theContent__phone">
                      <span class="help__content__theContent__phone__symbol">
                        <Svg-icon iconType="Phone"></Svg-icon>
                      </span>
                      <a
                        href="tel:003235410023"
                        class="help__content__theContent__phone__label"
                        >0032 3 541 00 23</a
                      >
                    </div>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    <span class="help__content__theContent__label"
                      >Centrale Zandvliet</span
                    >
                    <span class="help__content__theContent__desc"></span>
                  </td>
                  <td>
                    <div class="help__content__theContent__phone">
                      <span class="help__content__theContent__phone__symbol">
                        <Svg-icon iconType="Phone"></Svg-icon>
                      </span>
                      <a class="help__content__theContent__phone__label"
                        >VHF 12</a
                      >
                    </div>
                  </td>
                  <td>`</td>
                </tr>

                <tr>
                  <td>
                    <span class="help__content__theContent__label"
                      >Operationeel Manager</span
                    >
                    <span class="help__content__theContent__desc"></span>
                  </td>
                  <td>
                    <div class="help__content__theContent__phone">
                      <span class="help__content__theContent__phone__symbol">
                        <Svg-icon iconType="Phone"></Svg-icon>
                      </span>
                      <a
                        href="tel:003238086566"
                        class="help__content__theContent__phone__label"
                        >0032 3 808 65 66</a
                      >
                    </div>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    <span class="help__content__theContent__label"
                      >Nautisch Coördinator</span
                    >
                    <span class="help__content__theContent__desc"></span>
                  </td>
                  <td>
                    <div class="help__content__theContent__phone">
                      <span class="help__content__theContent__phone__symbol">
                        <Svg-icon iconType="Phone"></Svg-icon>
                      </span>
                      <a
                        href="tel:0031618966555"
                        class="help__content__theContent__phone__label"
                        >0031 6 18966555</a
                      >
                    </div>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    <span class="help__content__theContent__label"
                      >Assistent Operationeel Manager</span
                    >
                    <span class="help__content__theContent__desc"></span>
                  </td>
                  <td>
                    <div class="help__content__theContent__phone">
                      <span class="help__content__theContent__phone__symbol">
                        <Svg-icon iconType="Phone"></Svg-icon>
                      </span>
                      <a
                        href="tel:003238086565"
                        class="help__content__theContent__phone__label"
                        >0032 3 808 65 65</a
                      >
                    </div>
                  </td>
                  <td></td>
                </tr>

                <tr>
                  <td>
                    <span class="help__content__theContent__label"
                      >Planning</span
                    >
                    <span class="help__content__theContent__desc"></span>
                  </td>
                  <td>
                    <div class="help__content__theContent__phone">
                      <span class="help__content__theContent__phone__symbol">
                        <Svg-icon iconType="Phone"></Svg-icon>
                      </span>
                      <a
                        href="tel:0031854895155"
                        class="help__content__theContent__phone__label"
                        >0031 85 4895155</a
                      >
                    </div>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    <span class="help__content__theContent__label"
                      >Salaris administratie</span
                    >
                    <span class="help__content__theContent__desc"></span>
                  </td>
                  <td>
                    <div class="help__content__theContent__phone">
                      <span class="help__content__theContent__phone__symbol">
                        <Svg-icon iconType="Phone"></Svg-icon>
                      </span>
                      <a
                        href="tel:0031854895117"
                        class="help__content__theContent__phone__label"
                        >0031 85 4895117</a
                      >
                    </div>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    <span class="help__content__theContent__label"
                      >Personeelszaken</span
                    >
                    <span class="help__content__theContent__desc"></span>
                  </td>
                  <td>
                    <div class="help__content__theContent__phone">
                      <span class="help__content__theContent__phone__symbol">
                        <Svg-icon iconType="Phone"></Svg-icon>
                      </span>
                      <a
                        href="tel:0031854895170"
                        class="help__content__theContent__phone__label"
                        >0031 85 4895170</a
                      >
                    </div>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    <span class="help__content__theContent__label"
                      >Technische Dienst</span
                    >
                    <span class="help__content__theContent__desc"></span>
                  </td>
                  <td>
                    <div class="help__content__theContent__phone">
                      <span class="help__content__theContent__phone__symbol">
                        <Svg-icon iconType="Phone"></Svg-icon>
                      </span>
                      <a
                        href="tel:0031854895180"
                        class="help__content__theContent__phone__label"
                        >0031 85 4895180</a
                      >
                    </div>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </Page-wrapper>
</template>
