<template>
	<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 26.6 19.9" xml:space="preserve">
		<g>
			<path d="M24.7,14.6c-0.3-2.1-2.1-3.7-4.2-3.8c0-0.2,0-0.4,0-0.6c0-2.2-1.8-4-4-4c-0.7,0-1.3,0.2-1.9,0.5 c-1.1-1.3-2.7-2.1-4.5-2.1c-3.1,0-5.7,2.6-5.7,5.7c0,1.9,0.9,3.6,2.5,4.7c-0.2,0.4-0.3,0.9-0.3,1.4c0,2,1.6,3.5,3.5,3.5l11.2-0.1 h2.5c1.5,0,2.7-1.2,2.7-2.7C26.6,15.9,25.8,14.9,24.7,14.6L24.7,14.6z M6.2,10.2c0-2.2,1.8-4,4-4c1.2,0,2.4,0.6,3.1,1.5 c-0.4,0.5-0.6,1-0.8,1.7c-1.7,0.3-3,1.7-3.2,3.5c-0.5,0.1-1,0.4-1.3,0.7C6.9,12.8,6.2,11.6,6.2,10.2L6.2,10.2z M23.9,18.1l-11,0.1 h-2.7c-1,0-1.8-0.8-1.8-1.8c0-1,0.8-1.8,1.8-1.8c0.5,0,0.9-0.4,0.9-0.9c0-0.1,0-0.1,0-0.1c0-0.1,0-0.1,0-0.1c0-1.3,1-2.3,2.3-2.3 c0.5,0,0.9-0.4,0.9-0.9c0-1.3,1-2.3,2.3-2.3c1.3,0,2.3,1,2.3,2.3s-1,2.3-2.3,2.3c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9 c1.4,0,2.6-0.7,3.4-1.8c0.1,0,0.2,0.1,0.3,0.1c1.6,0,2.9,1.3,2.9,2.9c0,0.5,0.4,0.9,0.9,0.9c0.5,0,1,0.4,1,1 C24.9,17.7,24.4,18.1,23.9,18.1L23.9,18.1z"/>
			<path d="M11.1,2V0.9c0-0.5-0.4-0.9-0.9-0.9C9.7,0,9.3,0.4,9.3,0.9l0,1.2v1.3h1.7L11.1,2z"/>
			<path d="M3.4,9.3H0.9C0.4,9.3,0,9.7,0,10.2c0,0.5,0.4,0.9,0.9,0.9l1.2,0h1.3V9.3z"/>
			<path d="M15.7,6l1.8-1.8c0.3-0.3,0.3-0.9,0-1.2s-0.9-0.3-1.2,0l-0.8,0.8l-0.9,0.9L15.7,6z"/>
			<path d="M3.8,15.4L3,16.2c-0.3,0.3-0.3,0.9,0,1.2c0.2,0.2,0.4,0.2,0.6,0.2c0.2,0,0.4-0.1,0.6-0.2l1.8-1.8 l-1.2-1.2L3.8,15.4z"/>
			<path d="M3.8,5l0.9,0.9l1.2-1.2L4.2,3C3.9,2.6,3.3,2.6,3,3S2.6,3.8,3,4.2L3.8,5z"/>
		</g>
	</svg>
</template>

<script>
	export default {
		name: 'clouds'
	}
</script>