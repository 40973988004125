export default {
  props: {
    viewData: Object,
  },

  data() {
    return {
      isLoading: false,
      isSubmitted: false,
    };
  },
  computed: {
    allItemsSend() {
      return (
        //done sending stops
        this.$store.state.currentStopsSendInfo.succesfullySend.length >=
          this.$store.state.currentStops.length &&
        !this.$store.state.currentStopsSendInfo.updated &&
        !this.$store.state.currentStopsSendInfo.isSending &&
        //done sending the checklist
        this.$store.state.checklistIsSend &&
        //done sending new malfunctions
        !this.$store.state.newMalfunctionSendInfo.needsSending.length &&
        //done sending read notifications
        !this.$store.state.readNotificationSendInfo.needsSending.length
      );
    },
  },
  methods: {
    handleClick() {
      this.emitter.emit("toggleOffcanvas", false);
    },

    handleSubmitClick() {
      //save localy
      this.$store.commit({
        type: "saveStore",
      });

      //allow list to update again
      this.$store.commit({
        type: "setChecklistIsSend",
        payload: false,
      });

      this.$store.commit({
        type: "setChecklistIsUpdated",
        payload: true,
      });

      this.$store.commit({
        type: "setChecklistIsSending",
        payload: false,
      });

      this.emitter.emit("app--sendCheckist");

      //save store to old store

      localStorage.setItem("oldStore", JSON.stringify(this.$store.state));

      if (this.viewData.upload) {
        //submit the checklist and upload
        //go to dashboard
        this.emitter.emit("toggleOffcanvas", false);

        //go to upload
        this.$router.push("/upload-data");
      } else {
        //go to dashboard
        this.emitter.emit("toggleOffcanvas", false);

        //go to dash
        this.$router.push("/dashboard");
      }
    },

    onSuccess(r) {
      this.isLoading = false;

      this.isSubmitted = true;
    },

    gotToLogout() {
      if (this.viewData.logout && this.allItemsSend) {
        //has been send and needs logout
        this.emitter.emit("toggleOffcanvas", [
          true,
          "LogoutConfirm",
          { checklistIsCompleted: true },
        ]);
      }
    },
  },
  watch: {
    "$store.state.checklistIsSend"() {
      this.gotToLogout();
    },
    "$store.state.currentStopsSendInfo"() {
      this.gotToLogout();
    },
    "$store.state.newMalfunctionSendInfo"() {
      this.gotToLogout();
    },
    "$store.state.readNotificationSendInfo"() {
      this.gotToLogout();
    },
  },
};
