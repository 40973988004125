<script>
import "./MalfunctionsDetail.scss";
import MalfunctionsDetail from "./MalfunctionsDetail.js";

export default MalfunctionsDetail;
</script>

<template>
  <div :class="['malfunctionsDetail', { 'is-urgent': theMalfunction.urgent }]">
    <div :class="['malfunctionsDetail__inner']">
      <header class="malfunctionsDetail__header">
        <div class="malfunctionsDetail__header__group">
          <span class="malfunctionsDetail__header__symbol">
            <Svg-icon iconType="Warning"></Svg-icon>
          </span>

          <span class="malfunctionsDetail__header__subtitle">Storing</span>
        </div>

        <h3 class="heading heading--3 malfunctionsDetail__header__title">
          {{ theMalfunction.category }}
        </h3>
      </header>

      <div class="malfunctionsDetail__content">
        <div class="malfunctionsDetail__content__item">
          <div class="malfunctionsDetail__desc">
            <p>{{ theMalfunction.remarks }}</p>
          </div>

          <div class="malfunctionsDetail__visual" v-if="theMalfunction.image">
            <button @click="handlePhotoClick" class="button button--brand-1">
              <span class="button__symbol">
                <Svg-icon iconType="Camera-icon"></Svg-icon>
              </span>
              <span class="button__label">Bekijk foto</span>
            </button>
          </div>
        </div>

        <div class="malfunctionsDetail__content__item">
          <div class="malfunctionsDetail__info">
            <ul class="malfunctionsDetail__info__list">
              <li class="malfunctionsDetail__info__listItem">
                <span class="malfunctionsDetail__info__title"
                  >Datum &amp; tijd</span
                >

                <div class="malfunctionsDetail__info__data">
                  <span class="malfunctionsDetail__info__data__symbol">
                    <Svg-icon iconType="Calendar-flat" />
                  </span>

                  <span
                    class="malfunctionsDetail__info__data__theContent ff--bold"
                  >
                    {{ formatDateOfMalfunction(theMalfunction) }}
                    <span>&bullet;</span>
                    {{ formatTimeOfMalfunction(theMalfunction) }}
                  </span>
                </div>
              </li>

              <li class="malfunctionsDetail__info__listItem">
                <span class="malfunctionsDetail__info__title"
                  >Dienstregeling</span
                >

                <div class="malfunctionsDetail__info__data">
                  <span class="malfunctionsDetail__info__data__symbol">
                    <Svg-icon iconType="Anchor-circle"></Svg-icon>
                  </span>

                  <span class="malfunctionsDetail__info__data__theContent">{{
                    theFlightInfo.shift.name
                  }}</span>
                </div>
              </li>

              <li class="malfunctionsDetail__info__listItem">
                <span class="malfunctionsDetail__info__title">Kapitein</span>

                <div class="malfunctionsDetail__info__data">
                  <span class="malfunctionsDetail__info__data__symbol">
                    <Svg-icon iconType="Wheel"></Svg-icon>
                  </span>

                  <span class="malfunctionsDetail__info__data__theContent">{{
                    theMalfunction.captain.full_name
                  }}</span>
                </div>
              </li>

              <li
                v-if="theMalfunction.urgent"
                :class="[
                  'malfunctionsDetail__info__listItem',
                  { 'is-urgent': theMalfunction.urgent },
                ]"
              >
                <span class="malfunctionsDetail__info__title">Urgentie</span>

                <div class="malfunctionsDetail__info__data">
                  <span class="malfunctionsDetail__info__data__symbol"> </span>

                  <span class="malfunctionsDetail__info__data__theContent"
                    >Dringend</span
                  >
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
