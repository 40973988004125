import TimeStamp from "../../../functions/TimeStamp";

export default {
  data() {
    return {
      timeIsloaded: false,
      timeRemaining: null,
      isLoading: true,
      theNextStop: false,
      thePreviousStop: false,
      theSecondStop: false,
      theThirdStop: false,
      animateIn: false,
    };
  },

  created() {
    this.setStops();

    this.getRemainingTime();
  },
  computed: {
    timeOfDeparture() {
      return (departure) => departure.substring(0, departure.length - 3);
    },
    allItemsPassed() {
      return (
        this.$store.state.currentStops.filter((stop) => {
          return !stop.skipped && !stop.passed && !stop.arrived;
        }).length === 0
      );
    },

    visualPreviousStop() {
      const filteredCurrentStops = [...this.$store.state.currentStops].filter(
        (stop) => (stop ? !stop.skipped : false)
      );

      if (this.$store.state.currentStopIndex === -1) {
        return false;
      } else if (
        this.$store.state.currentStopIndex >=
        filteredCurrentStops.length - 1
      ) {
        return this.thePreviousStop;
      } else {
        return this.theNextStop;
      }
    },
    visualNextStop() {
      const filteredCurrentStops = [...this.$store.state.currentStops].filter(
        (stop) => (stop ? !stop.skipped : false)
      );

      if (
        this.$store.state.currentStopIndex === -1 ||
        this.$store.state.currentStopIndex === filteredCurrentStops.length - 1
      ) {
        return this.theNextStop;
      } else {
        return this.theSecondStop;
      }
    },
    visualSecondStop() {
      //de second stop hier aan passen
      const filteredCurrentStops = [...this.$store.state.currentStops].filter(
        (stop) => (stop ? !stop.skipped : false)
      );

      if (
        this.$store.state.currentStopIndex >=
        filteredCurrentStops.length - 2
      ) {
        return false;
      } else {
        return this.theThirdStop;
      }
    },
  },
  methods: {
    hasStopChecklist() {
      return (
        this.$store.state.flightSettings.shift.has_end_checklist
        //&& !this.$store.state.checklistIsSend
      );
    },
    setStops() {
      let currentIndex = -1;
      let currentStop =
        this.$store.state.currentStops[
          this.$store.state.currentStopIndex >= 0
            ? this.$store.state.currentStopIndex
            : 0
        ];
      const filteredCurrentStops = [...this.$store.state.currentStops].filter(
        (stop) => (stop ? !stop.skipped : false)
      );

      filteredCurrentStops.forEach((stop, index) => {
        if (currentStop) {
          if (currentStop.departure === stop.departure) {
            currentIndex = index;
          }
        }
      });

      if (currentIndex < 0) {
        currentIndex = 0;
      }

      this.theNextStop = filteredCurrentStops[currentIndex];

      if (currentIndex - 1 >= 0) {
        /*if (
          filteredCurrentStops[currentIndex - 1].stop_id !==
          this.theNextStop.stop_id
        ) {
          this.thePreviousStop = filteredCurrentStops[currentIndex - 1];
        } else {
          this.thePreviousStop = false;
        }*/
        this.thePreviousStop = this.$store.state.currentStops[currentIndex - 1];
      } else {
        this.thePreviousStop = false;
      }

      if (currentIndex + 1 < filteredCurrentStops.length) {
        this.theSecondStop = filteredCurrentStops[currentIndex + 1];
        /*if (
          filteredCurrentStops[currentIndex + 1].stop_id !==
          this.theNextStop.stop_id
        ) {
          this.theSecondStop = filteredCurrentStops[currentIndex + 1];
        } else {
          this.theSecondStop = false;
        }*/
      } else {
        this.theSecondStop = false;
      }

      if (currentIndex + 2 < filteredCurrentStops.length) {
        this.theThirdStop = filteredCurrentStops[currentIndex + 2];
      }
    },

    handleSkipClick() {
      let viewData = {
        nextStop: this.theNextStop,
      };

      /* this.$store.commit({
        type: "setCurrentStops",
        payload: {},
      });*/

      this.emitter.emit("toggleOffcanvas", [true, "FlightSkipForm", viewData]);
    },

    handleArrivedClick() {
      const viewData = {
        timeStamp: TimeStamp.getTime(),
        nextStop: this.theSecondStop,
        goToNext: true,
      };

      this.emitter.emit("toggleOffcanvas", [true, "PassengersForm", viewData]);
    },
    handleStartClick() {
      //set to needs to arrive
      this.$store.commit({
        type: "setCurrentStopIndex",
        payload: 0,
      });

      //set first departed time
      this.setFirstDeparted();
    },

    setFirstDeparted() {
      const currentStops = [...this.$store.state.currentStops];

      this.$store.commit({
        type: "setCurrentStops",
        payload: currentStops.map((stop, index) => {
          if (index === 0) {
            //first stop
            return {
              ...stop,
              departured: TimeStamp.getTime(),
            };
          }

          return stop;
        }),
      });
    },

    getRemainingTime() {
      setInterval(
        function () {
          let self = this;

          const today = new Date();
          let timeOfArrival = new Date();

          if (!this.visualPreviousStop) {
            this.timeRemaining = "";
          } else {
            let [hh, mm, ss] = this.visualPreviousStop.departure.split(":");

            timeOfArrival.setHours(hh); // set the hours, using implicit type coercion
            timeOfArrival.setMinutes(mm);
            timeOfArrival.setSeconds(ss);

            let diff = today.getTime() - timeOfArrival.getTime();

            let hours = Math.floor(diff / (1000 * 60 * 60));
            diff -= hours * (1000 * 60 * 60);

            let mins = Math.floor(diff / (1000 * 60));

            diff -= mins * (1000 * 60);
            mins < 10 && (mins = "0" + mins);

            this.timeRemaining = hours + ":" + mins;
          }
        }.bind(this),
        1000
      );
    },

    handleFinishClick() {
      //end shift go to overview
      //this.$router.push(`/flight-overview`);

      if (this.hasStopChecklist()) {
        this.$store.commit({
          type: "resetCheckList",
          payload: true,
        });
      }

      if (this.hasStopChecklist()) {
        this.$router.push(`/checklist?end=true`);
      } else {
        this.$router.push(`/upload-data`);
      }
    },
  },
  watch: {
    "$store.state.currentStopIndex"() {
      this.setStops();

      this.animateIn = true;

      setTimeout(() => {
        this.animateIn = false;
      }, 900);
    },
  },
};
