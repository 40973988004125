export default {
  props: {
    viewData: Object,
  },

  data() {
    return {
      isLoading: false,
      isSuccess: false,
      theMessage: {
        title: String,
        subtitle: String,
        body: String,
        icon: String,
      },
    };
  },

  methods: {
    handleSubmit() {
      //remove old remark if present

      const remarks = [...this.$store.state.checklistRemarks].filter(
        (remark) => remark.id !== this.viewData.entry.id
      );

      //push new remark
      if (this.viewData.remark !== "" && this.viewData.remark) {
        remarks.push({
          id: this.viewData.entry.id,
          img: this.viewData.img,
          remark: this.viewData.remark,
          timeStamp: this.viewData.timeStamp,
        });
      }

      this.$store.commit({
        type: "setChecklistRemarks",
        payload: remarks,
      });

      // return the comment is saved to set active state on button
      this.emitter.emit("toggleOffcanvas", false);
    },

    handleChangeClick() {
      this.emitter.emit("toggleOffcanvas", [
        true,
        "CommentsForm",
        { ...this.viewData.entry, remark: this.viewData.remark },
      ]);
    },
  },
};
