<script>
import "./App.scss";
import App from "./App.js";
export default App;
</script>

<template>
  <div class="app">
    <div class="main">
      <router-view />
    </div>
    <Off-canvas />
    <Photo-viewer />
    <transition name="fade">
      <div class="overlay" v-if="showOverlay"></div>
    </transition>
  </div>
</template>
