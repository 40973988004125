export default {
  props: {
    viewData: [String, Object],
  },

  data() {
    return {
      theContent: {
        title: "Er is iets misgegaan",
        subtitle: "Oeps?!",
        body: "",
        icon: "Whoops",
      },
    };
  },

  created() {
    this.setTheMessage();
  },

  methods: {
    setTheMessage() {
      if (this.viewData) {
        this.theContent.title = this.viewData.title;

        this.theContent.subtitle = this.viewData.subtitle;
        this.theContent.body = this.viewData.body;
        this.theContent.icon = this.viewData.icon;
      }
    },

    handleClick() {
      this.emitter.emit("toggleOffcanvas", false);
      if (this.viewData) {
        this.viewData.title ==
          "Captain with token not found (Try to login again?)" &&
          this.$router.push("/");
        this.viewData.status == "404" && this.$router.push("/");
        this.viewData.status == 401 && this.$router.push("/");
      }
    },
  },
};
