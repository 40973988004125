<script>
import "./FlightEdit.scss";
import FlightEdit from "./FlightEdit.js";

export default FlightEdit;
</script>

<template>
  <Page-wrapper title="HUIDIGE VAART" :colored="true">
    <section class="flightEditPanel">
      <transition name="fade" mode="out-in">
        <div v-if="isLoading" class="flightEditPanel__loader">
          <Loader />
        </div>

        <div v-else class="flightEditPanel__inner">
          <div
            :class="['flightEditPanel__overview', { 'is-open': showOffcanvas }]"
          >
            <ul class="flightEditPanel__list">
              <li
                v-for="(item, index) in $store.state.currentStops"
                :key="index"
                :class="[
                  'flightEditPanel__list__item',
                  { 'is-passed': item.passed == 1 || item.skipped == 1 },
                  { 'is-active': activeIndex === index },
                ]"
              >
                <div class="flightEditPanel__item__wrapper">
                  <div class="flightEditPanel__item__content">
                    <span
                      v-if="item.is_manually_added"
                      class="flightEditPanel__content__symbol is-inserted"
                    >
                      <Svg-icon iconType="Plus-circle"></Svg-icon>
                    </span>

                    <span class="flightEditPanel__content__symbol">
                      <Svg-icon iconType="Anchor-circle"></Svg-icon>
                    </span>

                    <span
                      class="flightEditPanel__content__label"
                      :class="{ skippedText: item.skipped == 1 }"
                      >{{ item.stop.name }}</span
                    >

                    <div
                      v-if="item.passed == 1"
                      class="flightEditPanel__content__status"
                    >
                      <span class="flightEditPanel__content__status__symbol">
                        <Svg-icon iconType="Check"></Svg-icon>
                      </span>
                    </div>

                    <span
                      class="flightEditPanel__content__time"
                      :class="{ skippedText: item.skipped == 1 }"
                      >{{ item.departure }}</span
                    >
                  </div>

                  <transition
                    appear
                    v-on:before-enter="beforeEnter"
                    v-on:after-enter="afterEnter"
                    v-on:leave="leave"
                  >
                    <div
                      v-if="!showOffcanvas"
                      class="flightEditPanel__item__actions"
                    >
                      <div class="flightEditPanel__actions__item">
                        <button
                          @click="handleInsertClick(index)"
                          :disabled="!canInsertHere(index)"
                          class="button button--theme-1 flightEditPanel__actions__button"
                        >
                          <span
                            class="button__symbol flightEditPanel__actions__button__symbol"
                          >
                            <Svg-icon iconType="Insert"></Svg-icon>
                          </span>
                        </button>
                      </div>

                      <div class="flightEditPanel__actions__item">
                        <button
                          @click="handleDeleteClick(item, index)"
                          :disabled="item.passed == 1 || item.skipped == 1"
                          class="button flightEditPanel__actions__button button--brand-2"
                        >
                          <span
                            class="button__symbol flightEditPanel__actions__button__symbol"
                          >
                            <Svg-icon iconType="Trash"></Svg-icon>
                          </span>
                        </button>
                      </div>
                    </div>
                  </transition>
                </div>
                <div
                  class="flightEditPanel__item__insertghost"
                  :class="{ active: showOffcanvas && activeIndex === index }"
                >
                  <div class="flightEditPanel__item__content">
                    <span class="flightEditPanel__content__symbol is-inserted">
                      <Svg-icon iconType="Plus-circle"></Svg-icon>
                    </span>

                    <span class="flightEditPanel__content__symbol">
                      <Svg-icon iconType="Anchor-circle"></Svg-icon>
                    </span>

                    <span class="flightEditPanel__content__label">{{
                      ghostName
                    }}</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <transition name="slideX" mode="in-out">
            <div v-if="showOffcanvas" class="flightEditPanel__offcanvas">
              <Flight-insert-form
                :activeIndex="activeIndex"
                :currentStop="$store.state.currentStops[activeIndex]"
              />
            </div>
          </transition>
        </div>
      </transition>
    </section>
  </Page-wrapper>
</template>
