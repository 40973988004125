<script>
import "./FlightStop.scss";
import FlightStop from "./FlightStop.js";

export default FlightStop;
</script>

<template>
  <div class="flightNext">
    <div class="flightNext__inner">
      <div class="flightNext__timeManager">
        <div :class="['flightNext__timeManager__inner', 'is-loaded']">
          <span v-if="visualPreviousStop" class="flightNext__timeManger__label">
            <span class="flightNext__nextlabel"
              >{{
                $store.state.currentStopIndex === -1
                  ? "HUIDIGE HALTE"
                  : "onderweg naar halte"
              }}
            </span>

            <span class="flightNext__currenttime">
              <Time />
            </span>
          </span>
          <span v-else class="flightNext__timeManger__label">
            <span class="flightNext__nextlabel">{{
              $store.state.currentStopIndex === -1
                ? "HUIDIGE HALTE"
                : "onderweg naar halte"
            }}</span>
          </span>

          <span class="flightNext__timeManger__label">
            <strong v-if="visualNextStop">{{
              timeOfDeparture(visualNextStop.departure)
            }}</strong>
            <strong v-else>Geen haltes beschikbaar?</strong>
          </span>

          <div
            v-if="visualNextStop"
            class="flightNext__timeManger__estimatedTime"
          >
            <span class="flightNext__timeManger__symbol">
              <Svg-icon iconType="Goal"></Svg-icon>
            </span>
            <!-- TIME LEFT TO ARRIVE AT CURRENT STOP -->
            <span class="flightNext__timeManger__estimatedTime__label">{{
              timeRemaining
            }}</span>
          </div>
        </div>
      </div>

      <transition name="fade">
        <div :class="['flightNext__theStop', { 'is-last': !visualSecondStop }]">
          <div class="flightPrev__theStop__outer">
            <div
              v-if="visualPreviousStop"
              class="flightPrev__theStop__outer__content"
            >
              <span class="flightPrev__theStop__outer__label">{{
                visualPreviousStop.stop.name
              }}</span>
            </div>
            <div v-else class="invisible flightPrev__theStop__outer__content">
              <span
                class="flightPrev__theStop__outer__label"
                v-if="visualNextStop"
                >{{ visualNextStop.stop.name }}</span
              >
            </div>
          </div>

          <div
            class="flightNext__theStop__inner"
            :class="{ animateIn: animateIn }"
          >
            <div class="flightNext__grid">
              <Svg-icon
                iconType="Anchor-circle"
                :class="{ invisible: !visualPreviousStop }"
              ></Svg-icon>
              <div
                class="dots"
                :class="{ invisible: !visualPreviousStop }"
              ></div>
              <div></div>
              <header class="flightNext__theStop__inner__header">
                <h3 class="heading heading--1 flightNext__theStop__title">
                  {{
                    visualNextStop
                      ? visualNextStop.stop.name
                      : "Fout in configuratie"
                  }}
                </h3>
              </header>
              <div></div>
              <div class="dots" :class="{ invisible: !visualSecondStop }"></div>
              <Svg-icon
                iconType="Anchor-circle"
                :class="{ invisible: !visualSecondStop }"
              ></Svg-icon>
            </div>

            <div class="flightNext__actions">
              <div class="flightNext__actions__item">
                <button
                  v-if="
                    false
                    /*!theSecondStop &&
                    $store.state.currentStopIndex >=
                      $store.state.currentStops.length - 1 &&
                    allItemsPassed */
                  "
                  @click="handleFinishClick"
                  class="button--withpadding button button--positive button--arrival"
                  :disabled="/*!$store.state.isCompleted*/ false"
                >
                  <span class="button__symbol">
                    <Svg-icon iconType="Check-circle"></Svg-icon>
                  </span>
                  <span class="button__label">Dienst beëindigen</span>
                </button>
                <button
                  v-else-if="$store.state.currentStopIndex === -1"
                  @click="handleStartClick"
                  class="button button--withpadding button--pairing-positive button--arrival"
                >
                  <span class="button__label">Start vaart</span>
                </button>
                <button
                  v-else-if="$store.state.currentStopIndex > -1"
                  @click="handleArrivedClick"
                  class="button button--arrival button--pairing-brand-2"
                >
                  <span class="button__symbol">
                    <Svg-icon iconType="Goal"></Svg-icon>
                  </span>

                  <span class="button__label">Aangekomen aan halte</span>
                </button>
              </div>
            </div>
          </div>

          <div v-if="visualSecondStop" class="flightNext__theStop__outer">
            <div class="flightNext__theStop__outer__content">
              <span class="flightNext__theStop__outer__label">{{
                visualSecondStop.stop.name
              }}</span>
              <span
                class="flightNext__theStop__outer__time"
                v-if="visualSecondStop"
                >{{ timeOfDeparture(visualSecondStop.departure) }}</span
              >
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
