<template>
	<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 25.3 18.9" xml:space="preserve">
		<g>
			<path d="M23.3,8.9c-0.4-2.2-2.2-4-4.5-4.1c0-0.2,0.1-0.4,0.1-0.6c0-2.3-1.9-4.3-4.3-4.3c-2.1,0-3.8,1.5-4.2,3.4
				C9.1,3.7,8,4.6,7.4,5.8c0.8-0.8,1.9-1.4,3.1-1.4c0.5,0,1,0.1,1.5,0.3c0.1-0.1,0.1-0.3,0.1-0.4c0-1.4,1.1-2.4,2.4-2.4S17,2.9,17,4.2
				s0.8,2.3,1.5,2.4c1.7,0.1,3,1.4,3,3c0,0.5,0.4,0.9,0.9,0.9c0.6,0,1,0.5,1,1c0,0.6-0.4,1-1,1l-3.4,0c0.1,0.2,0.1,0.5,0.2,0.7
				c0.6,0.2,1.2,0.6,1.5,1.1h1.7c1.6,0,2.8-1.3,2.8-2.8C25.3,10.4,24.4,9.3,23.3,8.9z"/>
			<path d="M19.2,13.3c-0.4-2.2-2.2-4-4.5-4.1c0-0.2,0.1-0.4,0.1-0.6c0-2.3-1.9-4.3-4.3-4.3
				c-2.1,0-3.8,1.5-4.2,3.4c-1.8,0.4-3.2,1.8-3.4,3.7C1.2,11.9,0,13.4,0,15.2c0,2.1,1.7,3.7,3.7,3.7h1.8l10.1,0h2.7
				c1.6,0,2.8-1.3,2.8-2.8C21.2,14.8,20.4,13.7,19.2,13.3z M7.5,18.9l4.4,0L7.5,18.9z M18.4,17L6.6,17.1H3.8c-1.1,0-1.9-0.9-1.9-1.9
				s0.9-1.9,1.9-1.9c0.5,0,0.9-0.4,0.9-0.9c0-0.1,0-0.3,0-0.3c0-1.4,1.1-2.4,2.4-2.4C7.6,9.6,8,9.1,8,8.6c0-1.4,1.1-2.4,2.4-2.4
				s2.4,1.1,2.4,2.4s-1.1,2.4-2.4,2.4c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9c1.5,0,2.8-0.8,3.6-2c0.1,0.1,0.2,0.1,0.3,0.1
				c1.7,0,3,1.4,3,3c0,0.5,0.4,0.9,0.9,0.9c0.6,0,1,0.5,1,1C19.4,16.6,18.9,17,18.4,17z"/>
		</g>
	</svg>
</template>

<script>
	export default {
		name: 'clouds'
	}
</script>