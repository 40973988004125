import axios from "./index";

export default {
  create(formData) {
    return axios
      .post("/checklists/create", formData)
      .then((response) => response);
  },

  getCaptain(captainId) {
    return axios.get(`/getName/${captainId}`);
  },

  detail(checklistId) {
    return axios.get(`/checklists/detail?checklist=${checklistId}`);
  },

  update(formData) {
    return axios.post("checklists/update", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },

  complete(formData) {
    return axios
      .post("/checklists/complete", formData)
      .then((response) => response);
  },
};
