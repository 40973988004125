export default {
  props: {
    viewData: Object,
  },

  data() {
    return {
      theMalfunction: this.viewData.malfunction,
      theFlightInfo: this.viewData.flightInfo,
    };
  },

  created() {
    this.theMalfunction.urgent = 1;
  },

  methods: {
    handlePhotoClick() {
      const viewData = {
        title: this.theMalfunction.category,
        subtitle: "Storing",
        remarks: this.theMalfunction.remarks,
        image: this.theMalfunction.image,
      };

      this.emitter.emit("togglePhotoViewer", [true, viewData]);
    },

    formatDateOfMalfunction(malfunction) {
      let theDate = malfunction.created_at.substring(
        0,
        malfunction.created_at.length - 8
      );

      let [yyyy, mm, dd] = theDate.split("-");

      theDate = `${yyyy}/${mm}/${dd}`;

      return theDate;
    },

    formatTimeOfMalfunction(malfunction) {
      let theTime = malfunction.created_at.substring(
        10,
        malfunction.created_at.length - 3
      );

      return theTime;
    },
  },
};
