<script>
import "./WhoopsError.scss";
import Whoops from "./WhoopsError.js";

export default Whoops;
</script>

<template>
  <div class="whoops">
    <header class="whoops__header">
      <span class="whoops__symbol">
        <Svg-icon :iconType="theContent.icon" />
      </span>

      <span class="whoops__header__subtitle">{{ theContent.subtitle }}</span>
      <h3 class="heading heading--3 whoops__header__title">
        {{ theContent.title }}
      </h3>
    </header>

    <div class="whoops__content">
      <p>{{ theContent.body }}</p>
    </div>

    <div class="whoops__submit">
      <button
        @click="handleClick()"
        class="button button--pairing-neutrals-5 whoops__theSubmit"
      >
        <span class="button__label">Sluiten</span>
      </button>
    </div>
  </div>
</template>
