<script>
import "./SvgIcon.scss";
import SvgIcon from "./SvgIcon.js";

export default SvgIcon;
</script>

<template>
  <div class="svg-icon">
    <component :is="iconType" :fill="iconColor" />
  </div>
</template>
