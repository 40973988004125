<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 18 18"
    xml:space="preserve"
  >
    <g>
      <path
        d="M9,0C4,0,0,4,0,9s4,9,9,9s9-4,9-9S14,0,9,0z M16.2,6.1l-2.5,0.8c-0.3-0.6-0.6-1.1-1.1-1.5 c-0.4-0.4-1-0.8-1.5-1.1l0.8-2.5c1,0.4,1.8,1,2.6,1.7C15.2,4.3,15.8,5.2,16.2,6.1L16.2,6.1z M9,12.9c-2.1,0-3.9-1.7-3.9-3.9 S6.9,5.1,9,5.1s3.9,1.7,3.9,3.9S11.1,12.9,9,12.9z M3.5,3.5c0.8-0.8,1.6-1.3,2.6-1.7l0.8,2.5C6.3,4.6,5.8,4.9,5.4,5.4 c-0.5,0.5-0.8,1-1.1,1.5L1.8,6.1C2.2,5.2,2.8,4.3,3.5,3.5L3.5,3.5z M1.8,11.9l2.5-0.8c0.3,0.6,0.6,1.1,1.1,1.5 c0.5,0.4,1,0.8,1.5,1.1l-0.8,2.5c-1-0.4-1.8-1-2.6-1.7C2.8,13.7,2.2,12.8,1.8,11.9L1.8,11.9z M14.5,14.5c-0.8,0.8-1.6,1.3-2.6,1.7 l-0.8-2.5c0.6-0.3,1.1-0.6,1.5-1.1c0.5-0.5,0.8-1,1.1-1.5l2.5,0.8C15.8,12.8,15.2,13.7,14.5,14.5L14.5,14.5z"
      />
    </g>
  </svg>
</template>
