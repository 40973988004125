import Stewards from "../../../api/Stewards";

export default {
  data() {
    return {
      LOCAL_URL: "",
      isLoading: false,
      chosenCrew: [],
      showCrewForm: false,
      crewFilters: [],
      currentFilter: "A-Z",
      slider: {
        crewMembers: [],
        dir: null,
        trackOffset: 0,
        goToPrevSlide: false,
        goToNextSlide: true,
        currentSlide: null,
        slideIndex: 0,
      },
    };
  },
  created() {
    const API_URL = "https://swetscockpitapp.nl/storage";
    const TEST_URL = "https://test.swetscockpitapp.nl/storage";

    this.LOCAL_URL =
      document.location.host.indexOf("localhost") == 0
        ? TEST_URL
        : document.location.host.indexOf("test.") == 0
        ? TEST_URL
        : API_URL;

    if (!this.$store.state.allCrew.length && this.$store.state.isOnline) {
      //no crew get them
      this.getStewards();
    } else {
      this.crewToSlider();
    }

    this.emitter.on("crew--toggleShowForm", (value) => {
      this.toggleCrewForm(value);
    });

    this.emitter.on("crew--addedSteward", () => {
      if (this.$store.state.isOnline) {
        this.getStewards();
      }
    });

    this.emitter.emit(
      "start--set-disabled",
      !this.$store.state.flightSettings.crew.length
    );
  },
  methods: {
    stewardPic(url) {
      return `${this.LOCAL_URL}/${url}`;
    },
    checkIfChecked(item) {
      return this.$store.state.flightSettings.crew.filter(
        (crew) => crew.id === item.id
      ).length;
    },

    getStewards() {
      this.isLoading = true;
      Stewards.overview(this)
        .then((r) => this.onSuccess(r))
        .catch((r) => {
          this.onFail(r);
        });
    },

    onSuccess(r) {
      let crew = r.data.body;

      this.$store.commit({
        type: "setAllCrew",
        payload: crew.sort((a, b) => {
          const aName = a.firstname.toLowerCase();
          const bName = b.firstname.toLowerCase();

          if (aName < bName) {
            return -1;
          }

          if (aName > bName) {
            return 1;
          }

          return 0;
        }),
      });

      this.crewToSlider();

      this.isLoading = false;
    },

    onFail(r) {
      this.isLoading = false;

      let theMessage = {};
      theMessage.status = r.status;
      theMessage.title = r.data.message;
      theMessage.subtitle = "Oeps?!";
      theMessage.body = "";
      theMessage.icon = "whoops";

      this.emitter.emit("toggleOffcanvas", [true, "Whoops", theMessage]);
    },

    crewToSlider() {
      const crew = this.$store.state.allCrew;

      for (let key in crew) {
        this.slider.crewMembers.push(crew[key]);

        let firstLetter = crew[key].firstname.slice(0, 1).toUpperCase();
        this.crewFilters.indexOf(firstLetter) == -1 &&
          this.crewFilters.push(firstLetter);
      }

      this.crewFilters = this.crewFilters.sort();

      this.resetSlider();
    },

    handleChange(item) {
      let currentCrew = [...this.$store.state.flightSettings.crew];
      if (currentCrew.filter((member) => member.id === item.id).length) {
        //exists so remove
        currentCrew = currentCrew.filter((member) => member.id !== item.id);
      } else {
        //not present so add
        currentCrew.push(item);
      }

      this.$store.commit({
        type: "setFlightSettings",
        payload: { crew: [...currentCrew] },
      });

      //is last btn so check the other values are okey
      this.emitter.emit("start--set-disabled", !currentCrew.length);
    },

    filterIt() {
      this.resetSlider();
      this.slideIt();

      if (this.currentFilter === "A-Z") {
        this.slider.crewMembers = this.$store.state.allCrew;
      } else {
        this.slider.crewMembers = this.$store.state.allCrew.filter(
          (item) =>
            item.firstname
              .slice(0, 1)
              .toUpperCase()
              .indexOf(this.currentFilter) > -1
        );
      }
    },

    toggleCrewForm(value) {
      this.showCrewForm = value;

      this.emitter.emit("start--show-btns", !this.showCrewForm);

      this.resetSlider();
    },

    handleSliderClick(dir) {
      this.slider.dir = dir;

      // check if there's a next/prev slide
      if (
        this.slider.dir == "next" &&
        this.$refs.crew__slide[this.slider.slideIndex].nextElementSibling
      ) {
        this.slider.slideIndex++;

        // update currentSlide
        this.slider.currentSlide =
          this.$refs.crew__slide[this.slider.slideIndex];

        this.slideIt();
      }

      if (
        this.slider.dir == "prev" &&
        this.$refs.crew__slide[this.slider.slideIndex].previousElementSibling
      ) {
        this.slider.slideIndex--;

        // update currentSlide
        this.slider.currentSlide =
          this.$refs.crew__slide[this.slider.slideIndex];

        this.slideIt();
      }
    },

    handleSwipe() {
      return (direction, event) => {
        if (direction == "left") {
          this.slider.dir = "next";
        } else {
          this.slider.dir = "prev";
        }

        // check if there's a next/prev slide
        if (
          this.slider.dir == "next" &&
          this.$refs.crew__slide[this.slider.slideIndex].nextElementSibling
        ) {
          this.slider.slideIndex++;

          // update currentSlide
          this.slider.currentSlide =
            this.$refs.crew__slide[this.slider.slideIndex];

          this.slideIt();
        }

        if (
          this.slider.dir == "prev" &&
          this.$refs.crew__slide[this.slider.slideIndex].previousElementSibling
        ) {
          this.slider.slideIndex--;

          // update currentSlide
          this.slider.currentSlide =
            this.$refs.crew__slide[this.slider.slideIndex];

          this.slideIt();
        }
      };
    },

    slideIt() {
      let theTrack = this.$refs.crew__sliderTrack;

      // get width of slide
      let theWidth = this.$refs.crew__slide[0].clientWidth;

      // go left or right
      this.slider.dir == "next" &&
        (this.slider.trackOffset = this.slider.trackOffset - theWidth);
      this.slider.dir == "prev" &&
        (this.slider.trackOffset = this.slider.trackOffset + theWidth);

      theTrack.style.left = this.slider.trackOffset + "px";

      // check prev/next btn
      this.checkControls();
    },

    checkControls() {
      this.slider.dir == "next" &&
        !this.slider.currentSlide.nextElementSibling &&
        (this.slider.goToNextSlide = false);
      this.slider.dir == "next" &&
        this.slider.currentSlide.previousElementSibling &&
        (this.slider.goToPrevSlide = true);

      this.slider.dir == "prev" &&
        this.slider.currentSlide.nextElementSibling &&
        (this.slider.goToNextSlide = true);
      this.slider.dir == "prev" &&
        !this.slider.currentSlide.previousElementSibling &&
        (this.slider.goToPrevSlide = false);
    },

    resetSlider() {
      this.slider.dir = null;
      this.slider.trackOffset = 0;
      this.slider.goToPrevSlide = false;
      this.slider.goToNextSlide = true;
      this.slider.currentSlide = null;
      this.slider.slideIndex = 0;
    },
  },
  computed: {
    selectedFilter: {
      get() {
        return this.currentFilter;
      },
      set(newValue) {
        this.currentFilter = newValue;
        this.filterIt();
      },
    },
  },
  watch: {
    "$store.state.allCrew"() {
      this.crewToSlider();
    },
  },
};
