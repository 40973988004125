<script>
import "./Weather.scss";
import Weather from "./Weather.js";

export default Weather;
</script>

<template>
  <transition name="fade-in" mode="out-in">
    <div class="weather" v-if="$store.state.weather">
      <span class="weather__symbol" v-if="$store.state.weather.icon">
        <Svg-icon :iconType="$store.state.weather.icon"></Svg-icon>
      </span>
      <span class="weather__label">
        {{ $store.state.weather.temperature }}° &bullet;
        {{ $store.state.weather.state }}
      </span>
    </div>
  </transition>
</template>
