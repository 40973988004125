export default {
  computed: {
    isSecondToLast() {
      return (
        this.$store.state.currentStops.length - 1 ===
        this.$store.state.currentStopIndex + 1
      );
    },
    nextStopExists() {
      return (
        this.$store.state.currentStops.length - 1 >
        this.$store.state.currentStopIndex + 1
      );
    },
    nextStopName() {
      return this.$store.state.currentStops[
        this.$store.state.currentStopIndex + 1
      ].stop.name;
    },
  },
};
