import axios from "./index";

export default {
  create(formData, vueThis) {
    return axios.post("/flights/create", formData).then((r) => r);
  },

  detail(flight) {
    return axios.get(`/flights/detail?flight=${flight}`);
  },

  weather(data, vueThis) {
    return axios
      .get(`/flights/weather?lat=${data.lat}&lon=${data.lng}&flight=${data.id}`)
      .then((r) => r.data.body);
  },

  stop(formData) {
    return axios.post("/flights/stop", formData);
  },

  incompletes(shift, vueThis) {
    return axios.get(`/flights/not-flying?shift=${shift}`).then((r) => r);
  },

  allincompletes(vueThis) {
    return axios.get(`/flights/all-not-flying`).then((r) => r);
  },

  update(formData) {
    return axios.post("/flights/update", formData);
  },

  minimal(flight) {
    return axios.get(`/flights/minimal?flight=${flight}`);
  },
};
