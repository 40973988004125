export default {
  props: {
    viewData: Object,
  },

  data() {
    return {
      isLoading: true,
      checklistIsCompleted: this.viewData.checklistIsCompleted,
      hasEndChecklist: false,
      canLogout: true,
      theContent: {
        title: "Ben je zeker",
        body: "",
      },
    };
  },

  created() {
    this.setCanLogout();
    this.viewData && this.setContent();
  },

  computed: {
    allItemsSend() {
      return (
        //done sending stops
        this.$store.state.currentStopsSendInfo.succesfullySend.length >=
          this.$store.state.currentStops.length &&
        !this.$store.state.currentStopsSendInfo.updated &&
        !this.$store.state.currentStopsSendInfo.isSending &&
        //done sending the checklist
        this.$store.state.checklistIsSend &&
        //done sending new malfunctions
        !this.$store.state.newMalfunctionSendInfo.needsSending.length &&
        //done sending read notifications
        !this.$store.state.readNotificationSendInfo.needsSending.length
      );
    },
  },

  methods: {
    closeClick() {
      this.emitter.emit("toggleOffcanvas", false);
    },

    handleClick() {
      location.href = "/checklist";
    },

    handleLogoutClick() {
      //clear the old store if everything is send
      if (
        //check if the stops are updated or sending
        this.$store.state.currentStopsSendInfo.succesfullySend.length >=
          this.$store.state.currentStops.length &&
        //check if check if the checklist is send
        this.$store.state.checklistIsCompleted &&
        //check if all the malfunctions are send
        !this.$store.state.newMalfunctionSendInfo.needsSending.length &&
        //check if all the read notifications are are send
        !this.$store.state.readNotificationSendInfo.needsSending.length &&
        //check flight settings is sending
        this.$store.state.flightSettings.isSend
      ) {
        localStorage.removeItem("oldStore");
      }

      localStorage.removeItem("store");
      localStorage.removeItem("token");
      localStorage.removeItem("concession");

      this.$store.commit({
        type: "clearStore",
        payload: {},
      });

      this.emitter.emit("toggleOffcanvas", false);
      this.$router.push("/");
    },

    setContent() {
      if (!this.checklistIsCompleted && this.hasEndChecklist) {
        //Endchecklist gets created when the flight is completed, then this error can show.
        this.theContent.title = "Checklist onvoltooid";
        this.theContent.body =
          "Het blijkt dat de checklist nog niet is voltooid.";

        this.isLoading = false;
        return false;
      }

      if (!this.checklistIsCompleted && !this.$store.state.isCompleted) {
        //This cannot be possible. as the endchecklist can only be created when the flight is completed
        this.isLoading = false;
        return false;
      }

      //If the flight is not completed show the error below.
      !this.$store.state.isCompleted &&
        (this.theContent.title = "Vaart onvoltooid");
      !this.$store.state.isCompleted &&
        (this.theContent.body =
          "Het blijkt dat de huidige vaart nog niet is voltooid.");
    },

    onSuccessFlightStop(response) {
      localStorage.removeItem("store");
      localStorage.removeItem("token");
      localStorage.removeItem("concession");

      this.$store.commit({
        type: "clearStore",
        payload: {},
      });

      this.isLoading = false;

      this.emitter.emit("toggleOffcanvas", false);

      this.$router.push("/");
    },

    onFailFlightStop(response) {
      this.isLoading = false;

      let theMessage = {};
      theMessage.status = response.status;
      theMessage.title = response.message;
      theMessage.subtitle = "Oeps?!";
      theMessage.body = "Logout error.";
      theMessage.icon = "radar";

      this.emitter.emit("toggleOffcanvas", [true, "Whoops", theMessage]);
    },

    setCanLogout() {
      //
      if (
        //check if the stops are updated or sending
        this.$store.state.currentStopsSendInfo.updated ||
        this.$store.state.currentStopsSendInfo.isSending ||
        //check if check if the checklist is sending or updated
        this.$store.state.checklistIsUpdated ||
        this.$store.state.checklistIsSending ||
        //check if all the malfunctions are send
        this.$store.state.newMalfunctionSendInfo.needsSending.length ||
        //check if all the read notifications are are send
        this.$store.state.readNotificationSendInfo.needsSending.length ||
        //check flight settings is sending
        this.$store.state.flightSettings.isSending
      ) {
        this.canLogout = false;
      } else {
        this.canLogout = true;
      }
    },
  },
  watch: {
    "$store.state.isOnline"() {
      this.setCanLogout();
    },
    "$store.state.flightSettings"() {
      this.setCanLogout();
    },
    "$store.state.checklistIsUpdated"() {
      this.setCanLogout();
    },
    "$store.state.checklistIsSending"() {
      this.setCanLogout();
    },
    "$store.state.checklistIsSend"() {
      this.setCanLogout();
    },
    "$store.state.currentStopsSendInfo"() {
      this.setCanLogout();
    },
    "$store.state.newMalfunctionSendInfo"() {
      this.setCanLogout();
    },
    "$store.state.readNotificationSendInfo"() {
      this.setCanLogout();
    },
  },
};
