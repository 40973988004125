import axios from "./index";

export default {
  overview(shipId, vueThis) {
    return axios
      .get(`malfunctions/overview?ship=${shipId}`)
      .then((response) => response);
  },

  create(formData, vueThis) {
    return axios
      .post("/malfunctions/create", formData)
      .then((response) => response);
  },
};
