import Concession from "../../../api/Concession";

export default {
  props: {
    dateType: {
      type: String, // DD/MM/YYYY or fullname
    },
  },

  data() {
    return {
      theDate: {
        number: null,
        name: String,
        fullname: String,
        date: null,
        month: String,
        format: null,
      },
      dayNames: ["Zo.", "Ma.", "Di.", "Wo.", "Do.", "Vr.", "Za."],
      fullDayNames: [
        "Zondag",
        "Maandag",
        "Dinsdag",
        "Woensdag",
        "Donderdag",
        "Vrijdag",
        "Zaterdag",
      ],
      fullMonthNames: [
        "Januari",
        "Februari",
        "Maart",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Augustus",
        "September",
        "Oktober",
        "November",
        "December",
      ],
    };
  },

  computed: {
    date() {
      return this.formatDate();
    },
  },

  created() {
    this.setDates();
  },

  methods: {
    setDates() {
      const now = new Date();

      this.theDate.number = this.addLeadingZero(now.getDate());
      this.theDate.name = this.dayNames[now.getDay()];
      this.theDate.fullname = this.fullDayNames[now.getDay()];
      this.theDate.date = `${this.addLeadingZero(now.getDate())}/${
        now.getMonth() + 1
      }/${now.getFullYear()}`;
      this.theDate.month = this.fullMonthNames[now.getMonth()];
    },

    onSucces(r) {
      const response = JSON.parse(r.data.body);

      this.theDate.number = response.number;
      this.theDate.name = response.name;
      this.theDate.fullname = response.fullname;
      this.theDate.date = response.date;
      this.theDate.month = response.month;

      this.formatDate();
    },

    formatDate() {
      switch (this.dateType) {
        case "DD/MM/YYYY":
          this.theDate.format = this.theDate.date;
          break;

        case "nameOfDay+DD/MM/YYYY":
          this.theDate.format = this.theDate.name + " " + this.theDate.date;
          break;

        case "nameOfDay":
          this.theDate.format = this.theDate.fullname;
          break;

        case "nameOfMonth":
          this.theDate.format = this.theDate.month;
          break;

        default:
          this.theDate.format =
            this.theDate.name +
            " " +
            this.theDate.number +
            " " +
            this.theDate.month;
      }

      return this.theDate.format;
    },
    addLeadingZero(int) {
      if (int <= 9) {
        return `0${int}`;
      }

      return int;
    },
  },
};
