<script>
import "./LogoutConfirm.scss";
import LogoutConfirm from "./LogoutConfirm.js";

export default LogoutConfirm;
</script>

<template>
  <div class="logoutConfirm">
    <header class="logoutConfirm__header">
      <span class="logoutConfirm__header__subtitle">Logout</span>
      <h2 class="heading heading--3 logoutConfirm__header__title">
        {{ theContent.title }}
      </h2>
    </header>

    <div class="logoutConfirm__content">
      <p>{{ theContent.body }}</p>
    </div>

    <div class="logoutConfirm__actions">
      <div class="logoutConfirm__action__item">
        <button
          @click="handleLogoutClick"
          :class="[
            'button',
            {
              'button--pairing-neutrals-4':
                !checklistIsCompleted || !$store.state.isCompleted,
            },
            {
              'button--brand-2':
                checklistIsCompleted && $store.state.isCompleted,
            },
          ]"
        >
          <span class="button__symbol">
            <Svg-icon iconType="Logout-icon"></Svg-icon>
          </span>
          <span class="button__label">{{
            true ? "Uitloggen" : "Bezig met data versturen"
          }}</span>
        </button>
      </div>

      <div
        v-if="!$store.state.isCompleted && checklistIsCompleted"
        class="logoutConfirm__actions__item"
      >
        <router-link
          @click="closeClick"
          to="/flight"
          class="button button--brand-2"
        >
          <span class="button__symbol">
            <Svg-icon iconType="Ship-front"></Svg-icon>
          </span>
          <span class="button__label">Naar huidige vaart</span>
        </router-link>
      </div>

      <div v-if="hasEndChecklist" class="logoutConfirm__actions__item">
        <router-link
          @click="handleClick"
          to="/checklist"
          class="button button--brand-2"
        >
          <span class="button__symbol">
            <Svg-icon iconType="Checklist-flat"></Svg-icon>
          </span>
          <span class="button__label">Naar checklist</span>
        </router-link>
      </div>
    </div>
  </div>
</template>
