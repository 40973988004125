import Flights from "../../../api/Flights";

export default {
  data() {
    return {
      isLoading: true,
      flightInfo: false,
    };
  },

  created() {
    this.getFlightInfo();

    this.wipeCheckList();
  },

  computed: {
    departured() {
      return (departured) => departured.substring(0, departured.length - 3);
    },
    hasStopChecklist() {
      return (
        this.$store.state.flightSettings.shift.has_end_checklist &&
        !this.$store.state.checklistIsSend
      );
    },
  },

  methods: {
    wipeCheckList() {
      if (this.hasStopChecklist) {
        //has a stop checklist so reset the start checklist

        this.$store.commit({
          type: "resetCheckList",
          payload: true,
        });
      }
    },
    handleNotificationsClick(theNotification) {
      const viewData = {
        notification: theNotification,
        isNew: false,
      };

      this.emitter.emit("toggleOffcanvas", [
        true,
        "NotificationsDetail",
        viewData,
      ]);
    },

    getFlightInfo() {
      Flights.detail(this.$store.state.flightSettings.id)
        .then((response) => this.onSuccess(response))
        .catch((e) => {
          this.onFail(e);
        });
    },

    onSuccess(response) {
      this.flightInfo = JSON.parse(response.data.body.flight);
    },

    onFail(response) {
      let theMessage = {};
      theMessage.status = response.status;
      theMessage.title = response.data.message;
      theMessage.subtitle = "Oeps?!";
      theMessage.body = "";
      theMessage.icon = "whoops";

      this.emitter.emit("toggleOffcanvas", [true, "Whoops", theMessage]);
    },

    getTimeDiff(item) {
      const [hh, mm, ss] = item.departure.split(":");

      const [hh1, mm1, ss1] = item.departured.split(":");

      return (this.timeRemaining = this.diff(`${hh}:${mm}`, `${hh1}:${mm1}`));
    },

    diff(start, end) {
      start = start.split(":");
      end = end.split(":");
      var startDate = new Date(0, 0, 0, start[0], start[1], 0);
      var endDate = new Date(0, 0, 0, end[0], end[1], 0);
      var diff = endDate.getTime() - startDate.getTime();
      var hours = Math.floor(diff / 1000 / 60 / 60);
      diff -= hours * 1000 * 60 * 60;
      var minutes = Math.floor(diff / 1000 / 60);

      return (
        (hours < 9 ? "0" : "") +
        hours +
        ":" +
        (minutes < 9 ? "0" : "") +
        minutes
      );
    },

    formatTimeOfNotification(notification) {
      let theTime = notification.created_at.substring(
        10,
        notification.created_at.length - 3
      );

      return theTime;
    },
  },
};
