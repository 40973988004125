const importAllVue = (app) => {
  	app.component("App", require("./App/App.vue").default); 
	app.component("Checklist", require("./Checklist/Checklist.vue").default); 
	app.component("Create-malfunction", require("./CreateMalfunction/CreateMalfunction.vue").default); 
	app.component("Dashboard", require("./Dashboard/Dashboard.vue").default); 
	app.component("Flight", require("./Flight/Flight.vue").default); 
	app.component("Flight-edit", require("./FlightEdit/FlightEdit.vue").default); 
	app.component("Flight-overview", require("./FlightOverview/FlightOverview.vue").default); 
	app.component("Help", require("./Help/Help.vue").default); 
	app.component("Login", require("./Login/Login.vue").default); 
	app.component("Malfunctions", require("./Malfunctions/Malfunctions.vue").default); 
	app.component("Notifications", require("./Notifications/Notifications.vue").default); 
	app.component("Start", require("./Start/Start.vue").default); 
	app.component("Uploading-data", require("./UploadingData/UploadingData.vue").default); 
;
};

export default importAllVue;
