export default {
  props: {
    iconType: {
      type: String,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
};
