<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 25.2 25.2"
    xml:space="preserve"
  >
    <g>
      <path
        d="M7.4,0C3.5,0,0,3.5,0,7.5c0,3.9,3.5,7.4,7.4,7.4c0.4,0,0.8,0,1.2-0.1c0.6-3,3.1-5.6,6.1-6.1 c0.1-0.4,0.1-0.8,0.1-1.2C14.9,3.6,11.3,0,7.4,0L7.4,0z M7.4,3.6c0.6,0,1.1,0.5,1.1,1.1v1.7h1.7c0.6,0,1.1,0.5,1.1,1.1 s-0.5,1.1-1.1,1.1H8.5v1.7c0,0.6-0.5,1.1-1.1,1.1c-0.6,0-1.1-0.5-1.1-1.1V8.5H4.6C4.1,8.5,3.6,8,3.6,7.4s0.5-1.1,1.1-1.1h1.7V4.6 C6.4,4.1,6.9,3.6,7.4,3.6L7.4,3.6z"
      />
      <path
        d="M17.8,10.3c-3.9,0-7.4,3.5-7.4,7.4c0,3.9,3.5,7.4,7.4,7.4c3.9,0,7.4-3.5,7.4-7.4 C25.2,13.9,21.7,10.3,17.8,10.3z M15,16.7h5.6c0.6,0,1.1,0.5,1.1,1.1c0,0.6-0.5,1.1-1.1,1.1H15c-0.6,0-1.1-0.5-1.1-1.1 C13.9,17.2,14.4,16.7,15,16.7L15,16.7z"
      />
    </g>
  </svg>
</template>
