<script>
import "./SideInfo.scss";
import SideInfo from "./SideInfo.js";

export default SideInfo;
</script>

<template>
  <div class="sideInfo">
    <div class="sideInfo__inner">
      <transition name="fade">
        <div class="sideInfo__info">
          <div class="sideInfo__info__inner">
            <div class="sideInfo__date">
              <Date dateType="fullname"></Date>
            </div>

            <div class="sideInfo__time">
              <Time />
            </div>

            <div class="sideInfo__weather">
              <Weather />
            </div>
          </div>
        </div>
      </transition>

      <div class="sideInfo__recap">
        <Recap />
      </div>
    </div>
  </div>
</template>
