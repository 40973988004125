<template>
	<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 16.1 15.5" xml:space="preserve">
		<g>
			<path d="M12.9,0c0.5,0,0.8,0.3,0.8,0.8l0,0v0.8h0.8c0.4,0,0.8,0.4,0.8,0.8l0,0V6c-1.1-0.9-2.5-1.4-4-1.4
				c-3.5,0-6.4,2.8-6.4,6.2c0,1.1,0.3,2.2,0.9,3.1l0,0H0.8c-0.4,0-0.8-0.3-0.8-0.8l0,0V2.3c0-0.5,0.4-0.8,0.8-0.8l0,0h1.6V0.8
				C2.4,0.3,2.7,0,3.2,0S4,0.3,4,0.8l0,0v0.8h8V0.8C12.1,0.3,12.4,0,12.9,0z"/>
			<path d="M11.3,6.2c-2.7,0-4.8,2.1-4.8,4.6c0,2.6,2.2,4.6,4.8,4.6c2.7,0,4.8-2.1,4.8-4.6C16.1,8.3,13.9,6.2,11.3,6.2z
				 M12.9,11.6h-1.6c-0.5,0-0.8-0.3-0.8-0.8V8.5c0-0.5,0.3-0.8,0.8-0.8s0.8,0.3,0.8,0.8v1.5h0.8c0.5,0,0.8,0.3,0.8,0.8
				S13.3,11.6,12.9,11.6z"/>
		</g>
	</svg>
</template>