export default {
  props: {
    viewData: Object,
  },

  data() {
    return {
      isLoading: true,
      photoEl: null,
      inputFields: {
        remarks: "",
        image: "",
      },
      isInvalid: false,
    };
  },

  created() {
    // handle file upload
    this.emitter.on("fileIsUploaded", (isFileUploaded) => {
      if (!isFileUploaded) return false;

      this.$nextTick(() => this.onPhotoIsUploaded());
    });

    if (this.viewData.remark) {
      this.inputFields.remarks = this.viewData.remark;
    }
  },

  mounted() {
    this.isLoading = false;
    this.photoEl = this.$refs.photo;
  },

  methods: {
    handlePhotoClick() {
      this.emitter.emit("openCamera", true);
    },

    onPhotoIsUploaded() {
      this.isLoading = false;

      this.inputFields.image = this.$store.state.tmpImg;
      this.photoEl && (this.photoEl.src = this.$store.state.tmpImg);
    },

    handleDeleteClick() {
      this.inputFields.image = null;
      this.$store.commit({
        type: "setTmpImg",
        payload: null,
      });
    },

    handleSubmit() {
      const viewData = {
        entry: this.viewData,
        img: this.$store.state.tmpImg,
        remark: this.inputFields.remarks,
        timeStamp: this.getTimeStamp(),
      };

      // go to confirm view
      this.emitter.emit("toggleOffcanvas", [true, "CommentsConfirm", viewData]);
    },

    getTimeStamp() {
      function addZero(i) {
        i < 10 && (i = "0" + i);
        return i;
      }

      const today = new Date();
      const time =
        addZero(today.getHours()) + "u" + addZero(today.getMinutes());

      return time;
    },

    handleEmptyFields() {
      this.isInvalid = true;

      return false;
    },
  },
};
