export default {
  props: {
    title: String,
    isLoading: Boolean,
    noPadding: {
      type: Boolean,
      default: false,
    },
  },
};
