import { sendCurrentStops } from "../../../functions/SendCurrentStops";
import TimeStamp from "../../../functions/TimeStamp";

export default {
  props: {
    viewData: Object,
  },

  data() {
    return {
      stayOnPage: this.viewData.stayOnPage ? this.viewData.stayOnPage : false,
      theTimeStamp: this.viewData.timeStamp,
      nextStop: this.viewData.nextStop,
      passengers: {
        adding: 0,
        current: 0,
        removing: 0,
      },
      bikes: {
        adding: 0,
        current: 0,
        removing: 0,
      },
    };
  },
  created() {
    const adding_people = isNaN(this.$store.state.passengers.adding_people)
      ? 0
      : this.$store.state.passengers.adding_people;
    const removing_people = isNaN(this.$store.state.passengers.removing_people)
      ? 0
      : this.$store.state.passengers.removing_people;

    const adding_bikes = isNaN(this.$store.state.passengers.adding_bikes)
      ? 0
      : this.$store.state.passengers.adding_bikes;

    const removing_bikes = isNaN(this.$store.state.passengers.removing_bikes)
      ? 0
      : this.$store.state.passengers.removing_bikes;

    this.passengers = {
      ...this.passengers,
      current:
        this.$store.state.passengers.current_people +
        adding_people +
        removing_people,
      adding: adding_people,
      removing: removing_people,
    };
    this.bikes = {
      ...this.bikes,
      current:
        this.$store.state.passengers.current_bikes +
        adding_bikes +
        removing_bikes,
      adding: adding_bikes,
      removing: removing_bikes,
    };

    this.emitter.on(`counter--update`, this.updatePassengers);

    //submit the last results
    this.handleSubmit();
  },
  computed: {
    timeStamp() {
      if (this.theTimeStamp) {
        return this.theTimeStamp.slice(0, -3);
      }
      return "";
    },
    maxIncommingPassengers() {
      return (
        this.$store.state.passengers.max_people -
        this.$store.state.passengers.current_people -
        this.passengers.removing
      );
    },
    maxIncommingBikes() {
      return (
        this.$store.state.passengers.max_bikes -
        this.$store.state.passengers.current_bikes -
        this.bikes.removing
      );
    },
  },

  methods: {
    hasStopChecklist() {
      return (
        this.$store.state.flightSettings.shift.has_end_checklist &&
        !this.$store.state.checklistIsSend
      );
    },
    updatePassengers(data) {
      if (data.type === "passengers") {
        //passengers
        if (data.id === "Opstappers") {
          //add
          this.passengers = { ...this.passengers, adding: data.value };
        } else {
          //remove
          this.passengers = { ...this.passengers, removing: data.value };
        }
      } else {
        //bikes
        if (data.id === "Opstappers") {
          //add
          this.bikes = { ...this.bikes, adding: data.value };
        } else {
          //remove
          this.bikes = { ...this.bikes, removing: data.value };
        }
      }

      //update current
      this.calcCurrentValues();

      //update passenger counters
      /*this.$store.commit({
        type: "setPassengers",
        payload: {
          ...this.$store.state.passengers,
          adding_people: this.passengers.adding,
          removing_people: this.passengers.removing,
          adding_bikes: this.bikes.adding,
          removing_bikes: this.bikes.removing,
        },
      });*/
    },
    setDataInCurrentStop() {
      const currentStops = [...this.$store.state.currentStops];

      this.$store.commit({
        type: "setCurrentStops",
        payload: currentStops.map((stop, index) => {
          if (index === this.$store.state.currentStopIndex) {
            //to edit stop

            let extra = {};

            if (this.viewData.goToNext) {
              extra = { arrived: this.theTimeStamp, passed: 1 };
            }

            return {
              ...stop,
              bikes: this.bikes.current,
              bikes_off: Math.abs(this.bikes.removing),
              bikes_on: this.bikes.adding,
              people: this.passengers.current,
              people_off: Math.abs(this.passengers.removing),
              people_on: this.passengers.adding,
              ...extra,
            };
          } else if (index === this.$store.state.currentStopIndex + 1) {
            //edit previous stop

            let extra = {};

            if (this.viewData.goToNext) {
              extra = { departured: this.theTimeStamp };
            }

            return {
              ...stop,
              ...extra,
            };
          }

          return stop;
        }),
      });

      if (this.viewData.goToNext) {
        this.$store.commit({
          type: "setCurrentStopsSendInfo",
          payload: { updated: true, isSending: false },
        });

        //send current data to db
        sendCurrentStops(this);
      }
    },
    calcCurrentValues() {
      const newCurrentPassengers =
        this.$store.state.passengers.current_people +
        this.passengers.adding +
        this.passengers.removing;

      const newCurrentBikes =
        this.$store.state.passengers.current_bikes +
        this.bikes.adding +
        this.bikes.removing;

      this.passengers = { ...this.passengers, current: newCurrentPassengers };
      this.bikes = { ...this.bikes, current: newCurrentBikes };
    },
    handleRemoveAll() {
      const currentStopIndex = this.$store.state.currentStopIndex;

      this.passengers = {
        adding: 0,
        current: 0,
        removing:
          currentStopIndex === 0
            ? 0
            : -this.$store.state.passengers.current_people,
      };

      this.bikes = {
        adding: 0,
        current: 0,
        removing:
          currentStopIndex === 0
            ? 0
            : -this.$store.state.passengers.current_bikes,
      };

      this.$store.commit({
        type: "setPassengers",
        payload: {
          ...this.$store.state.passengers,
          adding_people: 0,
          removing_people: this.passengers.removing,
          adding_bikes: 0,
          removing_bikes: this.bikes.removing,
        },
      });
    },
    handlePassengersClick() {
      this.isDisabled = false;

      this.inputs.people.plus = 0;
      this.inputs.bikes.plus = 0;

      this.inputs.people.min = this.inputs.people.current_people;
      this.inputs.bikes.min = this.inputs.bikes.current_bikes;
    },

    handleSubmit(preventNext = false) {
      //update passengers in the currentstops
      this.setDataInCurrentStop();

      if (this.viewData.goToNext && !preventNext) {
        // update passengers total
        this.$store.commit({
          type: "setPassengers",
          payload: {
            ...this.$store.state.passengers,
            current_people: this.passengers.current,
            current_bikes: this.bikes.current,
            adding_people: 0,
            removing_people: 0,
            adding_bikes: 0,
            removing_bikes: 0,
          },
        });

        //set the next stop
        let newIndex = this.$store.state.currentStopIndex + 1;

        if (newIndex === 0) {
          //newIndex = 1;
        }

        if (newIndex > this.$store.state.currentStops.length - 1) {
          //is to big so trip is done
          //display next stop

          //if last stop got to endscreen or end checklist
          if (newIndex) {
            if (this.hasStopChecklist()) {
              this.$router.push(`/checklist?end=true`);
            } else {
              this.$router.push(`/upload-data`);
            }
          }
        } /*else if (newIndex === this.$store.state.currentStops.length - 1) {
          //is last stop so add option to set current passengers
          this.$store.commit({
            type: "setCurrentStopIndex",
            payload: newIndex,
          });

          const viewData = {
            timeStamp: TimeStamp.getTime(),
            nextStop: this.getcurrentStop(newIndex),
            goToNext: true,
          };

          this.emitter.emit("toggleOffcanvas", [
            true,
            "PassengersForm",
            viewData,
          ]);
        }*/ else {
          //display next stop
          this.$store.commit({
            type: "setCurrentStopIndex",
            payload: newIndex,
          });
        }

        this.passengers = {
          ...this.passengers,
          adding: 0,
          removing: 0,
        };

        this.bikes = {
          ...this.bikes,
          adding: 0,
          removing: 0,
        };
      } else {
        // update passengers total
        this.$store.commit({
          type: "setPassengers",
          payload: {
            ...this.$store.state.passengers,
            /*  current_people: this.passengers.current,
            current_bikes: this.bikes.current,*/
            adding_people: this.passengers.adding,
            removing_people: this.passengers.removing,
            adding_bikes: this.bikes.adding,
            removing_bikes: this.bikes.removing,
          },
        });
      }

      //save store to old store
      localStorage.setItem("oldStore", JSON.stringify(this.$store.state));

      //this.emitter.emit("toggleOffcanvas", false);
    },

    closeCanvas() {
      this.handleSubmit(true);
      this.emitter.emit("toggleOffcanvas", false);

      if (
        this.$store.state.currentStopIndex + 1 >
        this.$store.state.currentStops.length - 1
      ) {

        if (this.hasStopChecklist()) {
          this.$store.commit({
            type: "resetCheckList",
            payload: true,
          });
        }



        if (this.hasStopChecklist()) {
          this.$router.push(`/checklist?end=true`);
        } else {
          this.$router.push(`/upload-data`);
        }
      }
    },
    hasStopChecklist() {
      return (
        this.$store.state.flightSettings.shift.has_end_checklist
        //&& !this.$store.state.checklistIsSend
      );
    },

    getcurrentStop(newIndex) {
      let currentIndex = -1;
      let currentStop =
        this.$store.state.currentStops[newIndex >= 0 ? newIndex : 0];
      const filteredCurrentStops = [...this.$store.state.currentStops].filter(
        (stop) => (stop ? !stop.skipped : false)
      );

      filteredCurrentStops.forEach((stop, index) => {
        if (currentStop) {
          if (currentStop.departure === stop.departure) {
            currentIndex = index;
          }
        }
      });

      if (currentIndex < 0) {
        currentIndex = 0;
      }
      console.log("new item tester", filteredCurrentStops[currentIndex]);
      return filteredCurrentStops[currentIndex];
    },
  },
};
