<script>
import "./Recap.scss";
import Recap from "./Recap.js";

export default Recap;
</script>

<template>
  <div class="recap">
    <div class="recap__row">
      <span class="recap__row__item__title">Huidige dienst</span>

      <div class="recap__row__inner">
        <div class="recap__row__item">
          <div class="recap__track">
            <span class="recap__track__symbol">
              <Svg-icon iconType="Anchor-circle"></Svg-icon>
            </span>
            <span
              v-if="$store.state.flightSettings.track"
              class="recap__track__label"
              >{{ $store.state.flightSettings.track.name }}</span
            >
          </div>
        </div>

        <div class="recap__row__item names-section">
          <span
            v-if="$store.state.flightSettings.shift"
            class="recap__row__item__label"
            >{{ $store.state.flightSettings.ship.name }}</span
          >
          <span
            v-if="$store.state.flightSettings.shift"
            class="recap__row__item__label"
            >{{ $store.state.flightSettings.shift.name }}</span
          >
        </div>
      </div>
    </div>

    <div class="recap__row">
      <div class="recap__row__inner">
        <div v-if="nextStopExists" class="recap__row__item">
          <span class="recap__row__item__symbol">
            <Svg-icon iconType="Next-stop"></Svg-icon>
          </span>

          <span class="recap__row__item__title">{{
            isSecondToLast ? "Einde" : "Volgende"
          }}</span>
          <span class="recap__row__item__label">{{ nextStopName }}</span>
        </div>

        <div
          :class="[
            'recap__row__item',
            {
              'is-invalid':
                $store.state.passengers.current_people >
                $store.state.passengers.max_people,
            },
          ]"
        >
          <span class="recap__row__item__symbol">
            <Svg-icon iconType="Passengers-flat"></Svg-icon>
          </span>

          <span class="recap__row__item__title">Personen</span>
          <span class="recap__row__item__label">{{
            $store.state.passengers.current_people
          }}</span>
        </div>

        <div
          :class="[
            'recap__row__item',
            {
              'is-invalid':
                $store.state.passengers.current_bikes >
                $store.state.passengers.max_bikes,
            },
          ]"
        >
          <span class="recap__row__item__symbol">
            <Svg-icon iconType="Bike"></Svg-icon>
          </span>

          <span class="recap__row__item__title">Fietsen</span>
          <span class="recap__row__item__label">{{
            $store.state.passengers.current_bikes
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
