<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 20.9 22.8"
    xml:space="preserve"
  >
    <g>
      <path
        d="M18.2,2.1c-0.4-0.4-1-0.5-1.5-0.1c-0.4,0.4-0.5,1-0.1,1.5C18,5,18.7,6.9,18.8,8.9c0,0.6,0.5,1,1,1h0 c0.6,0,1-0.5,1-1.1C20.8,6.4,19.9,4,18.2,2.1L18.2,2.1z"
      />
      <path
        d="M1,9.9c0.6,0,1-0.5,1-1c0-2,0.8-3.9,2.1-5.4C4.6,3.1,4.5,2.4,4.1,2C3.7,1.7,3,1.7,2.7,2.1C1,4,0,6.4,0,8.9 C0,9.4,0.4,9.9,1,9.9C1,9.9,1,9.9,1,9.9L1,9.9z"
      />
      <path d="M14.1,19.2c0,2-1.6,3.6-3.6,3.6s-3.6-1.6-3.6-3.6l0,0H14.1z" />
      <path
        d="M10.4,0c0.6,0,1,0.5,1,1l0,0l0,1.7c3.1,0.5,5.5,3.2,5.5,6.4l0,0v1.8c0,0.8,0.1,1.6,0.4,2.4l0,0l0.6,1.9h1.6 c0.6,0,1,0.5,1,1c0,0.6-0.5,1-1,1l0,0H1.4c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1l0,0h1.6l0.6-1.9c0.2-0.8,0.4-1.6,0.4-2.4l0,0V9.1 c0-3.2,2.4-5.9,5.5-6.4l0,0V1C9.4,0.5,9.8,0,10.4,0z"
      />
    </g>
  </svg>
</template>
