<template>
	<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 47.9 47.9"  xml:space="preserve">
		<g>
			<path d="M6.8,5.7C3,5.7,0,8.8,0,12.5v28.6c0,3.7,3,6.8,6.8,6.8h17c0.6,0,1-0.5,1-1s-0.5-1-1-1l-17,0c-2.6,0-4.7-2.1-4.7-4.7l0-28.6 c0-2.6,2.1-4.7,4.7-4.7h3.5c0.6,0,1-0.5,1-1s-0.5-1-1-1L6.8,5.7z"/>
			<path d="M37.6,5.7c-0.6,0-1,0.5-1,1s0.5,1,1,1h3.5c2.6,0,4.7,2.1,4.7,4.7v7.7c0,0.6,0.5,1,1,1s1-0.5,1-1l0-7.7c0-3.7-3-6.8-6.8-6.8 H37.6z"/>
			<path d="M17.5,5.7c-0.6,0-1,0.5-1,1s0.5,1,1,1h13.6c0.6,0,1-0.5,1-1s-0.5-1-1-1H17.5z"/>
			<path d="M5.6,17.2c-0.6,0-1,0.5-1,1s0.5,1,1,1h36.7c0.6,0,1-0.5,1-1s-0.5-1-1-1H5.6z"/>
			<path d="M13.9,0c-0.6,0-1,0.5-1,1v11.5c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.5,0.3,0.7,0.3c0.6,0,1-0.5,1-1V1C15,0.5,14.5,0,13.9,0z"/>
			<path d="M34.3,0c-0.6,0-1,0.5-1,1v11.5c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.5,0.3,0.7,0.3c0.6,0,1-0.5,1-1V1C35.4,0.5,34.9,0,34.3,0z"/>
			<path d="M9.6,28.6c-0.6,0-1,0.5-1,1s0.5,1,1,1h5.7c0.6,0,1-0.5,1-1s-0.5-1-1-1H9.6z"/>
			<path d="M9.6,34.4c-0.6,0-1,0.5-1,1s0.5,1,1,1h5.7c0.6,0,1-0.5,1-1s-0.5-1-1-1H9.6z"/>
			<path d="M9.6,40.1c-0.6,0-1,0.5-1,1s0.5,1,1,1h5.7c0.6,0,1-0.5,1-1s-0.5-1-1-1H9.6z"/>
			<path d="M23.2,21.8c-0.9,0-1.7,0.3-2.4,1l-1.6,1.6c-0.6,0.6-1,1.5-1,2.3s0.3,1.7,1,2.3l1.4,1.4c0.2,0.2,0.5,0.3,0.7,0.3 c0.3,0,0.5-0.1,0.7-0.3s0.3-0.5,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7l-1.4-1.4c-0.2-0.2-0.4-0.5-0.4-0.9c0-0.3,0.1-0.6,0.4-0.9l1.6-1.6 c0.5-0.5,1.3-0.5,1.7,0l1.4,1.4c0.4,0.4,1.1,0.4,1.5,0c0.4-0.4,0.4-1.1,0-1.5l-1.4-1.4C24.9,22.1,24.1,21.8,23.2,21.8z"/>
			<path d="M42.9,23.9c0.3,0,0.6,0.1,0.9,0.4l1.6,1.6c0.5,0.5,0.5,1.3,0,1.8L44,28.9c-0.4,0.4-0.4,1.1,0,1.5c0.2,0.2,0.5,0.3,0.7,0.3 c0.3,0,0.5-0.1,0.7-0.3l1.3-1.4c1.3-1.3,1.3-3.4,0-4.7l0,0l-1.6-1.6c-1.3-1.3-3.4-1.3-4.7,0l-1.4,1.4c-0.2,0.2-0.3,0.5-0.3,0.7 c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.5,0.3,0.7,0.3h0c0.3,0,0.5-0.1,0.7-0.3l1.4-1.4C42.3,24,42.6,23.9,42.9,23.9z"/>
			<path d="M44.7,36.2c0-1.9-0.5-3.8-1.4-5.5c-0.5-1-1.2-1.9-2-2.7c-0.8-0.8-1.7-1.5-2.7-2c-1.6-0.9-3.4-1.3-5.4-1.4c0,0-0.1,0-0.1,0 c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c-2,0-3.8,0.5-5.5,1.4c-1,0.5-1.9,1.2-2.7,2c-0.8,0.8-1.5,1.7-2,2.7 c-0.9,1.7-1.4,3.5-1.4,5.5c0,0,0,0,0,0.1l0,0c0,0,0,0,0,0.1v0c0,3.1,1.2,6,3.4,8.2c2.2,2.2,5.1,3.4,8.3,3.4c3.1,0,6.1-1.2,8.3-3.4 c2.2-2.2,3.4-5.1,3.4-8.2C44.7,36.3,44.7,36.3,44.7,36.2C44.7,36.3,44.7,36.2,44.7,36.2C44.7,36.2,44.7,36.2,44.7,36.2z M39.7,42.9 c-1.5,1.5-3.3,2.4-5.4,2.7l0-1c0-0.7-0.6-1.2-1.2-1.2s-1.2,0.6-1.2,1.2v1c-2.1-0.3-3.9-1.2-5.4-2.7c-1.4-1.4-2.4-3.3-2.6-5.4h0.9 c0.7,0,1.2-0.6,1.2-1.2S25.4,35,24.7,35h-0.9c0.1-1.2,0.5-2.2,1-3.2c0.4-0.8,0.9-1.5,1.5-2.1l0.1-0.1c0.6-0.6,1.4-1.2,2.1-1.6 c1-0.5,2.1-0.9,3.3-1.1v1c0,0.7,0.6,1.2,1.2,1.2s1.2-0.6,1.2-1.2v-1c1.1,0.2,2.2,0.5,3.2,1c0.8,0.4,1.5,1,2.2,1.6 c0.7,0.7,1.2,1.4,1.6,2.2c0.5,1,0.9,2.1,1,3.2h-0.9c-0.7,0-1.2,0.6-1.2,1.2s0.6,1.2,1.2,1.2h0.9C42.1,39.5,41.1,41.4,39.7,42.9z"/>
			<path d="M29.6,35.2c-0.6,0-1,0.5-1,1c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.5,0.3,0.7,0.3H33c0.3,0,0.5-0.1,0.7-0.3 c0.2-0.2,0.3-0.5,0.3-0.8v-5.7c0-0.6-0.5-1-1-1s-1,0.5-1,1v4.7H29.6z"/>
		</g>
	</svg>
</template>