<template>
	<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 44.9 31.9" xml:space="preserve">
		<g>
			<path d="M28.6,7.7c2.1,0,3.9-1.7,3.9-3.9c0-2.1-1.7-3.9-3.9-3.9c-2.1,0-3.9,1.7-3.9,3.9S26.5,7.7,28.6,7.7z M26.6,3.9 c0-1.2,0.9-2.1,2.1-2.1c1.2,0,2.1,0.9,2.1,2.1s-0.9,2.1-2.1,2.1C27.5,5.9,26.6,5,26.6,3.9z"/>
			<path d="M1.7,15.5c0.2,0,0.4-0.1,0.5-0.3s0.2-0.4,0.2-0.6L1.8,9.4c0-0.2-0.1-0.4-0.3-0.6C1.2,8.7,1,8.6,0.8,8.6 C0.5,8.6,0.3,8.8,0.2,9C0,9.1,0,9.4,0,9.6l0.6,5.1C0.7,15.2,1.1,15.5,1.7,15.5C1.6,15.5,1.7,15.5,1.7,15.5z"/>
			<path d="M6.1,7.7C8.3,7.7,10,6,10,3.9C10,1.7,8.3,0,6.1,0S2.3,1.7,2.3,3.9S4,7.7,6.1,7.7z M4,3.9C4,2.7,5,1.8,6.1,1.8 s2.1,0.9,2.1,2.1S7.3,5.9,6.1,5.9S4,5,4,3.9z"/>
			<path d="M35.7,23.8l-9.5-0.3l-0.2-2c-0.1-0.5-0.5-0.9-1.1-0.9c-0.4,0.1-0.8,0.5-0.7,0.9l0.3,2.9 c0.1,0.4,0.4,0.8,0.8,0.8l10.2,0.4l0.1,0c0.4,0,0.8-0.4,0.8-0.8c0-0.2-0.1-0.5-0.2-0.6C36.2,23.9,36,23.8,35.7,23.8z"/>
			<path d="M12.9,23.8l-9.5-0.3l-0.7-5.5c-0.1-0.5-0.5-0.9-1-0.9c0,0-0.1,0-0.1,0C1.3,17.1,1,17.5,1,18l0.8,6.5 c0.1,0.4,0.4,0.8,0.8,0.8l10.2,0.4h0c0.5,0,0.9-0.4,0.9-0.8c0-0.2-0.1-0.5-0.2-0.6S13.1,23.8,12.9,23.8z"/>
			<path d="M44.8,28.2l-2.4-8.7c-0.3-1-1-1.8-2-2c-0.3-0.1-0.6-0.1-0.9-0.2l-4-0.1l-1.3-6.5c-0.2-0.9-0.7-1.7-1.5-2.3 C32,7.9,31,7.7,30.1,7.9l-1.3,0.3c-0.9,0.2-1.7,0.7-2.3,1.5c-0.5,0.8-0.7,1.7-0.5,2.7l1.5,7.6l0,0.1c0,0,0,0.1,0,0.1 c0,0.7,0.3,1.4,0.8,1.9c0.5,0.5,1.2,0.9,2,0.9l7,0.2l1.8,6.7c0.2,0.8,0.7,1.4,1.4,1.8c0.4,0.3,0.9,0.4,1.4,0.4l0.1,0v0 c0.2,0,0.4,0,0.6-0.1c0.8-0.2,1.4-0.7,1.8-1.4C44.9,29.7,45,29,44.8,28.2z M40.8,29.3l-2-7.3c-0.1-0.4-0.4-0.6-0.8-0.7l-7.7-0.2 c-0.6,0-1.1-0.5-1.1-1.2c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.1L27.7,12c-0.1-0.5,0-0.9,0.3-1.3c0.3-0.4,0.7-0.7,1.1-0.8l1.3-0.3 c0.1,0,0.2,0,0.3,0c0,0,0,0,0,0h0c0.9,0,1.6,0.6,1.8,1.4l1.4,7.2c0.1,0.4,0.4,0.7,0.8,0.7l4.7,0.1c0.1,0,0.2,0,0.3,0.1 c0,0,0.1,0,0.1,0c0.4,0.1,0.7,0.4,0.8,0.8l2.4,8.7c0.1,0.3,0,0.6-0.1,0.9c-0.2,0.3-0.4,0.5-0.7,0.5c-0.3,0.1-0.6,0-0.9-0.1 C41.1,29.8,40.9,29.5,40.8,29.3z"/>
			<path d="M19.9,19.5c-0.3-1-1-1.8-2-2c-0.3-0.1-0.6-0.1-0.9-0.2l-4-0.1l-1.3-6.5c-0.2-0.9-0.7-1.7-1.5-2.3 C9.4,7.9,8.5,7.7,7.6,7.9L6.2,8.1C5.3,8.3,4.5,8.8,4,9.6c-0.5,0.8-0.7,1.7-0.5,2.7L5,19.9C5,20,5,20,5,20.1c0,0.7,0.3,1.4,0.8,1.9 c0.5,0.5,1.2,0.9,2,0.9l7,0.2l1.8,6.7c0.2,0.8,0.7,1.4,1.4,1.8c0.4,0.3,0.9,0.4,1.4,0.4l0.1,0l0,0c0.2,0,0.5,0,0.7-0.1 c0.8-0.2,1.4-0.7,1.8-1.4c0.4-0.7,0.5-1.5,0.3-2.2L19.9,19.5z M8.2,9.6C8.2,9.6,8.2,9.6,8.2,9.6L8.2,9.6c0.9,0,1.6,0.6,1.8,1.4 l1.4,7.2c0.1,0.4,0.4,0.7,0.8,0.7L17,19c0.1,0,0.2,0,0.3,0.1c0,0,0.1,0,0.1,0c0.4,0.1,0.7,0.4,0.8,0.8l2.4,8.7 c0.1,0.3,0,0.6-0.1,0.9c-0.2,0.3-0.4,0.5-0.7,0.5c-0.3,0.1-0.6,0-0.9-0.1c-0.3-0.2-0.5-0.4-0.5-0.7l-2-7.3 c-0.1-0.4-0.4-0.6-0.8-0.7l-7.7-0.2c-0.6,0-1.1-0.5-1.1-1.2c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.1L5.2,12C5,11,5.6,10,6.6,9.8 l1.3-0.3C8,9.6,8.1,9.6,8.2,9.6z"/>
			<path d="M24.9,19.1c0.2,0,0.4-0.1,0.5-0.3c0.1-0.2,0.2-0.4,0.2-0.6l-1.1-8.8c0-0.2-0.1-0.4-0.3-0.6s-0.4-0.2-0.7-0.2 c-0.5,0.1-0.8,0.5-0.8,1l1,8.6C23.9,18.7,24.3,19.1,24.9,19.1C24.9,19.1,24.9,19.1,24.9,19.1z"/>
		</g>
	</svg>
</template>