import Flights from "../../../api/Flights";

export default {
  data() {
    return {
      location: {
        lng: null,
        lat: null,
      },
      weather: {
        icon: null,
        temperature: null,
        state: String,
      },
      iconList: {
        icon01d: "Weather-clear-sky",
        icon02d: "Weather-few-clouds",
        icon03d: "Weather-scattered-clouds",
        icon04d: "Weather-broken-clouds",
        icon09d: "Weather-shower-rain",
        icon10d: "Weather-rain",
        icon11d: "Weather-thunderstorm",
        icon13d: "Weather-snow",
        icon50d: "Weather-mist",
      },
    };
  },

  created() {
    this.getPosition();
  },

  methods: {
    getPosition() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.getLatLng);
      }
    },
    getLatLng(position) {
      this.location.lng = position.coords.longitude;
      this.location.lat = position.coords.latitude;

      this.getWeather();
    },

    getWeather() {
      if (
        this.location.lng &&
        this.location.lat &&
        this.$store.state.flightSettings.id &&
        this.$store.state.isOnline
      ) {
        Flights.weather(
          {
            id: this.$store.state.flightSettings.id,
            lng: this.location.lng,
            lat: this.location.lat,
          },
          this
        ).then((r) => this.onSucces(r));
      }
    },

    onSucces(r) {
      let weather = {
        ...r,
      };

      if (r.icon) {
        weather = {
          ...r,
          icon: this.iconList["icon" + r.icon.replace(/n/g, "d")],
        };
      }

      this.$store.commit({
        type: "setWeather",
        payload: weather,
      });
    },
  },
  watch: {
    "$store.state.isOnline"(isOnline) {
      if (isOnline) {
        this.getPosition();
      }
    },
  },
};
