<template>
	<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 66.6 59" xml:space="preserve">
		<g>
			<path d="M43.4,0.1L43.4,0.1c-2.9,0-5.2,2.4-5.2,5.2c0,3.5-2.9,6.4-6.4,6.4H14.9c-4.9,0-9.5,2.4-12.2,6.4 c-2.8,4-3.4,9.1-1.7,13.7c1.7,4.6,5.6,8,10.3,9.2l3.9,13.4h0c0.4,1.6,1.5,2.9,2.9,3.7c1.4,0.8,3.1,1,4.7,0.5 c3.3-0.9,5.1-4.3,4.2-7.6l-2.8-9.6h7.7c3.5,0,6.4,2.9,6.4,6.4c0,2.9,2.3,5.2,5.2,5.2c2.9,0,5.2-2.3,5.2-5.2V5.3 C48.7,2.4,46.3,0.1,43.4,0.1z M24.6,51.8c0.6,2-0.6,4.1-2.6,4.6c-2,0.6-4.1-0.6-4.6-2.6l-3.5-12.3c0.4,0,0.7,0.1,1,0.1h6.6 L24.6,51.8z M45.3,50c-0.6,0.5-1.4,0.8-2.1,0.7c-1.5-0.2-2.6-1.4-2.6-3c0-0.2,0-0.5,0-0.7h0V38c0-0.7-0.5-1.2-1.2-1.2 c-0.7,0-1.2,0.5-1.2,1.2v3.9c-1.7-1.8-4-2.7-6.4-2.7H15.3C8.6,39.2,2.8,34,2.4,27.3c-0.2-3.4,1.1-6.8,3.4-9.3 c2.4-2.5,5.6-3.9,9.1-3.9h16.8c2.4,0,4.7-1,6.4-2.8v15.4c0,0.3,0.1,0.6,0.4,0.8c0.2,0.2,0.5,0.3,0.8,0.3c0.7,0,1.2-0.5,1.2-1.2V5.3 c-0.1-0.9,0.4-1.8,1.1-2.3c0.9-0.6,2-0.7,3-0.2c1,0.5,1.6,1.5,1.6,2.6l0,42.6h0C46.3,48.7,45.9,49.5,45.3,50z"/>
			<path d="M60.1,38l1.3,0.6c0.6,0.3,0.8,1,0.6,1.6c-0.3,0.6-1.1,0.8-1.6,0.6l-1.3-0.6c-0.6-0.3-0.8-1-0.6-1.6 C58.8,37.9,59.7,37.7,60.1,38z"/>
			<path d="M53.6,34.7l3.5,1.7c0.6,0.3,0.8,1,0.6,1.6c-0.3,0.6-1.1,0.8-1.6,0.6l-3.5-1.7c-0.6-0.3-0.8-1-0.6-1.6 C52.3,34.7,53.1,34.5,53.6,34.7z"/>
			<path d="M39.4,29.6c0.7,0,1.2,0.7,1.2,1.2v1.9c0,0.7-0.5,1.2-1.2,1.2s-1.2-0.7-1.2-1.2v-1.9 C38.2,30.1,38.7,29.6,39.4,29.6z"/>
			<path d="M65.3,25.4c0.7,0,1.2,0.7,1.2,1.2c0,0.7-0.5,1.2-1.2,1.2H51.5c-0.7,0-1.2-0.7-1.2-1.2c0-0.7,0.5-1.2,1.2-1.2 H65.3z"/>
			<path d="M58.2,13.7c0.3-0.2,0.6-0.2,0.9-0.1c0.3,0.1,0.6,0.3,0.7,0.6c0.1,0.3,0.2,0.6,0,1c-0.1,0.3-0.4,0.6-0.7,0.7 l0,0l-5.6,2.7c-0.6,0.2-1.2,0-1.5-0.6c-0.3-0.6-0.1-1.2,0.5-1.5l0,0L58.2,13.7z"/>
			<path d="M61.8,12c0.5-0.2,1.1,0,1.4,0.4l0,0l0.2,0.3c0.1,0.3,0.2,0.6,0.1,0.8c-0.1,0.3-0.3,0.5-0.5,0.6 c-0.3,0.1-0.6,0.1-0.8,0c-0.3-0.1-0.5-0.3-0.6-0.6l0,0l-0.2-0.3C61.1,12.8,61.3,12.2,61.8,12z"/>
		</g>
	</svg>
</template>