<template>
  <svg
    width="24"
    height="26"
    viewBox="0 0 24 26"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 14c0 6.609-5.391 12-12 12S0 20.609 0 14c0-3.797 1.75-7.297 4.797-9.578a1.979 1.979 0 0 1 2.797.391 1.995 1.995 0 0 1-.391 2.797C5.172 9.141 4 11.469 4 14.001c0 4.406 3.594 8 8 8 4.406 0 8-3.594 8-8 0-2.531-1.172-4.859-3.203-6.391a1.995 1.995 0 0 1-.391-2.797 1.981 1.981 0 0 1 2.797-.391A11.877 11.877 0 0 1 24 14ZM14 2v10c0 1.094-.906 2-2 2-1.094 0-2-.906-2-2V2c0-1.094.906-2 2-2 1.094 0 2 .906 2 2Z"
    />
  </svg>
</template>
