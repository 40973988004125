<script>
import "./Shifts.scss";
import Shifts from "./Shifts.js";

export default Shifts;
</script>

<template>
  <Step-wrapper title="Kies een dienst" :isLoading="isLoading">
    <div class="shift-wrapper">
      <agile
        :centerMode="true"
        :slidesToShow="slides.length > 3 ? 3 : slides.length"
        :dots="false"
        ref="carousel"
      >
        <template #prevButton>
          <div class="shift__actions__prev">
            <div class="directionalButton">
              <span class="directionalButton__symbol">
                <Svg-icon iconType="Arrow-left" />
              </span>
            </div>
          </div>
        </template>
        <div v-for="item in slides" class="shift__item slide" :key="item.id">
          <div class="shift__item__inner">
            <div class="shift__item__sizer">
              <div class="shift__item__sizerWrap">
                <div class="shift__item__content">
                  <input
                    @change="(e) => handleChange(e, item)"
                    :id="`${item.id}${item.name}`"
                    :value="item.id"
                    class="shift__radio"
                    type="checkbox"
                    :checked="chosenShift ? chosenShift.id === item.id : false"
                  />
                  <label
                    :for="`${item.id}${item.name}`"
                    class="shift__radioFaker"
                  >
                    <div class="shift__checked__symbol">
                      <span class="shift__checked__theSymbol">
                        <Svg-icon iconType="check" iconColor="#fff" />
                      </span>
                    </div>

                    <span class="shift__item__symbol">
                      <Svg-icon iconType="calendar" />
                    </span>

                    <div class="shift__item__label">
                      <span
                        v-if="item.label"
                        class="shift__item__label__name"
                        >{{ item.label }}</span
                      >
                      <span v-else class="shift__item__label__name">{{
                        item.name
                      }}</span>
                      <span
                        v-if="item.number"
                        class="shift__item__label__count"
                        >{{ item.number }}</span
                      >
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div
              class="button button--pairing-brand-2 incompleteHandler"
              :class="{ active: checkCompleted(item) }"
            >
              <Svg-icon iconType="track"></Svg-icon>Onvoltooid
            </div>
          </div>
        </div>
        <template #nextButton>
          <div class="shift__actions__next">
            <div class="directionalButton">
              <span class="directionalButton__symbol">
                <Svg-icon iconType="Arrow-right" />
              </span>
            </div>
          </div>
        </template>
      </agile>
    </div>
  </Step-wrapper>
</template>
