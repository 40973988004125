<template>
  <transition name="fade" mode="out-in">
    <div class="login">
      <div v-if="isLoading" class="login__preloader">
        <header class="login__preloader__header">
          <div class="login__preloader__brand">
            <div class="brand"></div>
          </div>

          <span class="login__preloader__header__subtitle"
            >Een vlotte vaart regel je hier.</span
          >
          <h2 class="heading heading--0 login__preloader__header__title">
            Cockpit<br />
            app
          </h2>
        </header>

        <div class="login__preloader__loader">
          <Loader />
        </div>
      </div>

      <div v-if="!isLoading" class="login__theLogin">
        <div class="login__inner">
          <Login-form />
        </div>

        <div class="login__outer"></div>
      </div>
    </div>
  </transition>
</template>

<script src="./Login.js"></script>
<style lang="scss" scoped>
@import "./Login.scss";
</style>
