<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 21 21"
    xml:space="preserve"
  >
    <g>
      <path
        d="M5.6,20.7c0.4-0.5,0.4-1.2,0-1.6l-0.2-0.2l2.7-1.2c0,0,0.1,0.1,0.1,0.1l1.2,1.2 c0.9,0.8,2.1,1.2,3.3,1c1.2-0.2,2.2-1,2.7-2.1c0.5-1.1,0.5-2.4-0.1-3.4l3-1.3l0.7,0.7c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6 L8.9,0.3c-0.5-0.4-1.2-0.4-1.6,0C6.8,0.8,6.8,1.5,7.3,2l0.6,0.6l-5.8,13L2,15.4c-0.4-0.5-1.2-0.5-1.6,0c-0.5,0.4-0.5,1.2,0,1.6 L4,20.7C4.4,21.1,5.2,21.1,5.6,20.7L5.6,20.7z M13.2,17.3c-0.6,0.6-1.6,0.6-2.2,0l-0.7-0.7l3.1-1.4C13.8,15.9,13.7,16.8,13.2,17.3 L13.2,17.3z M9.6,4.3L11.3,6L5,14.7L9.6,4.3z"
      />
    </g>
  </svg>
</template>
