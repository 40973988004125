<script>
import "./CommentsForm.scss";
import CommentsForm from "./CommentsForm.js";

export default CommentsForm;
</script>

<template>
  <div class="commentsForm">
    <header class="commentsForm__header">
      <div class="commentsForm__header__group">
        <span class="commentsForm__header__symbol">
          <Svg-icon iconType="Warning"></Svg-icon>
        </span>

        <span class="commentsForm__header__subtitle"
          >{{ viewData.details ? viewData.details.name : viewData.name }} -
          {{ viewData.details ? viewData.details.title : viewData.title }}</span
        >
      </div>

      <h3 class="heading heading--4 commentsForm__header__title">
        {{
          viewData.details
            ? viewData.details.subcategorytitle
            : viewData.details
        }}
      </h3>
    </header>

    <form class="commentsForm__form">
      <div class="commentsForm__form__field" ref="comment">
        <label class="input__label" for="comment">Opmerkingen</label>
        <textarea
          v-model="inputFields.remarks"
          :defaultValue="viewData ? viewData.remark : null"
          class="input__textarea"
          rows="4"
          name="comment"
        ></textarea>
      </div>

      <div class="commentsForm__camera">
        <transition name="fade">
          <div
            v-show="inputFields.image ? inputFields.image : null"
            class="commentsForm__camera__photo"
          >
            <img
              :src="inputFields.image"
              class="commentsForm__camera__thePhoto"
              ref="photo"
            />

            <button
              @click.prevent="handleDeleteClick"
              class="commentsForm__camera__photo__action"
            >
              <span class="commentsForm__camera__photo__symbol">
                <Svg-icon iconType="Trash"></Svg-icon>
              </span>
            </button>
          </div>
        </transition>

        <Camera />

        <div class="commentsForm__camera__btn">
          <button
            @click.prevent="handlePhotoClick"
            class="button button--pairing-medium"
            ref="submit"
          >
            <span class="button__symbol">
              <Svg-icon iconType="Camera"></Svg-icon>
            </span>
            <span class="button__label">{{
              !inputFields.image ? "Foto toevoegen" : "Foto vervangen"
            }}</span>
          </button>
        </div>
      </div>

      <div class="commentsForm__form__submit">
        <button
          @click.prevent="handleSubmit"
          class="button button--brand-2"
          type="sumbit"
          ref="submit"
        >
          <span class="button__label">Verzenden</span>
        </button>
      </div>

      <transition name="fade">
        <div
          v-if="isInvalid"
          :class="{ 'is-invalid': isInvalid }"
          class="commentsForm__error"
        >
          <span class="input__error">
            <span class="input__error__symbol">
              <Svg-icon iconType="Warning"></Svg-icon>
            </span>
            <span class="input__error__label"
              >Voeg een opmerking of foto toe</span
            >
          </span>
        </div>
      </transition>
    </form>
  </div>
</template>
