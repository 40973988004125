export default {
  props: {
    title: String,
    minPassengers: Number,
    currentPassengers: Number,
    maxPassengers: Number,
    minBikes: Number,
    currentBikes: Number,
    maxBikes: Number,
  },
};
