export default {
  data() {
    return {
      show: false,
      theViewData: undefined,
    };
  },

  created() {
    this.emitter.on("togglePhotoViewer", (data) => {
      this.show = data[0];
      this.theViewData = data[1];
    });
  },

  methods: {
    handleClick() {
      this.emitter.emit("togglePhotoViewer", false);
    },
  },
};
