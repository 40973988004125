<script>
import "./Notifications.scss";
import Notifications from "./Notifications.js";

export default Notifications;
</script>

<template>
  <Page-wrapper title="Meldingen">
    <div class="notifications__inner">
      <section class="notificationsOverview">
        <div class="notificationsOverview__inner">
          <table>
            <thead>
              <th>Datum &amp; tijd</th>
              <th>Beschrijving</th>
              <th></th>
            </thead>
            <tbody>
              <tr
                v-for="notification in $store.state.notifications"
                @click="handleDetailClick(notification)"
                :key="`${formatDateOfNotification(
                  notification
                )}${formatTimeOfNotification(notification)}`"
              >
                <td>
                  {{ formatDateOfNotification(notification) }}
                  <span>&bullet;</span>
                  {{ formatTimeOfNotification(notification) }}
                </td>
                <td>{{ notification.message }}</td>
                <td>
                  <button
                    class="button button--theme-1 flightEditPanel__actions__button"
                    :class="{ read: notificationIsRead(notification) }"
                  >
                    <span
                      class="button__symbol flightEditPanel__actions__button__symbol"
                    >
                      <Svg-icon iconType="View-icon"></Svg-icon>
                    </span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </div>
  </Page-wrapper>
</template>
